<!-- Ventes/Prestations -->
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title> Ventes/Prestations </mat-panel-title>
  </mat-expansion-panel-header>

  <app-ventes-prestation-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onVentesPrestationFormValuesChanged($event)"></app-ventes-prestation-form>
</mat-expansion-panel>

<!-- Achats -->
<mat-expansion-panel class="mt-3">
  <mat-expansion-panel-header>
    <mat-panel-title> Achats </mat-panel-title>
  </mat-expansion-panel-header>

  <app-achats-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onAchatsFormValuesChanged($event)"></app-achats-form>
</mat-expansion-panel>

<!-- Notes de frais -->
<mat-expansion-panel class="mt-3">
  <mat-expansion-panel-header>
    <mat-panel-title> Notes de frais </mat-panel-title>
  </mat-expansion-panel-header>

  <app-notes-de-frais-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onNotesDeFraisFormValuesChanged($event)"></app-notes-de-frais-form>
</mat-expansion-panel>

<!-- Autres flux -->
<mat-expansion-panel class="mt-3">
  <mat-expansion-panel-header>
    <mat-panel-title> Autres flux </mat-panel-title>
  </mat-expansion-panel-header>

  <app-autres-flux-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onAutresFluxFormValuesChanged($event)"></app-autres-flux-form>
</mat-expansion-panel>

<!-- Autres outils -->
<mat-expansion-panel class="mt-3">
  <mat-expansion-panel-header>
    <mat-panel-title> Autres outils </mat-panel-title>
  </mat-expansion-panel-header>

  <app-autres-outils-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onAutresOutilsFormValuesChanged($event)"></app-autres-outils-form>
</mat-expansion-panel>

<!-- Banques -->
<mat-expansion-panel class="mt-3">
  <mat-expansion-panel-header>
    <mat-panel-title> Banques </mat-panel-title>
  </mat-expansion-panel-header>

  <app-banques-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onBanquesFormValuesChanged($event)"></app-banques-form>
</mat-expansion-panel>

<div class="row submitCancel">
  <div class="col text-end">
    <button class="btn pink" (click)="goBack()">Précédent</button>
    <button [disabled]="!isVolumesValid" class="btn pink" (click)="submitForm()">Valider</button>
  </div>
</div>
