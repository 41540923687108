import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { ToogleFormControl } from './toogle-form-control';

@Component({
    selector: 'app-toogle-form-control',
    templateUrl: './toogle-form-control.component.html',
})
export class ToogleFormControlComponent extends AbstractFormControlComponent<ToogleFormControl> {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() inline: boolean;

    change(): void {
        this.onChange.emit(this.control.value);
    }
}
