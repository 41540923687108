import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { SelectedCompanyDataService } from 'projects/difference/app/services/selected-company-data.service';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { DataFieldConfig, MainTaxes } from 'projects/difference/webapi/Difference.WebApi';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { AbstractForm } from '../../abstract-form';
import { getMainTaxesFormComponentControl, mainTaxesFormToViewModel } from './main-taxes-form.extensions';
import { MainTaxesService } from './main-taxes-form.service';

@Component({
  selector: 'app-main-taxes-form',
  templateUrl: './main-taxes-form.component.html',
  styleUrls: ['./main-taxes-form.component.scss']
})
export class MainTaxesFormComponent extends AbstractForm<MainTaxes> implements OnChanges {
  @Input() mode: FormMode;
  public form: FormGroup;
  public tvaNumberControl: NameWithDigitsFormControl;

  constructor(
    public mainTaxesService: MainTaxesService,
    public lookupDataService: LookupDataService,
    public selectedCompanyDataService: SelectedCompanyDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  initControls(): void {
    this.tvaNumberControl = this.form.get(formFields.mainTaxesForm.tvaNumber) as NameWithDigitsFormControl;
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.mainTaxesService.getConfig();
    this.formData = await this.mainTaxesService.get(this.profileGuid);
    this.formId = this.formData?.taxMainId;
  }

  restoreAnswers(): void {
    const viewModel = mainTaxesFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel)
    this.checkAndRestoreSelectedCompanyData();
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  checkAndRestoreSelectedCompanyData(): void {
    if (this.selectedCompanyDataService.selectedCompanyExtraInformation) {
      if (this.tvaNumberControl.value === undefined || this.tvaNumberControl.value === null || this.tvaNumberControl.value === '') {
        this.tvaNumberControl.patchValue(this.selectedCompanyDataService.selectedCompanyExtraInformation.intraCommunityVATNumber);
        this.form.markAsDirty();
      }
    }
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getMainTaxesFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }
}
