import { Injectable } from '@angular/core';
import { Lookup, LookupDataClient, LookupType } from 'projects/difference/webapi/Difference.WebApi';

@Injectable({
    providedIn: 'root'
})

export class LookupDataService {
    private lookupData: Lookup[];
    private lookupTypeData: LookupType[];
    public lookupDataObj: any = {};

    constructor(private webApi: LookupDataClient) { }

    async initliaze(): Promise<void> {
        try {
            this.lookupData = await this.webApi.getLookupData().toPromise();
            this.lookupTypeData = await this.webApi.getLookupTypeData().toPromise();
            this.lookupTypeData.forEach((lookupType: LookupType) => {
                this.lookupDataObj[lookupType.id] = this.lookupData.filter((lookup: Lookup) => {
                    return lookup.lookupTypeId === lookupType.id;
                })
                this.lookupDataObj[lookupType.id]?.forEach((element: Lookup) => {
                    if (element.name.toLowerCase() === 'autre' || element.name.toLowerCase() === 'autres') {
                        this.lookupDataObj[lookupType.id].otherId = element.id;
                    }
                    if (element.name.toLowerCase() === 'recommandation') {
                        this.lookupDataObj[lookupType.id].surPrescriptionId = element.id;
                    }
                });
                if (lookupType.id === LookupTypeId.MissionTypes) {
                    this.lookupDataObj[lookupType.id]?.forEach((element: Lookup) => {
                        if (element.id === 1) {
                            this.lookupDataObj[lookupType.id].comptabiliteId = element.id;
                            this.lookupDataObj[lookupType.id].comptabiliteDateControlName = element.controlName;
                        }
                        if (element.id === 2) {
                            this.lookupDataObj[lookupType.id].socialId = element.id;
                            this.lookupDataObj[lookupType.id].socialDateControlName = element.controlName;
                        }
                        if (element.id === 3) {
                            this.lookupDataObj[lookupType.id].juridiqueId = element.id;
                            this.lookupDataObj[lookupType.id].juridicalDateControlName = element.controlName;
                        }
                        if (element.id === 4) {
                            this.lookupDataObj[lookupType.id].fiscaliteId = element.id;
                            this.lookupDataObj[lookupType.id].fiscaliteDateControlName = element.controlName;
                        }
                        if (element.id === 5) {
                            this.lookupDataObj[lookupType.id].auditLegalId = element.id;
                            this.lookupDataObj[lookupType.id].auditLegalDateControlName = element.controlName;
                        }
                        if (element.id === 6) {
                            this.lookupDataObj[lookupType.id].conseilOtherId = element.id;
                            this.lookupDataObj[lookupType.id].conseilOtherDateControlName = element.controlName;
                        }
                    });
                }
                if (lookupType.id === LookupTypeId.StakeholderType) {
                    this.lookupDataObj[lookupType.id]?.forEach((element: Lookup) => {
                        if (element.id === 1) { // Commissaire aux comptes
                            this.lookupDataObj[lookupType.id].auditorTypeId = element.id;
                        }
                        if (element.id === 2) { // Avocat
                            this.lookupDataObj[lookupType.id].lawyerTypeId = element.id;
                        }
                        if (element.id === 3) { // Intervenant
                            this.lookupDataObj[lookupType.id].stakeholderTypeId = element.id;
                        }
                    });
                }
                if (lookupType.id === LookupTypeId.PhoneTypes) {
                    this.lookupDataObj[lookupType.id]?.forEach((element: Lookup) => {
                        if (element.id === 1) { // mobile
                            this.lookupDataObj[lookupType.id].mobileTypeId = element.id;
                        }
                        if (element.id === 2) { // landLine
                            this.lookupDataObj[lookupType.id].landLineTypeId = element.id;
                        }
                    });
                }
                if (lookupType.id === LookupTypeId.Persons) {
                    this.lookupDataObj[lookupType.id]?.forEach((element: Lookup) => {
                        if (element.id === 7) { // external provider
                            this.lookupDataObj[lookupType.id].externalProviderId = element.id;
                        }
                    });
                }
                if (lookupType.id === LookupTypeId.AccountingEntry) {
                    this.lookupDataObj[lookupType.id]?.forEach((element: Lookup) => {
                        if (element.id === 1) { // Par le cabinet
                            this.lookupDataObj[lookupType.id].parLeCabinetId = element.id;
                        }
                    });
                }
            })
        } catch (err) {
            console.error(err);
        }
    }
}

export enum LookupTypeId {
    JurTypes = 1,
    PartnerNames = 2,
    ProfileTypes = 3,
    DepartmentTypes = 4,
    SeniorityTypes = 5,
    // Sites = 6,
    BillingMethods = 7,
    // TestTags = 8,
    // TestNotes = 9,
    // Cabinets = 10,
    ContactRoles = 11,
    Civilities = 12,
    PhoneTypes = 13,
    // MainActivities = 14,
    // LegalStatuses = 15,
    ActivityAreas = 16,
    // ActivitySectors = 17,
    CurrentTaxRegimes = 18,
    SourceInfoAboutUs = 19,
    // ResponsableNames = 20,
    // FirmNames = 21,
    InitialItemTypes = 22,
    // EmployeesTypes = 23,
    MissionTypes = 24,
    SalesPeriods = 25,
    SalesFormats = 26,
    Banks = 27,
    TaxationNatures = 28,
    TaxationMethods = 29,
    TaxationModes = 30,
    // Typologies = 31,
    Frequencies = 32,
    Persons = 33,
    // TaxationTypes = 34,
    WantToUseSoftwareTools = 35,
    ClassificationModeAccountingParts = 36,
    CommunicationModeAccountingParts = 37,
    AccountingArchiving = 38,
    Formats = 39,
    BusinessManagementSoftwareTools = 40,
    DigitalSafeTools = 41,
    WageManagementTools = 42,
    PersonnelInformationSystemTools = 43,
    AccountingTools = 44,
    ElectronicInvoicingTools = 45,
    DevisTemplatesSearhCriterions = 46,
    HeaderSearchCriterions = 47,
    FooterSearchCriterions = 48,
    ProspectsSearhCriterions = 49,
    PreconcusSearhCriterions = 50,
    ProfilesSearhCriterions = 51,
    PrestationsSearhCriterions = 52,
    DevisConslultationSearhCriterions = 53,
    HeaderFooterTypes = 54,
    Billings = 55,
    ProspectsSearhOperators = 56,
    Models = 57,
    Statuses = 58,
    StakeholderType = 59,
    AccountingEntry = 60,
    ResponsibleForVAT = 61,
    VolumeSalesTools = 62,
    VolumePurchasesTools = 63,
    VolumeExpensesTools = 64, 
    VolumeOtherStreamsTools = 65,
    VolumeOtherToolsCashManagement = 66,
    VolumeOtherToolsStockManagement = 67
}
