import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { constants } from '../../../constants/constants';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { RadioGroupFormControl } from './radio-group-form-control';

@Component({
    selector: 'app-radio-group-form-control',
    templateUrl: './radio-group-form-control.component.html',
})
export class RadioGroupFormControlComponent extends AbstractFormControlComponent<RadioGroupFormControl> {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() titleTooltipText: string;
    @Input() controlName: string;
    @Input() inline: boolean;
    @Input() oneInRow: boolean;

    change(): void {
        this.onChange.emit(this.control.value);
    }

    public get collection(): any[] {
        return this.control.collection || [];
    }

    public get keyPropertyName(): string {
        return this.control.keyPropertyName || constants.namePropertyName;
    }

    public get valuePropertyName(): string {
        return this.control.valuePropertyName || constants.namePropertyName;
    }

    public get isInline(): boolean {
        return this.inline || false;
    }

    public get isOneInRow(): boolean {
        return this.oneInRow || false;
    }
}
