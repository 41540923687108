import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { constants } from '../constants/constants';

@Pipe({
  name: 'dateShort'
})
export class DateShortPipe implements PipeTransform {
  constructor() {

  }

  transform(date: Date): string {
    if (!date) {
      return '';
    }

    return moment(date).format(constants.dateFormat);
  }
}
