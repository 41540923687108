import { MobileFormControl } from './../../../controls/mobile-form-control/mobile-form-control';
import { formFields } from './../../../../constants/form-fields';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { constants } from '../../../../constants/constants';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { PhoneFormControl } from '../../../controls/phone-form-control/phone-form-control';
import { DeleteConfirmComponent } from '../../../modals/delete-confirm/delete-confirm.component';
import { Contact, DataFieldConfig, GeneralInfo } from 'projects/difference/webapi/Difference.WebApi';
import { contactsToViewModel, getContactSecondFormComponentControl } from './contact-second-form.extensions';
import { ContactService } from './contact.service';
import { AbstractForm } from '../../abstract-form';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { mobileValidator } from 'projects/difference/app/shared/validators/mobileValidator';
import { phoneValidator } from 'projects/difference/app/shared/validators/phoneValidator';

@Component({
  selector: 'app-contact-second-form',
  templateUrl: './contact-second-form.component.html',
  styleUrls: ['./contact-second-form.component.scss']
})
export class ContactSecondFormComponent extends AbstractForm<Contact[]> implements OnChanges {
  @Input() generalInfo: GeneralInfo;
  @Input() mode: FormMode;

  public form: FormGroup;

  public mobileTypeId = this.lookupDataService.lookupDataObj[LookupTypeId.PhoneTypes].mobileTypeId;
  public landLineTypeId = this.lookupDataService.lookupDataObj[LookupTypeId.PhoneTypes].landLineTypeId;

  constructor(public bsModalService: BsModalService, public contactService: ContactService, public lookupDataService: LookupDataService, public customerDataService: CustomerDataService) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }

    if (
      changes?.generalInfo &&
      changes?.generalInfo.currentValue !== undefined &&
      changes?.generalInfo.firstChange === false &&
      changes?.generalInfo.currentValue !== changes?.generalInfo.previousValue
    ) {
      const firstContactForm = this.contacts?.controls[0];

      if (firstContactForm && changes?.generalInfo.previousValue?.[formFields.contactMainForm.jurTypeId]) {
        const model: any = {};
        const civilityControlName = this.getControlName(formFields.contactSecondFormComponent.civility, this.config);
        const firstNameControlName = this.getControlName(formFields.contactSecondFormComponent.firstName, this.config);
        const lastNameControlName = this.getControlName(formFields.contactSecondFormComponent.lastName, this.config);

        model[civilityControlName] = changes.generalInfo.currentValue[formFields.contactMainForm.jurTypeId];
        model[firstNameControlName] = changes.generalInfo.currentValue[formFields.contactSecondFormComponent.firstName];
        model[lastNameControlName] = changes.generalInfo.currentValue[formFields.contactSecondFormComponent.lastName];

        firstContactForm.patchValue(model);
      }
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.contactService.getConfig();
    this.formData = await this.contactService.get(this.profileGuid);
    this.formId = this.formData?.map((contact: Contact) => contact.contactId);
  }

  addNewPhone(contactsForm: FormGroup): void {
    const isReadOnly = this.mode === FormMode.View;
    const isRequired = this.getIsControlRequired(formFields.contactSecondFormComponent.phone);

    this.getPhonesFormArray(contactsForm).push(
      new FormGroup({
        phoneType: new DropDownFormControl(isRequired, isReadOnly, {
          valuePropertyName: constants.namePropertyName,
          keyPropertyName: constants.keyPropertyName,
          collection: this.lookupDataService.lookupDataObj[LookupTypeId.PhoneTypes]
        }),
        phoneNumber: new PhoneFormControl(isRequired, isReadOnly)
      })
    );
  }

  addNewContact(contact?: Contact): void {
    const isReadOnly = this.mode === FormMode.View;
    const isPhoneRequired = this.getIsControlRequired(formFields.contactSecondFormComponent.phone);
    let phones: any = [];

    if (contact?.phones?.length > 0) {
      let i = 0;

      while (i < contact.phones.length) {
        phones.push(
          new FormGroup({
            phoneType: new DropDownFormControl(isPhoneRequired, isReadOnly, {
              valuePropertyName: constants.namePropertyName,
              keyPropertyName: constants.keyPropertyName,
              collection: this.lookupDataService.lookupDataObj[LookupTypeId.PhoneTypes]
            }),
            phoneNumber: contact.phones[i].phoneType === this.landLineTypeId ? new PhoneFormControl(isPhoneRequired, isReadOnly) : new MobileFormControl(isPhoneRequired, isReadOnly)
          })
        );
        i++;
      }
    } else {
      phones = [
        new FormGroup({
          phoneType: new DropDownFormControl(isPhoneRequired, isReadOnly, {
            valuePropertyName: constants.namePropertyName,
            keyPropertyName: constants.keyPropertyName,
            collection: this.lookupDataService.lookupDataObj[LookupTypeId.PhoneTypes]
          }),
          phoneNumber: new PhoneFormControl(isPhoneRequired, isReadOnly)
        })
      ];
    }

    const newFormGroup = new FormGroup({
      Phones: new FormArray(phones)
    });

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getContactSecondFormComponentControl(configItem, this.mode, this.lookupDataService);

      if (controlToBeAdded) {
        newFormGroup.addControl(configItem.name, controlToBeAdded);
      }
    });

    this.contacts.push(newFormGroup);
  }

  onPhoneTypeChanged(contactsForm: FormGroup): void {
    const phoneType = contactsForm.get('phoneType').value;
    const phoneControl = contactsForm.get('phoneNumber') as PhoneFormControl;

    phoneControl.clearValidators();
    phoneControl.removeValidators([Validators.required, mobileValidator(), phoneValidator()]);

    if (this.getIsControlRequired(formFields.contactSecondFormComponent.phone)) {
      phoneControl.addValidators(Validators.required);
    }

    if (phoneType === this.mobileTypeId) {
      phoneControl.addValidators(mobileValidator());
    } else {
      phoneControl.addValidators(phoneValidator());
    }

    phoneControl.patchValue(null);
    phoneControl.markAsDirty();
    phoneControl.updateValueAndValidity();
  }

  async deleteExtraPhone(contactsForm: FormGroup, index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(DeleteConfirmComponent, {
      class: 'delete-item-confirm-dialog',
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.getPhonesFormArray(contactsForm).removeAt(index);
      }
    });
  }

  async deleteContact(index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(DeleteConfirmComponent, {
      class: 'delete-item-confirm-dialog',
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.contacts.removeAt(index);
      }
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      contacts: new FormArray([])
    });
  }

  async restoreAnswers(): Promise<void> {
    if (this.formData?.length > 0) {
      const countOfForms = this.formData.length;
      let i = 0;

      while (i < countOfForms) {
        this.addNewContact(this.formData[i]);
        i++;
      }

      const viewModel = contactsToViewModel(this.config, this.formData);
      this.contacts.patchValue(viewModel);
    } else {
      this.addNewContact();
    }

    this.onFormDataLoaded.next(true);
  }

  get contacts(): FormArray {
    return this.form?.get('contacts') as FormArray;
  }

  get isEditMode(): boolean {
    return this.mode === FormMode.Edit;
  }

  getPhonesFormArray(contactsForm: FormGroup): FormArray {
    return contactsForm?.get('Phones') as FormArray;
  }
}
