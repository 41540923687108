export const dataTableEvents = {
    common: {
        checkedId: 'checked-item-id',
        cancel: 'cancel-item',
        search: 'find-item',
        delete: 'delete-item',
        edit: 'edit-item',
        apply: 'apply-item',
        case: 'case-item',
        details: 'details-item',
        download: 'download-item',
        calendar: 'calendar-item',
        citation: 'citation-item',
        leter: 'leter-item',
        previw: 'previw-item'
    },
    prospects: {
        createQuotation: 'create-quotation-for-item',
        showQuotations: 'show-quotations-for-item',
        showLetters: 'show-letters-for-item'
    },
    quatations: {
        createEngLetter: 'create-letter-for-item'
    },
    alerts: {
        navigateByType: 'navigate-by-type'
    }

};
