import { Injectable } from '@angular/core';
import { ControlCenter, ControlCenterClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class ControlCenterService extends AbstractFormService<ControlCenter> {
  constructor(public webApi: ControlCenterClient) {
    super(webApi);
  }
}
