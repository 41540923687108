import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GetUser } from 'projects/difference/webapi/Difference.WebApi';

@Directive({
  selector: '[accessControl]'
})

export class AccessControlDirective implements OnInit, OnChanges {
  @Input() accessRole: string;
  @Input() user: GetUser;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
    this.elementRef.nativeElement.style.display = 'none';
    this.checkAccess();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.user && changes?.user.currentValue !== undefined) {
      this.checkAccess();
    }
  }

  checkAccess(): void {
    if (this.user?.role === this.accessRole) {
      this.elementRef.nativeElement.style.display = 'block';
    }
  }

}
