import { Contact, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { EmailFormControl } from '../../../controls/email-form-control/email-form-control';
import { NameFormControl } from '../../../controls/name-form-control/name-form-control';
import { PhoneFormControl } from '../../../controls/phone-form-control/phone-form-control';
import { formFields } from '../../../../constants/form-fields';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormValuesChangedModel } from '../../abstract-form';

function getContactSecondFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
  const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

  switch (config.name) {
    case formFields.contactSecondFormComponent.contactRole: {
      return new DropDownFormControl(config.isRequired, isReadOnly, {
        collection: lookupDataService.lookupDataObj[LookupTypeId.ContactRoles],
        valuePropertyName: constants.namePropertyName,
        keyPropertyName: constants.keyPropertyName
      });
    }
    case formFields.contactSecondFormComponent.civility: {
      return new DropDownFormControl(config.isRequired, isReadOnly, {
        collection: lookupDataService.lookupDataObj[LookupTypeId.Civilities],
        valuePropertyName: constants.namePropertyName,
        keyPropertyName: constants.keyPropertyName
      });
    }
    case formFields.contactSecondFormComponent.firstName: {
      return new NameFormControl(config.isRequired, isReadOnly);
    }
    case formFields.contactSecondFormComponent.lastName: {
      return new NameFormControl(config.isRequired, isReadOnly);
    }
    case formFields.contactSecondFormComponent.phone: {
      return new PhoneFormControl(config.isRequired, false);
    }
    case formFields.contactSecondFormComponent.email: {
      return new EmailFormControl(config.isRequired, isReadOnly);
    }
  }
}

function contactsToViewModel(config: DataFieldConfig[], contacts: Contact[]): any[] {
  const viewModel: any[] = [];

  contacts?.forEach((contact: any) => {
    const contactObj: any = {};

    config?.forEach((configItem: any) => {
      const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
      contactObj[configItem.name] = contact[propertyName];
    });

    viewModel.push(contactObj);
  });

  return viewModel;
}

function contactsViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): Contact[] {
  const objModelArray: Contact[] = [];
  const model = data?.model.contacts;

  model?.forEach((contactFormModel: any, index: number) => {
    const contactObjModel: any = {};

    config?.forEach((configItem: any) => {
      const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
      contactObjModel[propertyNameForSaveObj] = contactFormModel[configItem.name];
    });

    // restore id of the contacts
    if (data.formId && data.formId[index]) {
      contactObjModel.contactId = data.formId[index];
    }

    contactObjModel.profileGuid = data.profileGuid;

    objModelArray.push(contactObjModel);
  });

  return objModelArray;
}

export { getContactSecondFormComponentControl, contactsToViewModel, contactsViewModelToDataModel };
