import { ValidatorFn, Validators } from '@angular/forms';
import { sirenValidator } from '../../../validators/sirenValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class SirenFormControl extends AbstractFormControl {
    validators: any;

    constructor(required?: boolean, disabled?: boolean, private options?: SirenOptions) {
        super(required, disabled);
        this.setValue(null);

        this.updateValidators(required);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    private updateValidators(required: boolean): void {
        const validators = [];

        validators.push(sirenValidator());

        if (required) {
            validators.push(Validators.required);
        }

        this.validators = validators;
        super.setValidators(validators);
    }

    protected getValidators(): ValidatorFn[] {
        return this.validators;
    }
}

export interface SirenOptions {
    readOnlyCss?: boolean;
}
