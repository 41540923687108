import { NgModule } from '@angular/core';
import { DateShortPipe } from './date-short.pipe';
import { EuroPipe } from './euro.pipe';
import { PhonePipe } from './phone.pipe';
import { SafeHtml } from './safe-html.pipe';

@NgModule({
  declarations: [
    SafeHtml,
    EuroPipe,
    DateShortPipe,
    PhonePipe
  ],
  imports: [
  ],
  exports: [
    SafeHtml,
    EuroPipe,
    DateShortPipe,
    PhonePipe
  ],
  providers: [
    DateShortPipe
  ]
})
export class PipesModule { }
