import { Injectable } from '@angular/core';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { Accounting, AccountingClient } from 'projects/difference/webapi/Difference.WebApi';
import { map } from 'rxjs/operators';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class AccountingService extends AbstractFormService<Accounting> {
  constructor(
    public webApi: AccountingClient,
    public customerDataService: CustomerDataService
  ) {
    super(webApi);
  }

  public async saveAccounting(model: Accounting): Promise<Accounting> {
    return this.webApi.save(model).pipe(
      map((response: Accounting) => {
        this.customerDataService.setUserId(response?.profileGuid);
        return response;
      })
    ).toPromise();
  }
}
