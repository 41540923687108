<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>

    <input
      matInput
      appTrim
      *ngIf="!capitalizeAll"
      [id]="controlName"
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control form-control-sm"
      [formControlName]="controlName"
      (ngModelChange)="change($event)"
      [placeholder]="placeholderText"
    />

    <input
      matInput
      appTrim
      appCapitalizeAll
      *ngIf="capitalizeAll"
      [id]="controlName"
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control form-control-sm"
      [formControlName]="controlName"
      (ngModelChange)="change($event)"
      [placeholder]="placeholderText"
    />

    <!-- <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span> -->

    <!-- <mat-error *ngIf="!control.hideErrorMessages">
      <span *ngIf="control.errors?.required">{{ control.requiredErrorMessage }}</span>
      <span *ngIf="control.errors?.maxlength"> Saisie incorrecte. Taille maximale autorisée : {{ control.maxLength }} caractères. </span>
    </mat-error> -->
  </mat-form-field>
</div>
