<div *ngIf="form" class="card">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-xl-6">
        <div class="row">
          <div class="col-12">
            <h5>Imposition des bénéfices</h5>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.mainTaxesForm.natureId, config)">
            <app-dropdown-form-control [parentForm]="form" [controlName]="getControlName(formFields.mainTaxesForm.natureId, config)" [title]="'Nature'" [placeholder]="'Sélectionnez la nature'">
            </app-dropdown-form-control>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.mainTaxesForm.methodId, config)">
            <app-dropdown-form-control
              [parentForm]="form"
              [controlName]="getControlName(formFields.mainTaxesForm.methodId, config)"
              [title]="'Mode d\'imposition'"
              [placeholder]="'Sélectionnez le mode d\'imposition'"
            >
            </app-dropdown-form-control>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.mainTaxesForm.currentTaxRegimeId, config)">
            <app-dropdown-form-control
              [parentForm]="form"
              [controlName]="getControlName(formFields.mainTaxesForm.currentTaxRegimeId, config)"
              [title]="'Régime fiscal en cours'"
              [placeholder]="'Sélectionnez le régime fiscal'"
            >
            </app-dropdown-form-control>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-xl-6 mt-3-mobile">
        <div class="row">
          <div class="col-12">
            <h5>Régime de TVA</h5>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.mainTaxesForm.taxationModeId, config)">
            <app-autocomplete-form-control
              [parentForm]="form"
              [controlName]="getControlName(formFields.mainTaxesForm.taxationModeId, config)"
              [title]="'Régime d\'imposition'"
              [placeholder]="'Sélectionnez un régime'"
            >
            </app-autocomplete-form-control>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.mainTaxesForm.tvaNumber, config)">
            <app-name-with-digits-form-control
              [parentForm]="form"
              [controlName]="getControlName(formFields.mainTaxesForm.tvaNumber, config)"
              [title]="'Numéro de TVA intra-communautaire'"
              [placeholder]="'Saisissez un numéro'"
            >
            </app-name-with-digits-form-control>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.mainTaxesForm.responsibleForVatid, config)">
            <app-dropdown-form-control [parentForm]="form" [controlName]="getControlName(formFields.mainTaxesForm.responsibleForVatid, config)" [title]="'Qui fait la tenue de la TVA ?'">
            </app-dropdown-form-control>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
