import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { constants } from '../../../constants/constants';
import { SearchCriterionsDataType } from '../models/search-criterions-data-type';

@Pipe({
  name: 'searchValue'
})
export class SearchValuePipe implements PipeTransform {
  transform(searchValue: any, type: SearchCriterionsDataType): any {
    switch (type) {
      case SearchCriterionsDataType.String: {
        return searchValue;
      }
      case SearchCriterionsDataType.Integer: {
        return searchValue;
      }
      case SearchCriterionsDataType.Decimal: {
        return searchValue;
      }
      case SearchCriterionsDataType.DateTime: {
        return moment(searchValue).format(constants.dateFormat);
      }
      default: {
        return searchValue;
      }
    }
  }
}
