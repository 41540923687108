import { Injectable } from '@angular/core';
import { AccountingTabModel, BesoinTabsModel, ContactTabsModel, InterlocutorsTabsModel, SocialTabModel, TabsClient, TaxesTabModel, VolumesTabModel } from 'projects/difference/webapi/Difference.WebApi';
import { map } from 'rxjs/operators';
import { CustomerDataService } from './customer-data.service';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  constructor(
    private webApi: TabsClient,
    private customerDataService: CustomerDataService
  ) { }

  public async getContracts(profileGuid: string): Promise<ContactTabsModel> {
    return this.webApi.getContracts(profileGuid).pipe(
      map((response: ContactTabsModel) => {
        this.customerDataService.setContacts(response);
        return response;
      })
    ).toPromise();
  }

  public async saveContactTab(model: ContactTabsModel): Promise<ContactTabsModel> {
    return this.webApi.saveContracts(model).pipe(
      map((response: ContactTabsModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setContacts(response);
        return response;
      })
    ).toPromise();
  }

  public async getBesoin(profileGuid: string): Promise<BesoinTabsModel> {
    return this.webApi.getBesoin(profileGuid).pipe(
      map((response: BesoinTabsModel) => {
        this.customerDataService.setBesoin(response);
        return response;
      })
    ).toPromise();
  }

  public async saveBesoinTab(model: BesoinTabsModel): Promise<BesoinTabsModel> {
    return this.webApi.saveBesoin(model).pipe(
      map((response: BesoinTabsModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setBesoin(response);
        return response;
      })
    ).toPromise();
  }

  public async getInterlocutors(profileGuid: string): Promise<InterlocutorsTabsModel> {
    return this.webApi.getInterlocutors(profileGuid).pipe(
      map((response: InterlocutorsTabsModel) => {
        this.customerDataService.setInterlocutors(response);
        return response;
      })
    ).toPromise();
  }

  public async saveInterlocutors(model: InterlocutorsTabsModel): Promise<InterlocutorsTabsModel> {
    return this.webApi.saveInterlocutors(model).pipe(
      map((response: InterlocutorsTabsModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setInterlocutors(response);
        return response;
      })
    ).toPromise();
  }

  public async getVolumes(profileGuid: string): Promise<VolumesTabModel> {
    return this.webApi.getVolumes(profileGuid).pipe(
      map((response: VolumesTabModel) => {
        this.customerDataService.setVolumes(response);
        return response;
      })
    ).toPromise();
  }

  public async saveVolumes(model: VolumesTabModel): Promise<VolumesTabModel> {
    return this.webApi.saveVolumes(model).pipe(
      map((response: VolumesTabModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setVolumes(response);
        return response;
      })
    ).toPromise();
  }

  public async getSocial(profileGuid: string): Promise<SocialTabModel> {
    return this.webApi.getSocial(profileGuid).pipe(
      map((response: SocialTabModel) => {
        this.customerDataService.setSocial(response);
        return response;
      })
    ).toPromise();
  }

  public async saveSocial(model: SocialTabModel): Promise<SocialTabModel> {
    return this.webApi.saveSocial(model).pipe(
      map((response: SocialTabModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setSocial(response);
        return response;
      })
    ).toPromise();
  }

  public async getAccounting(profileGuid: string): Promise<AccountingTabModel> {
    return this.webApi.getAccounting(profileGuid).pipe(
      map((response: AccountingTabModel) => {
        this.customerDataService.setAccounting(response);
        return response;
      })
    ).toPromise();
  }

  public async saveAccounting(model: AccountingTabModel): Promise<AccountingTabModel> {
    return this.webApi.saveAccounting(model).pipe(
      map((response: AccountingTabModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setAccounting(response);
        return response;
      })
    ).toPromise();
  }

  public async getTaxes(profileGuid: string): Promise<TaxesTabModel> {
    return this.webApi.getTaxes(profileGuid).pipe(
      map((response: TaxesTabModel) => {
        this.customerDataService.setTaxes(response);
        return response;
      })
    ).toPromise();
  }

  public async saveTaxes(model: TaxesTabModel): Promise<TaxesTabModel> {
    return this.webApi.saveTaxes(model).pipe(
      map((response: TaxesTabModel) => {
        this.customerDataService.setUserId(response?.profileGuid);
        this.customerDataService.setTaxes(response);
        return response;
      })
    ).toPromise();
  }
}
