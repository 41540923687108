import { Injectable } from '@angular/core';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MenuDataService {
  public tryChangeMenuStep$ = new BehaviorSubject<ClientMenuStep>(null);
  public onChangeStep$ = new BehaviorSubject<ClientMenuStep>(ClientMenuStep.General);

  constructor() { }

  onTryToChangeStep(step: ClientMenuStep) {
    this.tryChangeMenuStep$.next(step)
  }

  onChangeStep(step: ClientMenuStep) {
    this.onChangeStep$.next(step);
  }

}
