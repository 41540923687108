<div *ngIf="form">
  <div class="row mb-2">
    <div class="col fw-600">Merci. Parlons maintenant de vos besoins.</div>
  </div>

  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
          <h5>Prestation</h5>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 m-0"></div>
      </div>
    </div>
    <div class="card-body">
      <app-besoin-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onIntroductionFormValuesChanged($event)"></app-besoin-form>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col inline-tooltip">
          <h5 class="inline-tooltip">
            Bénéficiaires effectifs <app-tooltip [title]="'Bénéficiaires effectifs'" [text]="'Le bénéficiaire effectif s\'entend de toute personne physique possédant directement ou indirectement plus de 25% du capital ou des droits de vote, ou, la personne exerçant un contrôle sur les organes de direction ou de gestion.'" class="inline-tooltip"> </app-tooltip>
          </h5>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 m-0"></div>
      </div>
    </div>
    <div class="card-body">
      <app-benificiaries-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onBeneficiariesFormValuesChanged($event)"></app-benificiaries-form>
    </div>
  </div>

  <div class="row submitCancel">
    <div class="col text-end">
      <button class="btn pink" (click)="goBack()">Précédent</button>
      <button [disabled]="!isBesoinValid" class="btn pink" (click)="submitForm()">Valider</button>
    </div>
  </div>
</div>
