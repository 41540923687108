<div class="row mb-2">
  <div class="col fw-600">Vous avez rempli plus de la moitié des informations ! Nous allons maintenant nous intéresser à votre régime fiscal.</div>
</div>

<div>
  <app-main-taxes-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onMainTaxesFormValuesChanged($event)"></app-main-taxes-form>
</div>

<div class="mt-3">
  <app-deb-des-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onDebDesFormValuesChanged($event)"></app-deb-des-form>
</div>

<div class="mt-3">
  <app-control-center-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onControlCenterFormValuesChanged($event)"></app-control-center-form>
</div>

<div class="mt-3">
  <app-features-of-taxation-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onFeaturesOfTaxationFormValuesChanged($event)"></app-features-of-taxation-form>
</div>

<div class="row submitCancel">
  <div class="col text-end">
    <button class="btn pink" (click)="goBack()">Précédent</button>
    <button [disabled]="!isTaxesValid" class="btn pink" (click)="submitForm()">Valider</button>
  </div>
</div>
