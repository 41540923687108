import { AccountingTabModel, BesoinTabsModel, ContactTabsModel, GeneralInfo, InterlocutorsTabsModel, Introduction, SocialTabModel, TaxesTabModel, VolumesTabModel } from 'projects/difference/webapi/Difference.WebApi';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {

  public userIdValue: string;
  public userId = new Subject<string>();

  public hasCompanyValue: boolean;
  public hasCompany = new Subject<boolean>();

  public customizedMissionsIds$ = new BehaviorSubject<string[]>(null);

  private userDataCleared = new Subject<boolean>();

  public generalInfoFormDataValue: GeneralInfo;
  public generalInfoFormData = new Subject<GeneralInfo>();

  public introductionModelValue: Introduction;
  public introductionModel = new Subject<Introduction>();

  public contactTabsModel$ = new BehaviorSubject<ContactTabsModel>(null);
  public besoinTabsModel$ = new BehaviorSubject<BesoinTabsModel>(null);
  public interlocutorsTabsModel$ = new BehaviorSubject<InterlocutorsTabsModel>(null);
  public volumesTabModel$ = new BehaviorSubject<VolumesTabModel>(null);
  public taxesTabModel$ = new BehaviorSubject<TaxesTabModel>(null);
  public accountingTabModel$ = new BehaviorSubject<AccountingTabModel>(null);
  public socialTabModel$ = new BehaviorSubject<SocialTabModel>(null);

  constructor() { }

  public async clearAllData(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.userIdValue = null;
      this.hasCompanyValue = null;
      this.userDataCleared.next(true);
      resolve(true);
    })
  }

  getUserId(): Observable<string> {
    return this.userId.asObservable();
  }

  setUserId(guid?: string): void {
    if (guid) {
      this.userIdValue = guid;
      this.userId.next(guid);
    }
  }

  setContacts(model: ContactTabsModel): void {
    this.contactTabsModel$.next(model);
  }

  setBesoin(model: BesoinTabsModel): void {
    this.besoinTabsModel$.next(model);
  }

  setInterlocutors(model: InterlocutorsTabsModel): void {
    this.interlocutorsTabsModel$.next(model);
  }

  setVolumes(model: VolumesTabModel): void {
    this.volumesTabModel$.next(model);
  }

  setSocial(model: SocialTabModel): void {
    this.socialTabModel$.next(model);
  }

  setAccounting(model: AccountingTabModel): void {
    this.accountingTabModel$.next(model);
  }

  setTaxes(model: TaxesTabModel): void {
    this.taxesTabModel$.next(model);
  }

  getHasCompany(): Observable<boolean> {
    return this.hasCompany.asObservable();
  }

  setHasCompany(hasCompany?: boolean): void {
    this.hasCompanyValue = hasCompany;
    this.hasCompany.next(hasCompany);
  }

  getCustomizedMissionsIds(): Observable<string[]> {
    return this.customizedMissionsIds$.asObservable();
  }

  setCustomizedMissionsIds(ids?: string[]): void {
    this.customizedMissionsIds$.next(ids);
  }

  setGeneralInfoFormData(data: GeneralInfo) {
    this.generalInfoFormDataValue = data;
    this.generalInfoFormData.next(data);
  }

  setIntroductionModelValue(data: Introduction) {
    this.introductionModelValue = data;
    this.introductionModel.next(data);
  }

}
