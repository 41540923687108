import { LookupTypeId } from './../../../../../services/lookup-data.service';
import { LookupDataService } from '../../../../../services/lookup-data.service';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, ManagementTool, Social } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { FormValuesChangedModel } from '../../abstract-form';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';

function getSocialFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.social.hasDigitalSafeTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.social.digitalSafeTool: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.DigitalSafeTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.social.isIntrestedInDigitalSafeTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.social.wageManagementTool: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.WageManagementTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.social.hasPersonnelInformationSystemTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.social.personnelInformationSystemTool: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.PersonnelInformationSystemTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.social.isIntrestedInPersonnelInformationSystemTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function socialFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): Social {
    const viewModel: any = {};

    if (!data) {
        return null;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

        if (configItem.name === formFields.social.digitalSafeTool) {
            viewModel[configItem.name] = data.digitalSafeTool.tools.map((item: any) => { return item.toString() });

            if (data.digitalSafeTool.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.DigitalSafeTools].otherId;
                viewModel[configItem.name].push(otherId.toString());

                viewModel['otherDigitalSafeTool'] = data.digitalSafeTool.otherToolName;
            }
        } else if (configItem.name === formFields.social.wageManagementTool) {
            viewModel[configItem.name] = data.wageManagementTool.tools.map((item: any) => { return item.toString() });

            if (data.wageManagementTool.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.WageManagementTools].otherId;
                viewModel[configItem.name].push(otherId.toString());

                viewModel['otherWageManagementTool'] = data.wageManagementTool.otherToolName;
            }
        } else if (configItem.name === formFields.social.personnelInformationSystemTool) {
            viewModel[configItem.name] = data.personnelInformationSystemTool.tools.map((item: any) => { return item.toString() });

            if (data.personnelInformationSystemTool.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.PersonnelInformationSystemTools].otherId;
                viewModel[configItem.name].push(otherId.toString());

                viewModel['otherPersonnelInformationSystemTool'] = data.personnelInformationSystemTool.otherToolName;
            }
        } else {
            viewModel[configItem.name] = data[propertyName]
        }

    })

    return viewModel;
}

function socialModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): Social {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

            if (configItem.name === formFields.social.digitalSafeTool) {
                const tools: number[] = model[formFields.social.digitalSafeTool]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherDigitalSafeTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else if (configItem.name === formFields.social.wageManagementTool) {
                const tools: number[] = model[formFields.social.wageManagementTool]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherWageManagementTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else if (configItem.name === formFields.social.personnelInformationSystemTool) {
                const tools: number[] = model[formFields.social.personnelInformationSystemTool]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherPersonnelInformationSystemTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else {
                objModel[propertyNameForSaveObj] = model[configItem.name];
            }
        })

        objModel.socialId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new Social(objModel);
}

export {
    getSocialFormComponentControl,
    socialFormToViewModel,
    socialModelToDataModel
}
