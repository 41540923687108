import { adminConstants } from 'projects/difference-admin/app/shared/constants/constants';

import { Pipe, PipeTransform } from '@angular/core';
import { ProfileXtagsModel } from 'projects/difference/webapi/Difference.WebApi';

@Pipe({ name: 'tag' })
export class TagPipe implements PipeTransform {
  constructor() { }

  transform(tags: ProfileXtagsModel[]) {
    if (!tags || tags.length === 0) {
      return '';
    }

    const text = tags.map((profileXtagsModel: ProfileXtagsModel) => {
      return profileXtagsModel.tag.name
    }).join(', ');

    return `<span title='${text}'> ${text.truncate(adminConstants.maxTagsLengthInColumn)} <span>`;
  }
}
