import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SidebarService {
    public collapseAll$ = new BehaviorSubject(false);
    constructor() { }

    public collapse(): void {
        this.collapseAll$.next(true);
    }
}
