<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      [placeholder]="placeholderText"
      (ngModelChange)="change()"
      type="text"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
    />
  </mat-form-field>

  <mat-error>
    <span *ngIf="control.errors?.dayMonthValidator"> dayMonthValidator </span>
  </mat-error>
</div>
