<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="row">
    <div class="col-12 col-sm-12 col-md-6">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="hasCompany && isShowControl(formFields.introductionForm.nafCode, config)">
          <app-name-with-digits-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.nafCode, config)"
            [title]="'Code NAF'"
            [placeholder]="'Saisissez le code NAF'"
          >
          </app-name-with-digits-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.introductionForm.activityStartDate, config)">
          <app-date-form-control [parentForm]="form" [controlName]="getControlName(formFields.introductionForm.activityStartDate, config)" [title]="activityStartDateTitle"> </app-date-form-control>
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.introductionForm.activityDescription, config)">
        <div class="col-12">
          <app-name-with-digits-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.activityDescription, config)"
            [title]="'Description de l\'activité'"
            [placeholder]="'Décrivez-nous votre activité'"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.introductionForm.activityAreaId, config)">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-autocomplete-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.activityAreaId, config)"
            [title]="'Secteur d\'activité'"
            [placeholder]="'Sélectionnez votre secteur'"
          >
          </app-autocomplete-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isActivityOther && isShowControl(formFields.introductionForm.activityAreaDescription, config)">
          <app-name-with-digits-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.activityAreaDescription, config)"
            [title]="'Merci de préciser le secteur'"
            [placeholder]="'Saisissez le secteur d\'activité'"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.introductionForm.turnover, config)">
          <label class="common-title-label"> {{ turnoverTitle }} <span *ngIf="getControlRequiredState(formFields.introductionForm.turnover, config)">*</span></label>
          <div class="row">
            <div class="col">
              <app-number-form-control
                [parentForm]="form"
                [controlName]="getControlName(formFields.introductionForm.turnover, config)"
                [title]="''"
                [placeholder]="'Entrez le chiffre d\'affaire'"
                class="no-title"
              >
              </app-number-form-control>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.introductionForm.employeesNumber, config)">
          <label class="common-title-label">Nombre de salariés <span *ngIf="getControlRequiredState(formFields.introductionForm.employeesNumber, config)">*</span></label>
          <app-number-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.employeesNumber, config)"
            [title]="''"
            [placeholder]="'Saisissez le nombre de salariés'"
            class="no-title"
            [ngClass]="{ 'two-lines-xl': hasCompany }"
          >
          </app-number-form-control>
        </div>
      </div>

      <div class="row" *ngIf="hasCompany">
        <div class="col">
          <h5 class="mt-3">Répartition de la clientèle</h5>
        </div>
      </div>

      <div class="row" *ngIf="hasCompany">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.introductionForm.individualsPercentage, config)">
          <app-number-form-control [parentForm]="form" [controlName]="getControlName(formFields.introductionForm.individualsPercentage, config)" [title]="'% de particuliers'">
          </app-number-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.introductionForm.professionalsPercentage, config)">
          <app-number-form-control [parentForm]="form" [controlName]="getControlName(formFields.introductionForm.professionalsPercentage, config)" [title]="'% de professionnels'">
          </app-number-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.introductionForm.communitiesPercentage, config)">
          <app-number-form-control [parentForm]="form" [controlName]="getControlName(formFields.introductionForm.communitiesPercentage, config)" [title]="'% de collectivités'">
          </app-number-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.introductionForm.othersPercentage, config)">
          <app-number-form-control [parentForm]="form" [controlName]="getControlName(formFields.introductionForm.othersPercentage, config)" [title]="'% autres'"> </app-number-form-control>
        </div>
      </div>

      <div class="row mt-3 red-text" *ngIf="percentagesSumError">
        <div class="col">
          {{ collections.messages.besoinSumError }}
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-6">
      <div class="row">
        <div class="col-12 missionType">
          <app-checkbox-list-form-control [parentForm]="form" [controlName]="'Missions'" [title]="'Mission(s) souhaitée(s)'" [inline]="true"> </app-checkbox-list-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isConseilOtherMission && isShowControl(formFields.introductionForm.otherMissionRequestDescription, config)">
        <div class="col-12">
          <app-name-with-digits-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.otherMissionRequestDescription, config)"
            [title]="'Merci de préciser la nature de la demande'"
            [placeholder]="'Précisez la nature de la demande'"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isShowControl(formFields.introductionForm.missionsStartDate, config)">
        <div class="col-12">
          <app-date-form-control [parentForm]="form" [controlName]="getControlName(formFields.introductionForm.missionsStartDate, config)" [title]="'Date de début souhaitée'"> </app-date-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isShowControl(formFields.introductionForm.isCustomMissionsStartDate, config)">
        <div class="col">
          <app-checkbox-form-control
            [title]="'Je souhaite que certaines missions démarrent à une date différente'"
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.isCustomMissionsStartDate, config)"
            class="big-text mt-0 in-col"
          >
          </app-checkbox-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isOtherDates">
        <div class="col-12 col-sm-12 col-md-12 col-xl-4" *ngIf="isComptabiliteMission">
          <app-date-form-control [parentForm]="form" [controlName]="comptabiliteDateControlName" [title]="'Comptabilité / Fiscalité'"> </app-date-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-4" *ngIf="isSocialMission">
          <app-date-form-control [parentForm]="form" [controlName]="socialDateControlName" [title]="'Social'"> </app-date-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-4" *ngIf="isJuridicalMission">
          <app-date-form-control [parentForm]="form" [controlName]="juridicalDateControlName" [title]="'Juridique'"> </app-date-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-4" *ngIf="isFiscaliteMission">
          <app-date-form-control [parentForm]="form" [controlName]="fiscaliteDateControlName" [title]="'Fiscalité personnelle'"> </app-date-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-4" *ngIf="isAuditMission">
          <app-date-form-control [parentForm]="form" [controlName]="auditLegalDateControlName" [title]="'Audit légal'"> </app-date-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-4" *ngIf="isConseilOtherMission">
          <app-date-form-control [parentForm]="form" [controlName]="conseilOtherDateControlName" [title]="'Conseil / Autre'"> </app-date-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isShowControl(formFields.introductionForm.details, config)">
        <div class="col-12">
          <app-info-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.details, config)"
            [title]="'Précisions'"
            [placeholder]="'Avez-vous des précisions sur l\'entreprise ou la mission que vous souhaitez nous communiquer ?'"
          >
          </app-info-form-control>
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.introductionForm.availability, config)">
        <div class="col-12">
          <app-info-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.introductionForm.availability, config)"
            [title]="'Disponibilités'"
            [placeholder]="'Quand seriez-vous disponibles pour que nous puissons vous joindre ou convenir d\'un rendez-vous ?'"
          >
          </app-info-form-control>
        </div>
      </div>
    </div>
  </div>
</div>
