import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({ name: 'facturation' })
export class FacturationPipe implements PipeTransform {
  constructor() { }

  transform(form: FormGroup) {
    const facturation = form.get('billingId').value;

    return facturation; // todo: convert id to text when api will be ready
  }
}
