export const adminConstants = {
    dataTableLanguageSettings: { // https://datatables.net/reference/option/language
        info: '_START_ à _END_ de _TOTAL_',
        infoEmpty: '0 à 0 de 0',
        lengthMenu: 'Afficher _MENU_ par page',
        zeroRecords: 'Pas de données',
        paginate: {
            first: 'Premier',
            last: 'Dernier',
            next: 'Suivant',
            previous: 'Précédent'
        }
    },
    otherToolControlName: 'otherTool',
    otherMoneyToolName: 'otherMoneyTool',
    otherMoneyToolsName: 'otherMoneyTools',
    otherStocksToolName: 'otherStocksTool',
    otherElectronicInvoicingToolName: 'otherElectronicInvoicingTool',
    maxTagsLengthInColumn: 70
};
