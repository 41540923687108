<div class="row mt-3">
  <div class="col-12">
    <div class="modes">
      <div class="mode-filter" [class.active]="isFilterActive(contants.fileFilterModes.bilan)" (click)="setFilter(contants.fileFilterModes.bilan)">Bilan</div>
      <div class="mode-filter" [class.active]="isFilterActive(contants.fileFilterModes.previsionnel)" (click)="setFilter(contants.fileFilterModes.previsionnel)">Prévisionnel</div>
      <div class="mode-filter" [class.active]="isFilterActive(contants.fileFilterModes.piecesIdentite)" (click)="setFilter(contants.fileFilterModes.piecesIdentite)">Pièces d'identité</div>
      <div class="mode-filter" [class.active]="isFilterActive(contants.fileFilterModes.declarations)" (click)="setFilter(contants.fileFilterModes.declarations)">Déclarations</div>
      <div class="mode-filter" [class.active]="isFilterActive(contants.fileFilterModes.autres)" (click)="setFilter(contants.fileFilterModes.autres)">Autres</div>
    </div>
  </div>
</div>

<div class="row mt-4" *ngIf="!isViewMode">
  <div class="col-12">
    <app-file-uploader [files]="filesToUploader" [multiple]="false" (onChange)="onFilesUploaderChanged($event)" [hideAfterUpload]="false"></app-file-uploader>
  </div>
</div>

<div class="row" *ngIf="data?.length > 0">
  <div class="col">
    <app-client-side-data-table [id]="'files-table-id'" [data]="data" [columns]="columns" [sort]="sort" [eventService]="filesEventsService"></app-client-side-data-table>
  </div>
</div>

<div class="row mt-6" *ngIf="!data || data?.length === 0">
  <div class="col text-center">
    <h6>Aucun fichier transmis</h6>
  </div>
</div>
