export const eventServicesList = {
    prospects: 'prospects',
    files: 'files',
    alerts: 'alerts',
    devisFooters: 'devisFooters',
    devisHeaders: 'devisHeaders',
    devisModels: 'devisModels',
    preconcus: 'preconcus',
    prestations: 'prestations',
    profiles: 'profiles',
    devisConsultation: 'devisConsultation',
    lettreMissionAnnexes: 'lettreMissionAnnexes',
    lettreMissionHeader: 'lettreMissionHeader',
    lettreMissionModels: 'lettreMissionModels',
    lettreMissionConsult: 'lettreMissionConsult',
    lettreMissionConsultEditApplications: 'lettreMissionConsultEditApplications'
};
