import { adminConstants } from 'projects/difference-admin/app/shared/constants/constants';

import { Pipe, PipeTransform } from '@angular/core';
import { IntroductionModel } from 'projects/difference/webapi/Difference.WebApi';

@Pipe({ name: 'tag' })
export class TypePrestationPipe implements PipeTransform {
  constructor() { }

  transform(introductionModels: IntroductionModel[]) {
    if (!introductionModels || introductionModels.length === 0) {
      return '';
    }

    const text = introductionModels.map((introductionModel: IntroductionModel) => {
      return introductionModel.missions.missionType.name;
    }).join(', ');

    return `<span title='${text}'> ${text.truncate(adminConstants.maxTagsLengthInColumn)} <span>`;
  }
}
