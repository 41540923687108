<div class="file-uploader">
  <file-upload [control]="fileUploadControl" [multiple]="multiple">
    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
      <file-upload-drop-zone>
        <ng-container> <img src="../../../../../assets/img/upload-icon.svg" alt="" /> {{ title }} </ng-container>
      </file-upload-drop-zone>
    </ng-template>
  </file-upload>

  <div class="error-list" *ngIf="errors">
    <div *ngFor="let errorMessage of errorMessages">
      {{ errorMessage }}
    </div>
  </div>

  <div class="file-list" *ngIf="fileUploadControl.value.length > 0 && !hideChosenFiles">
    <div class="file-item" *ngFor="let file of fileUploadControl.value">{{ file.name }}</div>
  </div>
  <!-- <div class="file-list" *ngIf="fileUploadControl.value.length > 0">
    <div class="file-item" *ngFor="let file of fileUploadControl.value" (click)="removeFile(file)"><span class="delete-icon">✘</span> {{ file.name }}</div>
  </div> -->
</div>
