<div class="row mb-2">
  <div class="col fw-600">Voici l'étape finale. Vous pouvez ajouter ici les fichiers que vous souhaitez nous transmettre.</div>
</div>

<app-files-form [mode]="FormMode.Edit" [profileGuid]="profileGuid"></app-files-form>

<div class="row submitCancel">
  <div class="col text-end">
    <button class="btn pink" (click)="goBack()">Précédent</button>
    <button class="btn pink" (click)="submitForm()">Valider</button>
  </div>
</div>
