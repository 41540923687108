import { Injectable } from '@angular/core';
import { Introduction, IntroductionClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class IntroductionService extends AbstractFormService<Introduction> {
  constructor(public webApi: IntroductionClient) {
    super(webApi);
  }
}
