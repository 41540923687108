import { Injectable } from '@angular/core';
import { CompanyAgreement, CompanyAgreementClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class CompanyAgreementService extends AbstractFormService<CompanyAgreement> {
  constructor(public webApi: CompanyAgreementClient) {
    super(webApi);
  }
}
