import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { FileSaverService } from 'ngx-filesaver';
import { dataTableEvents } from 'projects/difference-admin/app/shared/constants/data-table-events';
import { DataTableItem } from 'projects/difference-admin/app/shared/models/data-table-item';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { ThreeDotsMenuPipe } from 'projects/difference-admin/app/shared/pipes/three-dots-menu.pipe';
import { EventsServiceAction, EventsServiceData } from 'projects/difference-admin/app/shared/services/base-event-service';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { openFileInNewTab, saveFile } from 'projects/difference/app/shared/extentions/extensions';
import { AbstractForm } from '../../abstract-form';
import { FilesEventsService } from './files-events.service';
import { FilesService } from './files-form.service';

@Component({
  selector: 'app-files-form',
  templateUrl: './files-form.component.html',
  styleUrls: ['./files-form.component.scss']
})
export class FilesFormComponent extends AbstractForm<any> implements OnChanges {
  @Input() mode: FormMode;

  public contants = constants;
  public filterType: number = this.contants.fileFilterModes.bilan;
  public data: any[];

  public columns: DataTables.ColumnSettings[];
  public sort: (string | number)[][];
  public dataTableMenuItems: DataTableItem[];
  public uploaderFiles: File[];
  public filesToUploader: File[];

  constructor(
    private router: Router,
    public threeDotsMenu: ThreeDotsMenuPipe,
    public filesEventsService: FilesEventsService,
    public filesService: FilesService,
    private fileSaverService: FileSaverService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      await this.runFormRequiredOperations();
      this.initSubscriptions();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.initTable();
    this.config = await this.filesService.getConfig();
  }

  async restoreAnswers(): Promise<void> {
    this.data = await (await this.filesService.get(this.profileGuid))?.filter((item: any) => {
      return item.type === this.filterType;
    });

    if (!this.data) {
      this.data = [];
    }
  }

  initSubscriptions() {
    this.subscriptionHandler.subscriptions = this.filesEventsService.getSubject().subscribe(async (data: EventsServiceData) => {
      switch (data.action) {
        case EventsServiceAction.Download: {
          const fileGuid = data.id.toString();
          const file = await this.filesService.downloadFileBase64(fileGuid);
          // const blob = new Blob([file.base64File]);
          // saveAs(blob, file.fileName);

          const type = this.fileSaverService.genType(file.fileName);
          saveFile(file.fileName, type, file.base64File)
          break;
        }
        case EventsServiceAction.Previw: {
          const fileGuid = data.id.toString();
          const file = await this.filesService.downloadFileBase64(fileGuid);
          const type = this.fileSaverService.genType(file.fileName);
          // const blob = new Blob([file.base64File], { type: type });
          // this.fileSaverService.save(blob, file.fileName, type);
          openFileInNewTab(file.fileName, type, file.base64File);
          // const url = window.URL.createObjectURL(blob);
          // window.open(url);
          break;
        }
        case EventsServiceAction.Delete: {
          const fileGuid = data.id.toString();
          await this.filesService.delete(fileGuid);
          this.restoreAnswers();
          break;
        }
      }
    })
  }

  async onFilesUploaderChanged(files: File[]) {
    this.uploaderFiles = files;
    this.filesToUploader = [];
    await this.filesService.saveFile(this.filterType, files[0], this.profileGuid);
    this.restoreAnswers();
  }

  async setFilter(type: number): Promise<void> {
    this.filterType = type;

    this.data = await (await this.filesService.get(this.profileGuid))?.filter((item: any) => {
      return item.type === this.filterType;
    });
  }

  isFilterActive(type: number): boolean {
    return this.filterType === type;
  }

  initTable(): void {
    const that = this;
    this.dataTableMenuItems = [
      { title: 'Télécharger', event: dataTableEvents.common.download, iconClass: 'download' },
      { title: 'Prévisualiser', event: dataTableEvents.common.previw, iconClass: 'search' },
      { title: 'Supprimer', event: dataTableEvents.common.delete, iconClass: 'delete' }
    ]
    this.sort = [[0, 'asc']];
    this.columns = [
      {
        title: 'Nom du fichier',
        data: 'name'
      },
      {
        title: 'Actions',
        width: '50px',
        data: 'fileGuid',
        searchable: false,
        orderable: false,
        render: function (data: any, type: any, full: any) {
          return that.threeDotsMenu.transform(data, that.dataTableMenuItems);
        }
      }
    ]
  }

  public get isViewMode(): boolean {
    return this.mode === FormMode.View;
  }

}
