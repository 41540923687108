import { ValidatorFn } from '@angular/forms';
import { collections } from '../../../constants/collections';
import { matDatepickerMaxValidator } from '../../../validators/matDatepickerMaxValidator';
import { matDatepickerMinValidator } from '../../../validators/matDatepickerMinValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class DateFormControl extends AbstractFormControl {
  maxDate: Date;
  minDate: Date;

  constructor(required?: boolean, disabled?: boolean, private options?: DateOptions) {
    super(required, disabled);

    if (options?.defaultValue) {
      super.setValue(options.defaultValue);
    } else {
      super.setValue(null);
    }
  }

  protected getValidators(): ValidatorFn[] {
    return [matDatepickerMaxValidator(), matDatepickerMinValidator()];
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    super.setValue(value, options);
  }

  get requiredErrorMessage(): string {
    if (this.options && this.options.requiredErrorMessage) {
      return this.options.requiredErrorMessage;
    } else {
      return collections.messages.required;
    }
  }

  get minDateErrorMessage(): string {
    if (this.options && this.options.minDateErrorMessage) {
      return this.options.minDateErrorMessage;
    } else {
      return collections.messages.required;
    }
  }

  get maxDateErrorMessage(): string {
    if (this.options && this.options.maxDateErrorMessage) {
      return this.options.maxDateErrorMessage;
    } else {
      return collections.messages.required;
    }
  }

  get hideSuccessMark(): boolean {
    return this.options?.hideSuccessMark;
  }

  get hideErrorMessages(): boolean {
    return this.options?.hideErrorMessages;
  }
}

export interface DateOptions {
  requiredErrorMessage?: string;
  minDateErrorMessage?: string;
  maxDateErrorMessage?: string;
  hideSuccessMark?: boolean;
  hideErrorMessages?: boolean;
  defaultValue?: Date;
}
