import { Injectable } from '@angular/core';
import { FilterOperator, FilterRequestItem } from 'projects/difference/webapi/Difference.WebApi';
import {
  SearchCriterionsDataType
} from 'projects/difference/app/shared/components/data-table/models/search-criterions-data-type';
import * as _ from 'lodash';
import { ActiveInactiveEnum } from 'projects/difference/app/shared/components/data-table/models/search-operator-model';

@Injectable({
  providedIn: 'root'
})
export class ClientSideSearchService {

  doFilterItems(filterItem: FilterRequestItem, data: any, type: SearchCriterionsDataType): any {
    if (type === SearchCriterionsDataType.Boolean) {
      return data
        .filter((item: any) => _.get(item, filterItem.propertyNames[0]) === (filterItem.propertyValue === ActiveInactiveEnum.INACTIVE));
    } else {
      switch (filterItem.operator) {
        case FilterOperator.Equal: {
          return data
            .filter((item: any) => _.toLower(_.toString(_.get(item, filterItem.propertyNames[0]))) === filterItem.propertyValue?.toLowerCase()?.toString());
        }
        case FilterOperator.NotEqual: {
          return data
            .filter((item: any) => _.toLower(_.toString(_.get(item, filterItem.propertyNames[0]))) !== filterItem.propertyValue?.toLowerCase()?.toString());
        }
        case FilterOperator.Contains: {
          return data
            .filter((item: any) => _.includes(_.toLower(_.toString(_.get(item, filterItem.propertyNames[0]))), filterItem.propertyValue?.toLowerCase()?.toString()));
        }
      }
    }
  }
}
