<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="row" *ngIf="isShowControl(formFields.contactMainForm.isCompany, config)">
    <div class="col">
      <app-checkbox-form-control [title]="'Personne morale'" [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.isCompany, config)" class="mb-0-fg">
      </app-checkbox-form-control>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="isCompany && isShowControl(formFields.contactMainForm.firstName, config)">
      <app-company-name-autocomplete-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.contactMainForm.firstName, config)"
        [title]="'Nom'"
        [placeholder]="'Saisissez le nom commercial de l\'entité'"
        [http]="http"
        [selectedCompanyName]="selectedCompanyName"
        (onSelected)="onCompanySelected($event)"
      ></app-company-name-autocomplete-form-control>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="isShowControl(formFields.contactMainForm.jurTypeId, config)">
      <app-autocomplete-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.jurTypeId, config)" [placeholder]="jurTypeText" [title]="formeJuridiqueTitle">
      </app-autocomplete-form-control>
    </div>
  </div>
  <div class="row" *ngIf="!isCompany">
    <div class="col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.contactMainForm.lastName, config)">
      <app-name-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.lastName, config)" [title]="'Nom'" [placeholder]="'Entrez votre nom'">
      </app-name-form-control>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6" *ngIf="isShowControl(formFields.contactMainForm.firstName, config)">
      <app-name-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.firstName, config)" [title]="'Prénom'" [placeholder]="'Entrez votre prénom'">
      </app-name-form-control>
    </div>
  </div>
  <div class="row" *ngIf="isShowControl(formFields.contactMainForm.commercialName, config)">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <app-name-with-digits-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.contactMainForm.commercialName, config)"
        [title]="'Nom commercial'"
        [placeholder]="'Saisissez le nom commercial de l\'entité'"
      >
      </app-name-with-digits-form-control>
    </div>
  </div>
  <div class="row" *ngIf="hasCompany && isShowControl(formFields.contactMainForm.sirenNumber, config)">
    <div class="col-12">
      <app-siren-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.sirenNumber, config)" [title]="'Numéro SIREN'"> </app-siren-form-control>
    </div>
  </div>
  <div class="row" *ngIf="isShowControl(formFields.contactMainForm.addressLine, config)">
    <div class="col-12">
      <app-name-with-digits-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.contactMainForm.addressLine, config)"
        [title]="'Adresse'"
        [placeholder]="'Saisissez l\'adresse de l\'entité'"
      >
      </app-name-with-digits-form-control>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-3" *ngIf="isShowControl(formFields.contactMainForm.zipCode, config)">
      <app-zipcode-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.zipCode, config)" [title]="'Code postal'" [placeholder]="'Code postal'">
      </app-zipcode-form-control>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-9" *ngIf="isShowControl(formFields.contactMainForm.city, config)">
      <app-name-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.city, config)" [title]="'Ville'" [placeholder]="'Ville'"> </app-name-form-control>
    </div>
  </div>
  <div class="row">
    <div class="col-10 col-sm-10 col-md-10 col-lg-5" *ngIf="isShowControl(formFields.contactMainForm.office, config)">
      <app-dropdown-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.office, config)" [title]="'Cabinet souhaité'" [placeholder]="'Sélectionnez le cabinet'">
      </app-dropdown-form-control>
    </div>
    <div class="col-2 col-sm-2 col-md-2 col-lg-1">
      <app-tooltip [title]="'Cabinet souhaité'" [text]="'Cabinet souhaité text'"> </app-tooltip>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.contactMainForm.sourceInfoAboutUs, config)">
      <app-dropdown-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.contactMainForm.sourceInfoAboutUs, config)"
        [title]="'Comment nous avez-vous connus ?'"
        [placeholder]="'Sélectionnez une réponse'"
      >
      </app-dropdown-form-control>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-xl-12" *ngIf="isSourceInfoAboutUsSurPrescription && isShowControl(formFields.contactMainForm.partnerName, config)">
      <app-dropdown-form-control [parentForm]="form" [controlName]="getControlName(formFields.contactMainForm.partnerName, config)" [title]="'Type de prescripteur'"> </app-dropdown-form-control>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-xl-12" *ngIf="isSourceInfoAboutUsOther && isShowControl(formFields.contactMainForm.sourceInfoAboutUsOther, config)">
      <app-name-with-digits-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.contactMainForm.sourceInfoAboutUsOther, config)"
        [title]="'Pouvez-vous préciser ?'"
        [placeholder]="'Comment nous avez-vous connus ?'"
      >
      </app-name-with-digits-form-control>
    </div>
  </div>
</div>
