<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="row">
    <div class="col-12 col-md-6" *ngIf="isShowControl(formFields.additionalInfo.isAccountantEverHired, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.additionalInfo.isAccountantEverHired, config)"
        [title]="'Êtes-vous déjà engagé auprès d\'un expert-comptable ?'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>

    <div class="col-12 col-md-6" *ngIf="isShowControl(formFields.additionalInfo.isCommitmentEndDateKnown, config)">
      <app-radio-group-form-control
        *ngIf="isCertifiedAccountant"
        [parentForm]="form"
        [controlName]="getControlName(formFields.additionalInfo.isCommitmentEndDateKnown, config)"
        [title]="'Connaissez-vous la date de fin de l\'engagement ?'"
        [titleTooltipText]="'Date de fin d\'engagement définie dans le document contractuel signé avec votre expert comptable actuel.'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>

    <div class="col-12 col-md-4" *ngIf="isCommitmentEndDateKnown && isShowControl(formFields.additionalInfo.commitmentEndDate, config)">
      <app-date-form-control [parentForm]="form" [controlName]="getControlName(formFields.additionalInfo.commitmentEndDate, config)" [title]="'Date de fin de l\'engagement'"> </app-date-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isCertifiedAccountant && isShowControl(formFields.additionalInfo.currentAccountantCompanyName, config)">
      <app-name-with-digits-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.additionalInfo.currentAccountantCompanyName, config)"
        [placeholder]="'Saisissez un nom'"
        [title]="'Nom du cabinet d\'expertise comptable actuel'"
      >
      </app-name-with-digits-form-control>
    </div>

    <div class="col-12 col-sm-12 col-md-6 col-lg-4">
      <app-name-form-control
        *ngIf="isCertifiedAccountant && isShowControl(formFields.additionalInfo.currentAccountantName, config)"
        [parentForm]="form"
        [controlName]="getControlName(formFields.additionalInfo.currentAccountantName, config)"
        [title]="'Nom de l\'expert-comptable actuel'"
        [placeholder]="'Saisissez un nom'"
      >
      </app-name-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isShowControl(formFields.additionalInfo.corporateAccountsClosingDate, config)">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4">
      <app-day-month-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.additionalInfo.corporateAccountsClosingDate, config)"
        [title]="'Date de clôture des comptes sociaux'"
        [placeholder]="'JJ/MM'"
      >
      </app-day-month-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="common-title-label"
        >Organisation comptable et administrative actuelle <span *ngIf="getControlRequiredState(formFields.additionalInfo.currentOrganizationDescription, config)">*</span></label
      >
    </div>
  </div>

  <div class="row" *ngIf="isShowControl(formFields.additionalInfo.currentOrganizationDescription, config)">
    <div class="col-12">
      <app-info-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.additionalInfo.currentOrganizationDescription, config)"
        [title]="''"
        [placeholder]="'Décrivez l\'organisation comptable et administrative actuelle de votre entité'"
        class="no-title"
      >
      </app-info-form-control>
    </div>
  </div>
</div>
