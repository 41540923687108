<div class="container">
  <div class="menu-tabs mt-2">
    <div class="row">
      <div class="tabs-container">
        <ul class="tabs group nav nav-tabs">
          <li
            class="nav-item"
            (click)="onMenuClick(ClientMenuSteps.General)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.General"
            [class.inactive]="isContactsInactive"
            [ngClass]="{ 'viewed-header-link': isContactsViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/contact-icon.svg" class="menu-icon-white" alt="" />
              <span class="d-none d-sm-inline-block">Contacts</span>
            </span>
          </li>
          <li
            class="nav-item"
            (click)="onMenuClick(ClientMenuSteps.Besoin)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Besoin"
            [class.inactive]="isBesoinInactive"
            [ngClass]="{ 'viewed-header-link': isBesoinViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/prestation-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Besoin</span>
            </span>
          </li>
          <li
            class="nav-item"
            *ngIf="isInformationShowing"
            (click)="onMenuClick(ClientMenuSteps.Interlocutors)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Interlocutors"
            [class.inactive]="isInformationInactive"
            [ngClass]="{ 'viewed-header-link': isInformationViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/info-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Interlocuteurs</span>
            </span>
          </li>
          <li
            class="nav-item"
            *ngIf="isVolumesShowing"
            (click)="onMenuClick(ClientMenuSteps.Volumes)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Volumes"
            [class.inactive]="isVolumesInactive"
            [ngClass]="{ 'viewed-header-link': isVolumesViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/volumes-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Volumes à traiter</span>
            </span>
          </li>
          <li
            class="nav-item"
            *ngIf="isRegimeShowing"
            (click)="onMenuClick(ClientMenuSteps.Taxes)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Taxes"
            [class.inactive]="isRegimeInactive"
            [ngClass]="{ 'viewed-header-link': isRegimeViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/regime-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Régime fiscal</span>
            </span>
          </li>
          <li
            class="nav-item"
            *ngIf="isComptabiliteShowing"
            (click)="onMenuClick(ClientMenuSteps.Accounting)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Accounting"
            [class.inactive]="isComptabiliteInactive"
            [ngClass]="{ 'viewed-header-link': isComptabiliteViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/comptabilite-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Comptabilité</span>
            </span>
          </li>
          <li
            class="nav-item"
            *ngIf="isSocialShowing"
            (click)="onMenuClick(ClientMenuSteps.Social)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Social"
            [class.inactive]="isSocialInactive"
            [ngClass]="{ 'viewed-header-link': isSocialViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/social-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Social</span>
            </span>
          </li>
          <li
            class="nav-item"
            (click)="onMenuClick(ClientMenuSteps.Files)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Files"
            [class.inactive]="isFilesInactive"
            [ngClass]="{ 'viewed-header-link': isFilesViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/files-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Fichiers</span>
            </span>
          </li>
          <li
            class="nav-item"
            (click)="onMenuClick(ClientMenuSteps.Confirmation)"
            [class.active]="currentClientMenuStep === ClientMenuSteps.Confirmation"
            [class.inactive]="isConfirmationInactive"
            [ngClass]="{ 'viewed-header-link': isConfirmationViewed }"
          >
            <span class="nav-link">
              <img src="../../../assets/img/prestation-icon.svg" class="menu-icon-black" alt="" />
              <span class="d-none d-sm-inline-block">Confirmation</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
