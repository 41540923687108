import { FormControl } from '@angular/forms';

export function sirenValidator() {
    return (control: FormControl) => {
        const sirenCode = control.value;

        if (sirenCode) {
            if (!/[0-9]{9}$/.test(sirenCode)) {
                return {
                    sirenValidator: true
                };
            } else {
                return null;
            }
        }

        return null;
    };
}
