<div class="form-group" [class.required]="isRequired" [class.disabled]="isDisabled" [formGroup]="parentForm" [class.inline]="isInline" [class.one-in-row]="isOneInRow">
  <mat-radio-group [formControlName]="controlName" class="radio-group-checkboxes" (ngModelChange)="change()">
    <span class="mat-slide-toggle-content"
      >{{ title }} <span class="mat-form-field-required-marker" *ngIf="isRequired">*</span>
      <app-tooltip *ngIf="titleTooltipText" [title]="title" [text]="titleTooltipText" class="inline-tooltip"></app-tooltip
    ></span>
    <div class="mat-radio-buttons-wrapper">
      <mat-radio-button class="radio-button-checkboxes" *ngFor="let item of collection" [value]="item[keyPropertyName]">
        <span class="fs-14">{{ item[valuePropertyName] }}</span>
      </mat-radio-button>
    </div>
  </mat-radio-group>
</div>
