<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>

    <input
      #input
      type="text"
      [placeholder]="placeholderText"
      matInput
      [formControlName]="controlName"
      [matAutocomplete]="auto"
      (input)="filter()"
      (focus)="filter()"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control form-control-sm"
    />

    <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayWith.bind(this)" [panelWidth]="panelWidthValue">
      <mat-option *ngFor="let option of filteredOptions" [value]="option[autocompleteIdField]">
        {{ option[autocompleteValueField] }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
