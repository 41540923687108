import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({ name: 'invoiceGatel' })
export class InvoiceGatelPipe implements PipeTransform {
  constructor() { }

  transform(form: FormGroup) {
    const invoice = form.get('invoice').value;
    const gatel = form.get('gatel').value;

    if (invoice && gatel) {
      return `${invoice}/${gatel}`;
    } else if (invoice) {
      return `${invoice}/-`;
    } else if (gatel) {
      return `-/${gatel}`;
    } else return '';
  }
}
