<div>
  <div class="row">
    <div class="col">
      <h5>Bienvenue ! Tout d'abord nous aimerions en savoir plus sur votre organisation et sur vous.</h5>
    </div>
  </div>
  <div class="row mt-2 mb-2">
    <div class="col fw-600">Toutes les informations marquées d’un astérisque (*) dans ce formulaire sont des informations obligatoires.</div>
  </div>

  <div class="card">
    <div class="card-header p-0">
      <div class="modes">
        <div class="mode" [class.active]="hasCompany" (click)="setHasCompany(true)">Activité existante</div>
        <div class="mode" [class.active]="!hasCompany" (click)="setHasCompany(false)">Je crée mon entreprise</div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
          <app-contact-main-form [profileGuid]="profileGuid" [mode]="FormMode.Edit" (onFormValuesChanged)="onMainFormValuesChanged($event)"></app-contact-main-form>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
          <app-contact-second-form [profileGuid]="profileGuid" [mode]="FormMode.Edit" [generalInfo]="generalInfo" (onFormValuesChanged)="onContactFormValuesChanged($event)"></app-contact-second-form>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 mb-3">
          <mat-checkbox [(ngModel)]="agreePersonalDataSharing" class="big-text mb-2">
            En cochant cette case, je consens à <span (click)="goToCabinet($event)" class="link-like"> {{ companyName }}</span> le droit d’utiliser mes données à caractère personnel recueillies
            ci-dessus pour me contacter et répondre à mes demandes.
          </mat-checkbox>
          <mat-checkbox [(ngModel)]="agreeMail" class="big-text"> J'autorise également l'envoi d'offres commerciales à mon adresse email. </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="row submitCancel">
    <div class="col text-end">
      <button [disabled]="!isContactsValid" class="btn pink" (click)="submitContactForm()">Valider</button>
    </div>
  </div>
</div>
