<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>

    <input
      #inputAutoComplete
      matInput
      appTrim
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control form-control-sm"
      [formControlName]="controlName"
      [id]="controlName"
      [matAutocomplete]="auto"
      [placeholder]="placeholderText"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="itemSelected($event)" [displayWith]="useDisplayWith ? displayWith.bind(this) : null">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="useDisplayWith ? option[autocompleteValueField] : option[autocompleteLabelField]">
        {{ getOptionText(option) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
