import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, FeaturesTaxation } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { InfoFormControl } from '../../../controls/info-form-control/info-form-control';
import { FormValuesChangedModel } from '../../abstract-form';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';

function getFeaturesTaxationFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.featuresTaxationForm.features: {
            return new InfoFormControl(config.isRequired, isReadOnly, true)
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function featuresTaxationFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): any[] {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
        viewModel[configItem.name] = data[propertyName];
    })

    return viewModel;
}

function featuresTaxationViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): FeaturesTaxation {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            objModel[propertyNameForSaveObj] = model[configItem.name];
        })

        objModel.taxFeaturesTaxationId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new FeaturesTaxation(objModel);
}

export {
    getFeaturesTaxationFormComponentControl,
    featuresTaxationFormToViewModel,
    featuresTaxationViewModelToDataModel
}
