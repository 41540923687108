import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { AbstractForm } from '../../abstract-form';
import { DebDesService } from './deb-des-form.service';
import { DataFieldConfig, DebDes } from 'projects/difference/webapi/Difference.WebApi';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { debDesFormToViewModel, getDebDesFormComponentControl } from './deb-des-form.extensions';

@Component({
  selector: 'app-deb-des-form',
  templateUrl: './deb-des-form.component.html',
  styleUrls: ['./deb-des-form.component.scss']
})
export class DebDesFormComponent extends AbstractForm<DebDes> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public isDealOutsideEuropeControl: RadioGroupFormControl;
  public isDebControl: RadioGroupFormControl;
  public isDesControl: RadioGroupFormControl;

  constructor(
    public debDesService: DebDesService,
    public lookupDataService: LookupDataService

  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.debDesService.getConfig(); 
    this.formData = await this.debDesService.get(this.profileGuid);
    this.formId = this.formData?.taxDebDesId;
  }

  initControls(): void {
    this.isDealOutsideEuropeControl = this.form.get(formFields.debDesForm.isDealOutsideEurope) as RadioGroupFormControl;
    this.isDebControl = this.form.get(formFields.debDesForm.isDeb) as RadioGroupFormControl;
    this.isDesControl = this.form.get(formFields.debDesForm.isDes) as RadioGroupFormControl;

    this.subscriptionHandler.subscriptions = this.isDealOutsideEuropeControl.valueChanges.subscribe((value: boolean) => {
      this.isDebControl.markRequiredOrNot(value);
      this.isDesControl.markRequiredOrNot(value);

      if (value === false) {
        this.isDebControl.patchValue(null);
        this.isDesControl.patchValue(null);
      }
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getDebDesFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  restoreAnswers(): void {
    const viewModel = debDesFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  get isDealOutsideEurope(): boolean {
    return this.isDealOutsideEuropeControl?.value === true;
  }

}
