import { ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class CheckboxFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, options?: CheckboxOptions) {
      super(required, disabled);
      this.setValue(false);

      if (options && options.defaultValue) {
        this.setValue(options.defaultValue);
      }
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }
}

export interface CheckboxOptions {
  defaultValue: boolean;
}
