import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CustomerDataService } from '../../services/customer-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { constants } from '../../shared/constants/constants';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent extends AbstractClientTabComponent implements OnInit {
  public contants = constants;
  public filterType: number = this.contants.fileFilterModes.bilan;
  public FormMode = FormMode;
  public profileGuid: string;

  constructor(
    public route: ActivatedRoute,
    public progressBarService: ProgressBarService,
    public customerDataService: CustomerDataService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Files, bsModalService);
  }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.files.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep) {
        return;
      }

      return this.menuDataService.onChangeStep(step);
    });
  }

  setFilter(type: number): void {
    this.filterType = type;
  }

  isFilterActive(type: number): boolean {
    return this.filterType === type;
  }

  goBack(): void {
    return this.menuDataService.onChangeStep(ClientMenuStep.Social);
  }

  async submitForm(): Promise<void> {
    return this.menuDataService.onTryToChangeStep(ClientMenuStep.Confirmation);
  }

}

export class MyFile {
  id?: number;
  name?: string;
}
