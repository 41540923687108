import { Pipe, PipeTransform } from '@angular/core';
import { DataTableItem } from '../models/data-table-item';

@Pipe({ name: 'threeDotsMenu' })
export class ThreeDotsMenuPipe implements PipeTransform {
  constructor() { }

  getItemsHtml(id: string, dataTableMenuItems: DataTableItem[]): string {
    let res = '';

    dataTableMenuItems.forEach((item: DataTableItem, index: number) => {
      res += `<span class="menu-item" ${item.event}='${id}'>`
      res += `<div ${item.event}='${id}' class='table-menu-icon ${item.iconClass}'></div> ${item.title}`
      res += '</span>'

      if (index !== dataTableMenuItems.length - 1) {
        res += '<span class="menu-item-separator"></span>';
      }
    })

    return res;
  }

  transform(id: string, dataTableMenuItems: DataTableItem[]) {
    return `<div class='three-dots-menu' id='threeDotsMenu'>
    <div class='three-dots div-to-center' id='threeDots'>&#8943</div>
    <div id="myDropdown" class="dropdown-content">` + this.getItemsHtml(id, dataTableMenuItems) + '</div></div>';
  }
}
