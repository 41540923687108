<span>
  <img
    src="../../../assets/img/question-icon.svg"
    class="icon"
    alt=""
    pTooltip="{{ htmlText }}"
    tooltipPosition="{{ tooltipPosition }}"
    hideDelay="{{ hideDelay }}"
    tooltipStyleClass="{{ class }}"
    [escape]="false"
  />
</span>
