import { Injectable } from '@angular/core';
import { Beneficiary, BeneficiariesClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class BeneficiariesService extends AbstractFormService<Beneficiary[]> {
  constructor(public webApi: BeneficiariesClient) {
    super(webApi);
  }
}
