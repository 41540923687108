import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { AdminPipesModule } from './pipes/admin-pipes.module';
import { TabChangesModalComponent } from './modals/tab-changes-modal/tab-changes-modal.component';

@NgModule({
  declarations: [
    TabChangesModalComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule,
    AdminPipesModule
  ],
  exports: [
    TabChangesModalComponent
  ]
})

export class SharedAdminModule { }
