import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SearchItemModel } from '../data-table-search/data-table-search.component';

@Component({
  selector: 'app-base-search-component',
  templateUrl: './base-search.component.html'
})
export class BaseSearchComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onUpdateFilters(data: SearchItemModel[]) {
    this.onChange.emit(data);
  }
}
