<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      [placeholder]="placeholderText"
      (ngModelChange)="change()"
      type="email"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
    />

    <!-- <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.required">
        {{ requiredText }}
      </span>
      <span *ngIf="control.errors?.minlength"> L’adresse e-mail semble incorrecte. </span>
      <span *ngIf="control.errors?.maxlength"> L’adresse e-mail semble incorrecte. </span>
      <span *ngIf="!control.errors?.minlength && !control.errors?.maxlength && control.errors?.emailValidator"> L’adresse e-mail semble incorrecte. </span>
      <span *ngIf="control.errors?.emailRemoteValidator"> L'adresse e-mail semble incorrecte. </span>
    </mat-error> -->
  </mat-form-field>
</div>
