import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Office, OfficesClient } from 'projects/difference/webapi/Difference.WebApi';

@Injectable({
  providedIn: 'root'
})
export class OfficesApiService {

  constructor(private webApi: OfficesClient) { }

  getOffices(): Observable<Office[]> {
    return this.webApi.get();
  }
}
