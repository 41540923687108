<div *ngIf="form" class="card">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
        <h5>Particularités fiscales</h5>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12" *ngIf="isShowControl(formFields.featuresTaxationForm.features, config)">
        <app-info-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.featuresTaxationForm.features, config)"
          [title]="'Si vous avez des spécificités à nous partager, indiquez-les ici'"
          [placeholder]="'Saisissez les spécificités fiscales de votre entreprise que vous souhaitez partager avec nous'"
        >
        </app-info-form-control>
      </div>
    </div>
  </div>
</div>
