import { Directive, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalizeAll]'
})
export class CapitalizeAllDirective implements OnInit, OnChanges {

  constructor(private ngControl: NgControl) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //this.toUpperCaseAll();
  }

  ngOnInit(): void {
    this.toUpperCaseAll();
  }

  @HostListener("ngModelChange", [ "$event"]) onNgModelChange(value: any) {
  }

  @HostListener('window:keyup', ['$event']) OnKeyup() {
    this.toUpperCaseAll();
  }

  @HostListener('blur', ['$event']) OnBlur2() {
    this.toUpperCaseAll();
  }

  toUpperCaseAll(): void {
    const value = this.ngControl.control.value;

    if (value) {
      const arr: string[] = value.split(' ');
      const res: string[] = [];

      arr.forEach((word) => {
        res.push(word.toUpperCase());
      });

      this.ngControl.control.patchValue(res.join(' '));
    }
  }

}
