import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteConfirmComponent } from '../../../modals/delete-confirm/delete-confirm.component';
import { AbstractForm } from '../../abstract-form';
import { BanksService } from './banks-form.service';
import { Bank, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { banksFormToViewModel, getBanksFormComponentControl } from './banks-form.extensions';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-banques-form',
  templateUrl: './banques-form.component.html',
  styleUrls: ['./banques-form.component.scss']
})
export class BanquesFormComponent extends AbstractForm<Bank[]> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public bankTitle: string = '';

  constructor(
    public bsModalService: BsModalService,
    public banksService: BanksService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.banksService.getConfig(); 
    this.formData = await this.banksService.get(this.profileGuid);
    this.formId = this.formData?.map((ben: Bank) => ben.volumeBankId);
  }

  hasCompanyOrNotProcessing() {
    this.bankTitle = this.customerDataService.hasCompanyValue ? 'Nom de la banque ' : 'Nom de la banque (si connu) ';
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      banks: new FormArray([])
    })
  }

  restoreAnswers(): void {
    if (this.formData?.length > 0) {
      const countOfForms = this.formData.length;
      let i = 0;

      while (i < countOfForms) {
        this.addNewBank(this.formData[i]);
        i++;
      }

      const viewModel = banksFormToViewModel(this.config, this.formData);
      this.banks.patchValue(viewModel);
    }
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  get banks(): FormArray {
    return this.form?.get('banks') as FormArray;
  }

  addNewBank(bank?: Bank): void {
    const newFormGroup = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getBanksFormComponentControl(configItem, this.mode, this.lookupDataService);

      if (controlToBeAdded) {
        newFormGroup.addControl(configItem.name, controlToBeAdded);
      }
    })

    this.banks.push(newFormGroup);
  }

  async deleteBank(index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(DeleteConfirmComponent, {
      class: 'delete-item-confirm-dialog',
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.banks.removeAt(index);
      }
    });
  }

}
