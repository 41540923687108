import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DayMonthFormControl } from './day-month-form-control';
import { FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { collections } from '../../../constants/collections';

@Component({
    selector: 'app-day-month-form-control',
    templateUrl: './day-month-form-control.component.html'
})
export class DayMonthFormControlComponent extends AbstractFormControlComponent<DayMonthFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() placeholder: string;
    @Input() inline: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    faCheck: any = faCheck;

    change(): void {
        this.onChange.emit(this.control.value);
    }

    constructor() {
        super();
    }

    requiredText: string = collections.messages.required;

    public get isInline(): boolean {
        return this.inline || false;
    }

    public get placeholderText(): string {
        return this.placeholder || '';
    }

}
