<div class="row" *ngIf="form">
  <div class="col-12 col-sm-6 xol-md-4 col-xl-4" *ngIf="isShowControl(formFields.companyAgreement.conventionTeam, config)">
    <app-name-with-digits-form-control
      [parentForm]="form"
      [controlName]="getControlName(formFields.companyAgreement.conventionTeam, config)"
      [title]="'Convention collective'"
      [placeholder]="'Saisissez la convention collective'"
    >
    </app-name-with-digits-form-control>
  </div>
  <div class="col-12 col-sm-6 xol-md-4 col-xl-3" *ngIf="isShowControl(formFields.companyAgreement.isCompanyAgreement, config)">
    <app-toogle-form-control [parentForm]="form" [controlName]="getControlName(formFields.companyAgreement.isCompanyAgreement, config)" [title]="'Accord d\'entreprise'"> </app-toogle-form-control>
  </div>
  <div class="col-12 col-sm-6 xol-md-4 col-xl-5" *ngIf="isShowControl(formFields.companyAgreement.details, config) && isCompanyAgreement">
    <app-name-with-digits-form-control [parentForm]="form" [controlName]="getControlName(formFields.companyAgreement.details, config)" [title]="'Précisez '" [placeholder]="'Saisissez l\'accord'">
    </app-name-with-digits-form-control>
  </div>
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
</div>
