<div class="main-page">
  <app-header></app-header>

  <app-menu></app-menu>

  <app-progress-bar></app-progress-bar>

  <div class="container main">
    <div class="row">
      <div class="col">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
