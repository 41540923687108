import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FeaturesTaxationService } from './features-taxation-form.service';
import { AbstractForm } from '../../abstract-form';
import { DataFieldConfig, FeaturesTaxation } from 'projects/difference/webapi/Difference.WebApi';
import { featuresTaxationFormToViewModel, getFeaturesTaxationFormComponentControl } from './features-taxation-form.extensions';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';

@Component({
  selector: 'app-features-of-taxation-form',
  templateUrl: './features-of-taxation-form.component.html',
  styleUrls: ['./features-of-taxation-form.component.scss']
})
export class FeaturesOfTaxationFormComponent extends AbstractForm<FeaturesTaxation> implements OnChanges {
  @Input() mode: FormMode;
  public form: FormGroup;

  constructor(
    public featuresTaxationService: FeaturesTaxationService,
    public lookupDataService: LookupDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.featuresTaxationService.getConfig(); 
    this.formData = await this.featuresTaxationService.get(this.profileGuid);
    this.formId = this.formData?.taxFeaturesTaxationId;
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getFeaturesTaxationFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  restoreAnswers(): void {
    const viewModel = featuresTaxationFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

}
