import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tag' })
export class GradePipe implements PipeTransform {
  constructor() { }

  transform(grade: number) {
    if (!grade) {
      return this.getEmptyGradeHtml();
    }

    let html = '<div class="rating-wrapper">'
    html += grade > 0 ? '<span class="icon star no-pointer-events star-filled"> </span>' : '<span class="icon star no-pointer-events"> </span>'
    html += grade > 1 ? '<span class="icon star no-pointer-events star-filled"> </span>' : '<span class="icon star no-pointer-events"> </span>'
    html += grade > 2 ? '<span class="icon star no-pointer-events star-filled"> </span>' : '<span class="icon star no-pointer-events"> </span>'
    html += '</div>';

    return html;
  }

  getEmptyGradeHtml(): string {
    return '<div class="rating-wrapper"><span class="icon star no-pointer-events"> <span class="icon star no-pointer-events"> <span class="icon star no-pointer-events"></span> </div>';
  }
}
