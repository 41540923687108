import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NameAutocompleteFormControl } from './name-autocomplete-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from '@angular/forms';
import { Observable, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import '../../../../shared/extentions/string.extensions';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPresent } from '../../../utils/isPresent';

@UntilDestroy()
@Component({
  selector: 'app-name-autocomplete-form-control',
  templateUrl: './name-autocomplete-form-control.component.html'
})
export class NameAutocompleteFormControlComponent extends AbstractFormControlComponent<NameAutocompleteFormControl> implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoComplete') optionInput!: ElementRef<HTMLInputElement>;

  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() panelWidth: string;
  @Input() autocompleteOptions: any[] = [];
  @Input() hiddenOptions: any[] = [];
  @Input() useDisplayWith: boolean;
  @Input() showAllAfterInit: boolean;
  @Input() inline: boolean;

  faCheck: any = faCheck;

  filteredOptions$: Observable<any[]>;
  focusOut$: Observable<any> | undefined;
  autocompleteControl: NameAutocompleteFormControl;

  ngOnInit(): void {
    this.autocompleteControl = this.parentForm.get(this.controlName) as NameAutocompleteFormControl;

    if (this.showAllAfterInit) {
      setTimeout(() => {
        this.autocompleteControl.patchValue('');
        setTimeout(() => {
          this.inputAutoComplete.openPanel();
          this.autocompleteControl.updateValueAndValidity();
        }, 1);
      });
    }
  }

  ngAfterViewInit(): void {
    this.initFilteredOptions();
    this.processFocusOut();
  }

  processFocusOut(): void {
    this.focusOut$ = fromEvent(this.optionInput.nativeElement, 'blur');
    this.focusOut$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((event: any) => {
      if (this.optionInput.nativeElement.value === '' && !this.allowCustomText) {
        this.control.markAsInvalid();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.autocompleteOptions?.currentValue && JSON.stringify(changes.autocompleteOptions.currentValue) !== JSON.stringify(changes.autocompleteOptions.previousValue)) {
      this.initFilteredOptions();
    }
  }

  initFilteredOptions(): any {
    this.parentForm
      .get(this.controlName)
      .valueChanges.pipe(untilDestroyed(this), distinctUntilChanged(), startWith(''))
      .subscribe(value => {
        if (this.autocompleteControl?.untouched && this.showAllAfterInit) {
          setTimeout(() => {
            this.autocompleteControl?.markAsTouched();
          });
        }
        this.filteredOptions$ = of(this._filterOptions(value + ''));
      });
  }

  inputChanged(event: any): void {
    // this.initFilteredOptions();
    if (!this.allowCustomText) {
      // this.parentForm.get(this.controlName).patchValue(null);
    } else {
      this.onSelected.emit(this.control.value);
    }
  }

  itemSelected(event: any): void {
    this.parentForm.get(this.controlName).patchValue(event.option.value);
    this.onSelected.emit(this.control.value);
  }

  public get isInline(): boolean {
    return this.inline || false;
  }

  public get placeholderText(): string {
    return this.placeholder || this.title;
  }

  public get maxLength(): number {
    return this.control.maxLength;
  }

  public get readOnlyCss(): boolean {
    return this.control.readOnlyCss || false;
  }

  public get autocompleteLabelField(): string {
    return this.control.autocompleteLabelField ?? 'name';
  }

  public get panelWidthValue(): string {
    return this.panelWidth || '';
  }

  public get autocompleteValueField(): string {
    return this.control.autocompleteValueField ?? 'id';
  }

  public get allowCustomText(): boolean {
    return this.control.allowCustomText ?? false;
  }

  public get escapeFirstDigits(): boolean {
    return this.control.escapeFirstDigits;
  }

  public get hideSuccessMark(): boolean {
    return this.control.hideSuccessMark || false;
  }

  public displayWith(id: string | number): string {
    return this.autocompleteOptions.concat(this.hiddenOptions ?? []).find(option => `${option?.[this.autocompleteValueField]}` === `${id}`)?.[this.autocompleteLabelField];
  }

  private _filterOptions(value: string | number): any[] {
    const collection = this.autocompleteOptions?.filter(item => item[this.autocompleteLabelField]?.toLowerCase().includes(`${value}`?.toLowerCase()));

    return collection?.sort((a, b) => a[this.autocompleteLabelField]?.localeCompare(b[this.autocompleteLabelField]));
  }
}
