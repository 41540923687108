import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { CheckboxFormControl } from './checkbox-form-control';

@Component({
    selector: 'app-checkbox-form-control',
    templateUrl: './checkbox-form-control.component.html'
})
export class CheckboxFormControlComponent extends AbstractFormControlComponent<CheckboxFormControl> {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() inline: boolean;

    change(): void {
        this.onChange.emit(this.control.value);
    }

    public get isInline(): boolean {
        return this.inline || false;
    }
}
