import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { autocompleteValidator } from '../../../validators/autocompleteValidator';

export class AutocompleteFormControl extends AbstractFormControl {
    validators: any;
    constructor(required: boolean = false, disabled: boolean = false, private options?: AutocompleteOptions) {
        super(required, disabled);

        if (options?.defaultValue) {
            this.setValue(options.defaultValue);
        } else {
            this.setValue(null);
        }

        if (options?.collection) {
            this.setCollection(options.collection);
        }

        this.updateValidators(required);
    }

    setCollection(collection: any[]) {
        this.options.collection = collection;
    }

    public get collection(): any[] {
        return this.options?.collection;
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    public get autocompleteIdField(): string {
        return this.options?.keyPropertyName;
    }

    public get autocompleteValueField(): string {
        return this.options?.valuePropertyName;
    }

    private updateValidators(required: boolean): void {
        const validators = [];

        if (required) {
            validators.push(Validators.required);
        }

        validators.push(autocompleteValidator());

        this.validators = validators;
        super.setValidators(validators);
    }

    protected getValidators(): ValidatorFn[] {
        return this.validators;
    }
}

export interface AutocompleteOptions {
    collection: any[],
    defaultValue?: any;
    readOnlyCss?: boolean;
    keyPropertyName?: string;
    valuePropertyName?: string;
}
