declare global {
  interface String {
    myFormatString(replacements: any[]): string;
    allToUpperCase(): string;
    truncate(size: number): string;
    isNumeric(): boolean;
  }
}

String.prototype.isNumeric = function (): boolean {
  if (typeof this.valueOf() != "string") return false;
  return /^-?\d+$/.test(this.valueOf());
};

String.prototype.myFormatString = function (replacements: any[]): string {
  return this.replace(/{(\d+)}/g, (match, index) => {
    return typeof replacements[index] !== 'undefined' ? replacements[index] : match;
  });
};

String.prototype.allToUpperCase = function (): string {
  if (this) {
    const arr: string[] = this.split(' ');
    const res: string[] = [];

    arr.forEach((word) => {
      res.push(word.toUpperCase());
    });

    return res.join(' ');
  } else {
    return '';
  }
};

String.prototype.truncate = function (size: number): string {
  if (this) {
    if (this.length > size) {
      return this.substring(0, size) + '...';
    } else {
      return this.toString();
    }
  } else {
    return '';
  }
};

export { };
