import { Injectable } from '@angular/core';
import { MainTaxes, MainTaxesClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class MainTaxesService extends AbstractFormService<MainTaxes> {
  constructor(public webApi: MainTaxesClient) {
    super(webApi);
  }
}
