<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appCapitalizeAll
      (change)="change()"
      mask="000 000 000"
      placeholder="Saisissez le numéro SIREN de l'entité"
      [validation]="false"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
    />
    
  </mat-form-field>
</div>
