import { AccountingFirmsService } from './../../services/accounting-firms.service';
import { Component, OnInit } from '@angular/core';
import { AccountingFirm } from 'projects/difference/webapi/Difference.WebApi';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  accountingFirm: AccountingFirm;
  logoPath: any;

  constructor(
    private accountingFirmsService: AccountingFirmsService,
    private sanitizer: DomSanitizer
  ) { }

  async ngOnInit(): Promise<void> {
    this.accountingFirm = await this.accountingFirmsService.get();
    this.logoPath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.accountingFirm.logo);
  }

  onLogoCLick(): void {
  }

}
