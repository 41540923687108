import { Injectable } from '@angular/core';
import { FilesClient, FileParameter, FileResponse } from 'projects/difference/webapi/Difference.WebApi';
import { map } from 'rxjs/internal/operators/map';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class FilesService extends AbstractFormService<any[]> {
  constructor(public webApi: FilesClient) {
    super(webApi);
  }

  public async saveFile(type: number, file: File, profileGuid: string): Promise<File> {
    const formFile = {
      data: file,
      fileName: file.name
    } as FileParameter;

    return this.webApi.save(type, formFile, profileGuid).pipe(
      map((response: any) => {
        return response;
      })
    ).toPromise();
  }

  public async delete(fileGuid?: string): Promise<boolean> {
    return this.webApi.delete(fileGuid).toPromise();
  }

  public async downloadFileBase64(fileGuid?: string): Promise<any> {
    return this.webApi.downloadFileBase64(fileGuid).toPromise();
  }
}
