<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>

    <input matInput [placeholder]="placeholderText" [(ngModel)]="selectedCompany" [matAutocomplete]="auto" [formControlName]="controlName" />
    <button *ngIf="selectedCompany && !isDisabled" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
      <fa-icon [icon]="faClose"></fa-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onItemSelected()" [displayWith]="displayWith">
      <mat-option *ngIf="isLoading" class="is-loading">Chargement des données...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let company of filteredCompanies" [value]="company">
          <span>
            <span class="company"
              ><b>{{ company.nom_entreprise }}</b> ({{ company.siege.code_postal }})</span
            >
            <span class="activite">
              {{ company.libelle_code_naf }}
            </span>
          </span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
