import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';
import { constants } from '../shared/constants/constants';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private spinnerService: SpinnerService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.spinnerService.isLoading.next(this.requests.length > 0);
    }

    isUrlForNonBlockingSpinner(requestUrl: string): boolean {
        const index = constants.urlKeysForNonBlockingSpinner.findIndex((urlKey: string) => {
            return requestUrl.indexOf(urlKey) !== -1;
        });

        return index !== -1;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let delayExpired = false;

        const timeout = setTimeout(() => {
            delayExpired = true;
            if (this.isUrlForNonBlockingSpinner(request.url)) {
                this.spinnerService.isLoadingNonBlocking.next(true);
            } else {
                this.spinnerService.isLoading.next(true);
            }
        }, 200);

        return next.handle(request).pipe(
            catchError((err, caught) => {
                clearTimeout(timeout);
                this.spinnerService.isLoading.next(false);
                this.spinnerService.isLoadingNonBlocking.next(false);
                return throwError(err);
            }),
            finalize(() => {
                clearTimeout(timeout);
                this.spinnerService.isLoading.next(false);
                this.spinnerService.isLoadingNonBlocking.next(false);
            }),
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    clearTimeout(timeout);
                    if (delayExpired) {
                        this.spinnerService.isLoading.next(false);
                        this.spinnerService.isLoadingNonBlocking.next(false);
                    }
                }
            })
        );
    }
}
