<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="row" *ngIf="isShowControl(formFields.social.hasDigitalSafeTool, config)">
    <div class="col-12">
      <app-radio-group-form-control [parentForm]="form" [controlName]="getControlName(formFields.social.hasDigitalSafeTool, config)" [title]="hasDigitalSafeToolTitle" [inline]="true">
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isDigitalSafeToolsTrue && isShowControl(formFields.social.digitalSafeTool, config)">
    <div class="col-12">
      <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.social.digitalSafeTool, config)" [title]="toolsTitle" [inline]="true">
      </app-checkbox-list-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <app-name-with-digits-form-control
        *ngIf="isOtherDigitalSafeTool"
        [parentForm]="form"
        [controlName]="'otherDigitalSafeTool'"
        [title]="''"
        [placeholder]="'Saisissez le nom de l\'outil'"
        class="no-title like-no-title-in-mobile"
      >
      </app-name-with-digits-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isShowControl(formFields.social.isIntrestedInDigitalSafeTool, config) && !this.hasDigitalSafeTools">
    <div class="col-12">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.social.isIntrestedInDigitalSafeTool, config)"
        [title]="'Êtes-vous intéressé par un outil de ce type ?'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isShowControl(formFields.social.wageManagementTool, config)">
    <div class="col-12">
      <app-checkbox-list-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.social.wageManagementTool, config)"
        [title]="'Outil(s) de gestion de paie utilisé(s)'"
        [inline]="true"
      >
      </app-checkbox-list-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <app-name-with-digits-form-control
        *ngIf="isOtherWageManagementTool"
        [parentForm]="form"
        [controlName]="'otherWageManagementTool'"
        [title]="''"
        [placeholder]="'Saisissez le nom de l\'outil'"
        class="no-title like-no-title-in-mobile"
      >
      </app-name-with-digits-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isShowControl(formFields.social.hasPersonnelInformationSystemTool, config)">
    <div class="col-12">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.social.hasPersonnelInformationSystemTool, config)"
        [title]="hasPersonnelInformationSystemToolTitle"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isPersonnelInformationSystemTrue && isShowControl(formFields.social.personnelInformationSystemTool, config)">
    <div class="col-12">
      <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.social.personnelInformationSystemTool, config)" [title]="toolsTitle" [inline]="true">
      </app-checkbox-list-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <app-name-with-digits-form-control
        *ngIf="isOtherPersonnelInformationSystemTool"
        [parentForm]="form"
        [controlName]="'otherPersonnelInformationSystemTool'"
        [title]="''"
        [placeholder]="'Saisissez le nom de l\'outil'"
        class="no-title like-no-title-in-mobile"
      >
      </app-name-with-digits-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isShowControl(formFields.social.isIntrestedInPersonnelInformationSystemTool, config) && !hasPersonnelInformationSystemTools">
    <div class="col-12">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.social.isIntrestedInPersonnelInformationSystemTool, config)"
        [title]="'Êtes-vous intéressé par un outil de ce type ?'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>
</div>
