<div class="form-group datepicker" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline">
  <span class="inline-form-control-title" *ngIf="isInline">{{ title }} <span class="mat-form-field-required-marker" *ngIf="isRequired">*</span> </span>
  <mat-form-field [floatLabel]="'always'" appearance="fill">
    <mat-label *ngIf="!isInline">{{ title }} </mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [disabled]="control.disabled"
      placeholder="JJ/MM/AAAA"
      class="form-control form-control-sm mat-input-element mat-form-field-autofill-control"
      (dateChange)="changeDate($event)"
      [min]="minDate"
      [max]="maxDate"
      [formControlName]="controlName"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker (opened)="open()"></mat-datepicker>

    <!-- <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span> -->

    <!-- <mat-error *ngIf="!control.hideErrorMessages">
      <span *ngIf="control.errors?.required">
        {{ control.requiredErrorMessage }}
      </span>
      <span *ngIf="control.errors?.matDatepickerMin">{{ control.minDateErrorMessage }}</span>
      <span *ngIf="control.errors?.matDatepickerMax">{{ control.maxDateErrorMessage }}</span>
    </mat-error> -->
  </mat-form-field>
</div>
