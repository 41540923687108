import { Injectable } from '@angular/core';
import { Contact, ContactClient } from '../../../../../../webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class ContactService extends AbstractFormService<Contact[]> {
  constructor(public webApi: ContactClient) {
    super(webApi);
  }
}
