import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractFormControlComponent } from '@controls/abstract-form-control.component';
import { AutocompleteFormControl } from './autocomplete-form-control';
import { FormGroup } from '@angular/forms';
import { constants } from '../../../constants/constants';

@Component({
  selector: 'app-autocomplete-form-control',
  templateUrl: './autocomplete-form-control.component.html'
})
export class AutocompleteFormControlComponent extends AbstractFormControlComponent<AutocompleteFormControl> implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() panelWidth: string;
  @Input() hiddenOptions: any[] = [];
  @Input() showAllAfterInit: boolean;
  @Input() inline: boolean;

  autocompleteControl: AutocompleteFormControl;
  filteredOptions: any[];

  ngOnInit() {
    this.filteredOptions = this.autocompleteOptions.slice();
    this.autocompleteControl = this.parentForm.get(this.controlName) as AutocompleteFormControl;
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();

    this.filteredOptions = this.autocompleteOptions.filter((option: any) => {
      const item = option[this.autocompleteValueField];
      return item?.toLowerCase().includes(filterValue);
    });

    if (this.filteredOptions.length === 1 && filterValue.toLowerCase() === this.filteredOptions[0][this.autocompleteValueField].toLowerCase()) {
      this.autocompleteControl.patchValue(this.filteredOptions[0][this.autocompleteIdField]);
      this.onSelected.next(this.filteredOptions[0]);
    }
  }

  public displayWith(id: string | number): string {
    if (!id) {
      return null;
    }

    return this.autocompleteOptions.find(option => `${option?.[this.autocompleteIdField]}` === `${id}`)?.[this.autocompleteValueField];
  }

  public get autocompleteOptions(): any[] {
    return this.control.collection || [];
  }

  public get isInline(): boolean {
    return this.inline || false;
  }

  public get placeholderText(): string {
    return this.placeholder || this.title;
  }

  public get panelWidthValue(): string {
    return this.panelWidth || '';
  }

  public get readOnlyCss(): boolean {
    return this.control.readOnlyCss || false;
  }

  public get autocompleteIdField(): string {
    return this.control.autocompleteIdField ?? constants.keyPropertyName;
  }

  public get autocompleteValueField(): string {
    return this.control.autocompleteValueField ?? constants.namePropertyName;
  }
}
