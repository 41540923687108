<div *ngIf="form">
  <div class="row">
    <div class="col-12 col-sm-12">
      <div *ngFor="let stakeholderForm of stakeholders.controls; index as i" class="row">
        <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
        <ng-container [formGroup]="stakeholderForm">
          <div class="col-12 justify-content-center title-with-del" [class.mt-0]="i === 0">
            <h5>{{ getStakeholderTitle(stakeholderForm, i) }}</h5>
            <img *ngIf="isEditMode" src="../../../../../assets/img/delete-icon.svg" class="icon-in-label red" alt="" (click)="deleteStakeholder(i)" />
          </div>
          <div class="col-12 col-md-6 col-lg-3" *ngIf="isShowControl(formFields.stakeholdersForm.lastName, config)">
            <app-name-form-control [parentForm]="stakeholderForm" [controlName]="getControlName(formFields.stakeholdersForm.lastName, config)" [title]="'Nom'" [placeholder]="'Saisissez un nom'">
            </app-name-form-control>
          </div>
          <div class="col-12 col-md-6 col-lg-3" *ngIf="isShowControl(formFields.stakeholdersForm.firstName, config)">
            <app-name-form-control [parentForm]="stakeholderForm" [controlName]="getControlName(formFields.stakeholdersForm.firstName, config)" [title]="'Prénom'" [placeholder]="'Saisissez un prénom'">
            </app-name-form-control>
          </div>
          <div class="col-12 col-md-6 col-lg-3" *ngIf="isShowControl(formFields.stakeholdersForm.email, config)">
            <app-email-form-control [parentForm]="stakeholderForm" [controlName]="getControlName(formFields.stakeholdersForm.email, config)" [title]="'Email'" [placeholder]="'Saisissez l\'email'">
            </app-email-form-control>
          </div>
          <div class="col-12 col-md-6 col-lg-3" *ngIf="isShowControl(formFields.stakeholdersForm.phone, config)">
            <app-phone-form-control [parentForm]="stakeholderForm" [controlName]="getControlName(formFields.stakeholdersForm.phone, config)" [title]="'Téléphone'" class="phone">
            </app-phone-form-control>
          </div>

          <div class="col-12 col-md-6 col-lg-6" *ngIf="isShowControl(formFields.stakeholdersForm.nature, config) && isShowNature(stakeholderForm)">
            <app-name-with-digits-form-control [parentForm]="stakeholderForm" [controlName]="getControlName(formFields.stakeholdersForm.nature, config)" [title]="'Nature'" [placeholder]="'Nature'">
            </app-name-with-digits-form-control>
          </div>
          <div class="col-12 col-md-6 col-lg-6" [class.col-lg-12]="!isShowNature(stakeholderForm)" *ngIf="isShowControl(formFields.stakeholdersForm.address, config)">
            <app-name-with-digits-form-control
              [parentForm]="stakeholderForm"
              [controlName]="getControlName(formFields.stakeholdersForm.address, config)"
              [title]="'Adresse'"
              [placeholder]="getStakeholderAddressPlaceholder(stakeholderForm, i)"
            >
            </app-name-with-digits-form-control>
          </div>
        </ng-container>
      </div>

      <div class="row" *ngIf="isEditMode">
        <div class="col-12 text-end">
          <button class="btn pink w100mobile one-in-col" (click)="addNewStakeholder()">
            <img src="../../../../../assets/img/plus-icon.svg" alt="" />
            Ajouter un intervenant
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
