import { Injectable } from '@angular/core';
import { Bank, BanksClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class BanksService extends AbstractFormService<Bank[]> {
  constructor(public webApi: BanksClient) {
    super(webApi);
  }
}
