import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html'
})
export class AlertModalComponent implements OnInit {
  public body: string;
  public title: string;
  public cssClass: string = '';
  // public type: number = 1;
  public onClose: Subject<boolean>;

  public faClose: any = faTimes;

  public constructor(private bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    // switch (this.type) {
    //     case modalTypes.error: {
    //         this.cssClass = 'error-modal'
    //         break;
    //     }
    //     case modalTypes.warning: {
    //         this.cssClass = 'warning-modal'
    //         break;
    //     }
    //     case modalTypes.info: {
    //         this.cssClass = 'info-modal'
    //         break;
    //     }
    // }
  }

//   get isErrorType(): boolean {
//     return this.type === modalTypes.error;
//   }

  public show(body: string): void {
    this.body = body;
  }

  public hideConfirmationModal(moveNextStep?: boolean): void {
    this.onClose.next(moveNextStep);
    this.onClose.complete();
    this.bsModalRef.hide();
  }
}

export enum modalTypes {
  error = 1,
  warning = 2,
  info = 3
}
