<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      type="text"
      [placeholder]="placeholderText"
      [validation]="false"
      mask="00000"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control form-control-sm"
      [formControlName]="controlName"
    />

    <!-- <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span> -->
  </mat-form-field>
</div>
