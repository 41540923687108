<div
  class="form-group selection-list"
  [class.required]="isRequired"
  [class.disabled]="isDisabled"
  [class.inline]="isInline"
  [class.compact]="isCompact"
  [class.withTooltip]="isWithTooltip"
  [formGroup]="parentForm"
>
  <mat-label *ngIf="title" class="pr-2">{{ title }} <span class="mat-form-field-required-marker" *ngIf="isRequired">*</span> </mat-label>

  <br *ngIf="!isInline" />

  <mat-selection-list #list [formControlName]="controlName" (ngModelChange)="change()">
    <mat-list-option *ngFor="let item of collection; let i = index" value="{{ item[keyPropertyName] || item }}">
      <span class="value-text">
        {{ item[valuePropertyName] || item }}
      </span>
      <app-tooltip *ngIf="isWithTooltip" title="{{ item[valuePropertyName] }}" text="{{ item[tooltiPropertyName] }}"> </app-tooltip>
    </mat-list-option>
  </mat-selection-list>
</div>
