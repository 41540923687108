import { FilterRequestItem, Order } from './../../../../../webapi/Difference.WebApi';
import { adminConstants } from '../../../../../../difference-admin/app/shared/constants/constants';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { dataTableEvents } from '../../../../../../difference-admin/app/shared/constants/data-table-events';
import { processCommonMenuItems } from 'projects/difference-admin/app/shared/services/table-menu-process.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Criterion, SearchItemModel } from '../data-table-search/data-table-search.component';
import { SubscriptionHandler } from '../../subscriptionHandler';
import { constants } from '../../../constants/constants';
import { Pagination } from 'projects/difference-admin/app/shared/services/base-event-service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html'
})
export class DataTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() id: string;
  @Input() columns: DataTables.ColumnSettings[];
  @Input() sort: any;
  @Input() eventService: any;
  @Input() webApi: any;
  @Input() dataRequestMethodName: string = 'getPaginated';
  @Input() refreshIt: BehaviorSubject<boolean>;
  @Output() onDataLoaded: EventEmitter<any> = new EventEmitter();
  @ViewChild(DataTableDirective, { static: false })

  public dtOptions: DataTables.Settings;

  public dtTrigger = new Subject();
  public data: any[];
  public selectedIds: string[] = [];

  listenerFn: () => void;
  filterItems: SearchItemModel[];
  subscriptionHandler: SubscriptionHandler;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.subscriptionHandler = new SubscriptionHandler();
    this.initSubscriptions();

    this.dtOptions = {
      ...this.getDTOptions(),
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback)
      }
    }

    this.refreshIt?.subscribe((refresh: boolean) => {
      if (refresh) {
        const datatable = $(`#${this.id}`).DataTable();
        datatable.destroy();
        this.rerender();
      }
    })
  }

  getSort(): (string | number)[][] {
    if (this.sort?.length > 0) {
      return this.sort;
    }

    const orders: (string | number)[][] = [];

    const preSavedOrders = this.eventService?.getOrder();

    preSavedOrders?.forEach((preSavedOrder: Order) => {
      orders.push([preSavedOrder.column, preSavedOrder.dir]);
    });

    return orders;
  }

  resetSelected(): void {
    $('input#selectItem').prop('checked', false);
    $('input#selectAll').prop('checked', false);
    this.selectedIds = [];
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.eventService.getFilterConditionsSubject().subscribe((filterItems: SearchItemModel[]) => {
      this.filterItems = filterItems.map((item: SearchItemModel) => {
        item.searchValue = item.searchValue?.toString();
        return item;
      });
      this.refreshIt.next(true);
    })
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }

    this.dtTrigger.unsubscribe();
    this.refreshIt?.unsubscribe();
    this.subscriptionHandler.unsubscribeAll();
    this.eventService.dataWasLoaded = false;
  }

  rerender(): void {
    $(`#${this.id}`).DataTable({
      destroy: true,
      ...this.getDTOptions(),
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback)
      }
    });
  }

  getDTOptions(): any {
    return {
      pagingType: 'full_numbers',
      pageLength: this.getPageLength(),
      order: this.getSort(),
      displayStart: this.getPageNumber(),
      serverSide: true,
      processing: true,
      stateSave: false,
      searching: false,
      columns: this.columns,
      language: adminConstants.dataTableLanguageSettings
    }
  }

  ajaxFunction(dataTablesParameters: any, callback: any): any {
    dataTablesParameters = this.extendDataTablesParametersByFilters(dataTablesParameters);
    this.checkAndSaveOrder(dataTablesParameters);
    dataTablesParameters = this.checkAndSavePagination(dataTablesParameters);

    const request = this.webApi[this.dataRequestMethodName](dataTablesParameters);

    request.subscribe((resp: any) => {
      this.onDataRecieved(resp);
      callback({
        recordsTotal: resp.recordsTotal,
        recordsFiltered: resp.recordsTotal,
        data: resp.data
      });
    })
  }

  extendDataTablesParametersByFilters(params: any): any {
    return Object.assign(params, { filters: this.getFilters() });
  }

  getFilters(): any {
    const filters: any[] = [];

    if (this.filterItems?.length > 0 && this.eventService?.getFilterCriterions()) {
      this.filterItems.forEach((filterItem: SearchItemModel) => {
        this.eventService?.getFilterCriterions().forEach((filterCriterion: Criterion) => {
          if (filterItem.criterion === filterCriterion.id) {
            filters.push({
              propertyNames: filterCriterion.data,
              propertyValue: filterItem.searchValue,
              operator: filterItem.operator,
              entity: filterCriterion.entity
            } as FilterRequestItem);
          }
        });
      })
    }

    return filters;
  }

  checkAndSaveOrder(params: any): void {
    if (params.order?.length > 0) {
      this.eventService?.saveOrder(params.order);
    }
  }

  getPageLength(): number {
    const preSavedPagination: Pagination = this.eventService?.getPagination();

    if (preSavedPagination?.length) {
      return preSavedPagination.length;
    }

    return constants.defaultDataTablePageLength;
  }

  getPageNumber(): number {
    const preSavedPagination: Pagination = this.eventService?.getPagination();

    if (preSavedPagination?.start) {
      return preSavedPagination.start;
    }

    return 0;
  }

  checkAndSavePagination(params: any): any {
    const pagination = {
      start: params.start,
      length: params.length
    };

    this.eventService?.savePagination(pagination);

    return params;
  }

  ngAfterViewInit(): void {
    this.listenerFn = this.renderer.listen('document', 'click', (event) => {
      this.processThreeDotsMenu(event);
      this.processSelectors(event);

      processCommonMenuItems(this.eventService, this.router, event);

      event.stopPropagation();
    });
    this.dtTrigger.next();
  }

  processThreeDotsMenu(event: any) {
    const menus = document.getElementsByClassName('dropdown-content');

    for (let i = 0; i < menus.length; i++) {
      menus[i].classList.remove('show')
    }

    if (event.target.id === 'threeDots') {
      if (event.target.nextSibling.nextSibling.classList.contains('show')) {
        event.target.nextSibling.nextSibling.classList.remove('show');
      } else {
        event.target.nextSibling.nextSibling.classList.toggle('show');
      }
    }
  }

  processSelectors(event: any) {
    if (event.target.id === 'selectAll') {
      $('input#selectItem').prop('checked', event.target.checked);
      this.selectedIds = [];
      if (event.target.checked) {
        this.selectedIds = $('input#selectItem').map(function () {
          return this.getAttribute(dataTableEvents.common.checkedId);
        }).get();
      }
    }

    if (event.target.id === 'selectItem') {
      const itemId = event.target.getAttribute(dataTableEvents.common.checkedId);

      if (event.target.checked) {
        this.selectedIds.push(itemId);
      } else {
        this.selectedIds = this.selectedIds.filter((id: string) => {
          return id !== itemId;
        })
      }
    }

    this.eventService.onSelected(this.selectedIds);
  }

  onDataRecieved(resp: any): void {
    this.data = resp.data;
    this.onDataLoaded.emit(resp);
    this.eventService.dataWasLoaded = true;
    this.clearSelectedItems(); // todo: we can implement save and restore checkboxes between data updates depends on the feature requirements (sort, filter, paging)
  }

  clearSelectedItems(): void {
    this.selectedIds = [];
    $('input#selectAll').prop('checked', false);
  }

}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsTotal: number;
}
