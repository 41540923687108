import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteConfirmComponent } from '../../../modals/delete-confirm/delete-confirm.component';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { AbstractForm } from '../../abstract-form';
import { StakeholdersService } from './stakeholders.service';
import { DataFieldConfig, Stakeholder } from 'projects/difference/webapi/Difference.WebApi';
import { getStakeholdersFormComponentControl, stakeholdersToViewModel } from './stakeholders-form.extensions';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { ActivatedRoute } from '@angular/router';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-info-other-form',
  templateUrl: './info-other-form.component.html'
})
export class InfoOtherFormComponent extends AbstractForm<Stakeholder[]> implements OnChanges {
  @Input() mode: FormMode;

  public userId: string;

  public form: FormGroup;
  public isAuditor: boolean = true;
  public isLawyer: boolean = true;

  public auditorTypeId: number = this.lookupDataService.lookupDataObj[LookupTypeId.StakeholderType].auditorTypeId;
  public lawyerTypeId: number = this.lookupDataService.lookupDataObj[LookupTypeId.StakeholderType].lawyerTypeId;
  public stakeholderTypeId = this.lookupDataService.lookupDataObj[LookupTypeId.StakeholderType].stakeholderTypeId;

  public typeConfig = new DataFieldConfig({
    name: formFields.stakeholdersForm.stakeholderTypeId,
    propertyName: formFields.stakeholdersForm.stakeholderTypeId,
    isReadOnly: true,
    isRequired: false
  })

  constructor(
    public route: ActivatedRoute,
    public bsModalService: BsModalService,
    public stakeholdersService: StakeholdersService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      // this.initControls();
      // this.initSubscriptions();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.stakeholdersService.getConfig();
    this.config.push(this.typeConfig);
    this.formData = await this.stakeholdersService.get(this.profileGuid);
    this.formId = this.formData?.map((s: Stakeholder) => s.stakeholderId);
  }

  restoreAnswers(): void {
    if (this.isNew) {
      const newAuditorFormGroup = new FormGroup({});
      const newLawyerFormGroup = new FormGroup({});

      this.config.forEach((configItem: DataFieldConfig) => {
        const controlToBeAdded = getStakeholdersFormComponentControl(configItem, this.mode);

        if (controlToBeAdded) {
          newAuditorFormGroup.addControl(configItem.name, controlToBeAdded);
        }
      })

      this.config.forEach((configItem: DataFieldConfig) => {
        const controlToBeAdded = getStakeholdersFormComponentControl(configItem, this.mode);

        if (controlToBeAdded) {
          newLawyerFormGroup.addControl(configItem.name, controlToBeAdded);
        }
      })

      newAuditorFormGroup.get(formFields.stakeholdersForm.stakeholderTypeId).patchValue(this.auditorTypeId);
      newLawyerFormGroup.get(formFields.stakeholdersForm.stakeholderTypeId).patchValue(this.lawyerTypeId);

      this.stakeholders.push(newAuditorFormGroup);
      this.stakeholders.push(newLawyerFormGroup);
    } else {
      if (this.formData?.length > 0) {
        const countOfForms = this.formData.length;
        let i = 0;

        while (i < countOfForms) {
          this.addNewStakeholder(this.formData[i]);
          i++;
        }

        const viewModel = stakeholdersToViewModel(this.config, this.formData, this.lookupDataService);
        this.stakeholders.patchValue(viewModel);
      }
    }
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  getStakeholderTitle(form: FormGroup, index: number): string {
    const type = form?.get(formFields.stakeholdersForm.stakeholderTypeId).value;

    if (type === this.auditorTypeId) {
      return 'Commissaire aux comptes';
    } else if (type === this.lawyerTypeId) {
      return 'Avocat';
    } else return `Intervenant #${index + 1}`;
  }

  getStakeholderAddressPlaceholder(form: FormGroup, index: number): string {
    const type = form?.get(formFields.stakeholdersForm.stakeholderTypeId).value;

    if (type === this.auditorTypeId) {
      return 'Saisissez l’adresse du commissaire aux comptes';
    } else if (type === this.lawyerTypeId) {
      return 'Saisissez l’adresse du cabinet d’avocat(s)';
    } else return 'Saisissez l’adresse';
  }

  isShowNature(form: FormGroup): boolean {
    const type = form?.get(formFields.stakeholdersForm.stakeholderTypeId).value;

    return type !== this.auditorTypeId && type !== this.lawyerTypeId;
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      stakeholders: new FormArray([])
    })
  }

  get isNew(): boolean {
    return !this.formData || this.formData.length === 0;
  }

  get stakeholders(): FormArray {
    return this.form?.get('stakeholders') as FormArray;
  }

  deleteAuditor(): void {
    this.isAuditor = false;
  }

  deleteLawyer(): void {
    this.isLawyer = false;
  }

  addNewStakeholder(stakeholder?: any): void {
    const newFormGroup = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getStakeholdersFormComponentControl(configItem, this.mode);

      if (controlToBeAdded) {
        newFormGroup.addControl(configItem.name, controlToBeAdded);
      }
    })

    if (stakeholder === undefined) {
      newFormGroup.get(formFields.stakeholdersForm.stakeholderTypeId).patchValue(this.stakeholderTypeId);
    }

    this.form.markAsDirty();
    this.stakeholders.push(newFormGroup);
  }

  async deleteStakeholder(index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(DeleteConfirmComponent, {
      class: 'delete-item-confirm-dialog',
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.form.markAsDirty();
        this.stakeholders.removeAt(index);
      }
    });
  }

  get isEditMode(): boolean {
    return this.mode === FormMode.Edit;
  }
}
