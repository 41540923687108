import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { NotificationsActions } from './actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';

@Injectable()
export class NotificationFeatureEffects {
  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsActions.shownotification),
        tap(({ config, message, action }) => {
          this.snackBar.open(message, action, {
            duration: 8000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom'
          });
        })
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly snackBar: MatSnackBar) {}
}
