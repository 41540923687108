import { Injectable } from '@angular/core';
import moment from 'moment';
import { JeSignExpertToken } from 'projects/difference/webapi/Difference.WebApi';

@Injectable({
    providedIn: 'root'
})

export class JeSignTokensDataService {
    private tokens: JeSignExpertToken;

    constructor() { }

    async initliaze(): Promise<void> {
        const data = localStorage.getItem(JE_SIGN_TOKENS_STORAGE_KEY);

        if (data !== 'undefined') {
            this.tokens = JSON.parse(data) as JeSignExpertToken;
        }
    }

    clearTokens(): void {
        localStorage.setItem(JE_SIGN_TOKENS_STORAGE_KEY, null);
    }

    getTokens(): JeSignExpertToken {
        this.tokens = JSON.parse(localStorage.getItem(JE_SIGN_TOKENS_STORAGE_KEY)) as JeSignExpertToken;
        return this.tokens;
    }

    isTokensValid(): boolean {
        if (!this.tokens) {
            return false;
        }

        const expDate = moment(new Date(this.getTokens().user.expirationDate));
        const currentDate = moment();
        const isValid = expDate > currentDate;

        return isValid;
    }

    setTokens(data: JeSignExpertToken) {
        if (data !== undefined && data !== null) {
            localStorage.setItem(JE_SIGN_TOKENS_STORAGE_KEY, JSON.stringify(data));
        }
    }
}

export const JE_SIGN_TOKENS_STORAGE_KEY = 'je-sign-tokens';
