import { Injectable } from '@angular/core';
import { DebDes, DebDesClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class DebDesService extends AbstractFormService<DebDes> {
  constructor(public webApi: DebDesClient) {
    super(webApi);
  }
}
