import { Router } from '@angular/router';
import { constants } from './../constants/constants';
import saveAs from 'file-saver';

function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

async function openFileInNewTab(fileName: string, type: string, fileBase64: string) {
  const arr = type.split('/');
  let ext, preExt;
  const onlySaveExtArray = constants.onlySaveExtArray;

  if (arr && arr.length > 1) {
    ext = arr[arr.length - 1];
    preExt = constants.imageExtArray.indexOf(ext) !== -1 ? 'image' : 'application';
  }

  const base64 = `data:${preExt}/${ext};base64,${fileBase64}`;
  const blob = await (await fetch(base64)).blob();
  const fileURL = URL.createObjectURL(blob);

  if (onlySaveExtArray.indexOf(ext) === -1) {
    window.open(fileURL, '_blank');
  } else {
    const blob = new Blob([fileBase64]);
    saveAs(blob, fileName);
  }
}

async function saveFile(fileName: string, type: string, fileBase64: string) {
  const arr = type.split('/');
  let ext, preExt;

  if (arr && arr.length > 1) {
    ext = arr[arr.length - 1];
    preExt = constants.imageExtArray.indexOf(ext) !== -1 ? 'image' : 'application';
  }

  const base64 = `data:${preExt}/${ext};base64,${fileBase64}`;
  const blob = await (await fetch(base64)).blob();
  saveAs(blob, fileName);
}

function openInNewTab(router: Router, namedRoute: string) {
  const newRelativeUrl = router.createUrlTree([namedRoute]);
  const baseUrl = window.location.href.replace(router.url, '');

  window.open(baseUrl + newRelativeUrl, '_blank');
}

function mergePerIndex(arr: any[]): any[] {
  const result = arr.reduce(function (a, b) {
    return a.map(function (v: any, i: number) {
      return v + b[i];
    });
  });

  return result;
}

export {
  formatBytes,
  openFileInNewTab,
  saveFile,
  openInNewTab,
  mergePerIndex
};
