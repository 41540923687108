<div *ngIf="form" class="card">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
        <h5>Déclaration d'échanges de biens (DEB) / Déclaration européenne de services (DES)</h5>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12" *ngIf="isShowControl(formFields.debDesForm.isDealOutsideEurope, config)">
        <app-radio-group-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.debDesForm.isDealOutsideEurope, config)"
          [title]="'Avez-vous des relations achats/ventes avec des entités européennes ?'"
          [inline]="true"
        >
        </app-radio-group-form-control>
      </div>
      <div class="col-12" *ngIf="isDealOutsideEurope && isShowControl(formFields.debDesForm.isDeb, config)">
        <app-radio-group-form-control [parentForm]="form" [controlName]="getControlName(formFields.debDesForm.isDeb, config)" [title]="'Êtes-vous soumis au DEB ?'" [inline]="true">
        </app-radio-group-form-control>
      </div>
      <div class="col-12" *ngIf="isDealOutsideEurope && isShowControl(formFields.debDesForm.isDes, config)">
        <app-radio-group-form-control [parentForm]="form" [controlName]="getControlName(formFields.debDesForm.isDes, config)" [title]="'Êtes-vous soumis au DES ?'" [inline]="true">
        </app-radio-group-form-control>
      </div>
    </div>
  </div>
</div>
