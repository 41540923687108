<div class="row">
  <div *ngFor="let banksForm of banks?.controls; index as i" class="col-12 col-sm-6 col-md-6 col-xl-4">
    <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
    <ng-container [formGroup]="banksForm">
      <div class="col-12 justify-content-center title-with-del">
        <h6>Banque {{ i + 1 }}</h6>
        <img *ngIf="i !== 0 && isEditMode" src="../../../../../../assets/img/delete-icon.svg" class="icon-in-label red" alt="" (click)="deleteBank(i)" />
      </div>
      <div class="col-12" *ngIf="isShowControl(formFields.banksForm.bankId, config)">
        <app-dropdown-form-control [parentForm]="banksForm" [controlName]="getControlName(formFields.banksForm.bankId, config)" [title]="bankTitle + (i + 1)" [placeholder]="'Sélectionnez la banque'">
        </app-dropdown-form-control>
      </div>
      <div class="col-12" *ngIf="isShowControl(formFields.banksForm.name, config)">
        <app-name-with-digits-form-control
          [parentForm]="banksForm"
          [controlName]="getControlName(formFields.banksForm.name, config)"
          [title]="''"
          [placeholder]="'Saisissez le nom de la banque'"
          class="no-title mt-2"
        >
        </app-name-with-digits-form-control>
      </div>
    </ng-container>
  </div>
</div>

<div class="row" *ngIf="isEditMode">
  <div class="col-12 text-end">
    <button class="btn pink w100mobile one-in-col" (click)="addNewBank()">
      <img src="../../../../../../assets/img/plus-icon.svg" alt="" />
      Ajouter une banque
    </button>
  </div>
</div>
