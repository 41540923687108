import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { CheckboxListFormControl } from './checkbox-list-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NameWithDigitsFormControl } from '../name-with-digits-form-control/name-with-digits-form-control';
import { constants } from '../../../constants/constants';

@Component({
    selector: 'app-checkbox-list-form-control',
    templateUrl: './checkbox-list-form-control.component.html',
})
export class CheckboxListFormControlComponent extends AbstractFormControlComponent<CheckboxListFormControl> implements OnInit {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Output() onOtherChange: EventEmitter<any> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() otherAnswer?: string;
    @Input() otherUniqClass?: string;
    @Input() inline: boolean;
    @Input() compact: boolean;

    faCheck: any = faCheck;

    form = new FormGroup({
        other: new NameWithDigitsFormControl(false, false)
    });

    ngOnInit(): void {
    }

    otherChange(event: any): void {
        this.onOtherChange.emit(event);
    }

    change(): void {
        this.onChange.emit(this.control.value);
    }

    public get isInline(): boolean {
        return this.inline || false;
    }

    public get isWithTooltip(): boolean {
        return this.control?.isWithTooltip || false;
    }

    public get tooltiPropertyName(): string {
        return 'tooltip';
    }

    public get isCompact(): boolean {
        return this.compact || false;
    }

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }

    public get hideErrorMessages(): boolean {
        return this.control.hideErrorMessages || false;
    }

    get class(): string {
        if (this.otherUniqClass) {
            return `no-title ${this.otherUniqClass}`;
        }

        return 'no-title';
    }

    public get multiple(): string {
        return this.control.multiple ? 'true' : 'false';
    }

    public get collection(): any[] {
        return this.control.collection || [];
    }

    public get keyPropertyName(): string {
        return this.control.keyPropertyName || constants.namePropertyName;
    }

    public get valuePropertyName(): string {
        return this.control.valuePropertyName || constants.namePropertyName;
    }
}
