import { FormControl } from '@angular/forms';

export function dayMonthValidator() {
    return (control: FormControl) => {
        const value = control.value;

        if (value) {
            if (!/((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)$/.test(value)) { // dd/mm
                return {
                    emailValidator: true
                };
            } else {
                return null;
            }
        }

        return null;
    };
}
