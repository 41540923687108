import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../abstract-modal';

@Component({
  selector: 'app-tab-changes-modal',
  templateUrl: './tab-changes-modal.component.html'
})

export class TabChangesModalComponent extends ModalComponent {

  constructor(public bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

}
