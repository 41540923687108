import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(
    public router: Router,
    public customerDataService: CustomerDataService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const goingTo = state.url;
    const profileGuid = next.queryParams.userId;

    if (!profileGuid && goingTo.indexOf('contact') === -1) {
      this.router.navigate(['contact']);
      return false;
    } else {
      return true;
    }
  }

}
