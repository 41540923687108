import { LookupShort, Price } from 'projects/difference/webapi/Difference.WebApi';

export enum BillingTaskType {
  FIXED = 'fixed',
  UNIT = 'unit',
  TIME_BASED = 'time-based',
  FEES_BASED = 'fees-based',
  NOT_INVOICED = 'not-invoiced',
}

export interface DevisTask {
  id?: number;
  mission?: LookupShort;
  activity?: LookupShort;
  name: string;
  billingItems?: BillingItem[];
  type?: BillingTaskType;
  prices?: Price[];
  // todo: fees ?
  profileAndSeniority?: string;
  notInvoiced?: boolean;
}

export interface CreateDevisTask {
  missionId?: number;
  activityId?: number;
  id?: number | undefined;
  name: string;
  billingItems?: BillingItem[];
  notInvoiced?: boolean;
}

export interface BillingItem {
  id?: number;
  officeId?: number;
  from?: number;
  to?: number;
  price?: number;
  employeeResourceId?: number;
  timeSpent?: number;
  flatRate?: number;
  feeValue?: number;
}
