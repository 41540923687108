import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { DataFieldConfig, Social } from 'projects/difference/webapi/Difference.WebApi';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { AbstractForm } from '../../abstract-form';
import { getSocialFormComponentControl, socialFormToViewModel } from './social-form.extensions';
import { SocialService } from './social-form.service';

@Component({
  selector: 'app-social-middle-form',
  templateUrl: './social-middle-form.component.html',
  styleUrls: ['./social-middle-form.component.scss']
})
export class SocialMiddleFormComponent extends AbstractForm<Social> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public digitalSafeToolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.DigitalSafeTools].otherId;
  public wageManagementToolOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.WageManagementTools].otherId;
  public personnelInformationSystemToolOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.PersonnelInformationSystemTools].otherId;
  public isIntrestedInDigitalSafeToolControl: RadioGroupFormControl;
  public isIntrestedInPersonnelInformationSystemToolControl: RadioGroupFormControl;
  public digitalSafeToolsControl: CheckboxListFormControl;
  public otherDigitalSafeToolControl: NameWithDigitsFormControl;
  public wageManagementToolsControl: CheckboxListFormControl;
  public otherWageManagementToolControl: NameWithDigitsFormControl;
  public personnelInformationSystemToolsControl: CheckboxListFormControl;
  public otherpersonnelInformationSystemToolControl: NameWithDigitsFormControl;
  public isDigitalSafeToolsControl: RadioGroupFormControl;
  public isPersonnelInformationSystemControl: RadioGroupFormControl;

  public hasDigitalSafeToolTitle: string = '';
  public hasPersonnelInformationSystemToolTitle: string = '';
  public toolsTitle: string = '';
  public hasPersonnelInformationSystemTools: boolean;
  public hasDigitalSafeTools: boolean;

  constructor(
    public socialService: SocialService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.socialService.getConfig(); 
    this.formData = await this.socialService.get(this.profileGuid);
    this.formId = this.formData?.socialId;
  }

  hasCompanyOrNotProcessing() {
    this.hasDigitalSafeToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un coffre-fort numérique pour le stockage de vos bulletins de salaire ? ' : 'Disposerez-vous d’un coffre-fort numérique pour le stockage de vos bulletins de salaire ?';
    this.hasPersonnelInformationSystemToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un outil SIRH (Système d’Information Ressources Humaines) ?' : 'Disposerez-vous d’un outil SIRH (Système d’Information Ressources Humaines) ?';
    this.toolsTitle = this.customerDataService.hasCompanyValue ? 'Quel(s) outil(s) utilisez-vous ? ' : 'Quel(s) outil(s) prévoyez-vous d’utiliser ?';
  }

  initControls(): void {
    this.isDigitalSafeToolsControl = this.form.get(this.formFields.social.hasDigitalSafeTool) as RadioGroupFormControl;
    this.isIntrestedInDigitalSafeToolControl = this.form.get(this.formFields.social.isIntrestedInDigitalSafeTool) as RadioGroupFormControl;
    this.digitalSafeToolsControl = this.form.get(this.formFields.social.digitalSafeTool) as CheckboxListFormControl;
    this.otherDigitalSafeToolControl = this.form.get('otherDigitalSafeTool') as NameWithDigitsFormControl;

    this.wageManagementToolsControl = this.form.get(this.formFields.social.wageManagementTool) as CheckboxListFormControl;
    this.otherWageManagementToolControl = this.form.get('otherWageManagementTool') as NameWithDigitsFormControl;

    this.isPersonnelInformationSystemControl = this.form.get(this.formFields.social.hasPersonnelInformationSystemTool) as RadioGroupFormControl;
    this.isIntrestedInPersonnelInformationSystemToolControl = this.form.get(this.formFields.social.isIntrestedInPersonnelInformationSystemTool) as RadioGroupFormControl;
    this.personnelInformationSystemToolsControl = this.form.get(this.formFields.social.personnelInformationSystemTool) as CheckboxListFormControl;
    this.otherpersonnelInformationSystemToolControl = this.form.get('otherPersonnelInformationSystemTool') as NameWithDigitsFormControl;

    this.subscriptionHandler.subscriptions = this.digitalSafeToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherDigitalSafeToolControl.markRequiredOrNot(toolsIds?.indexOf(this.digitalSafeToolsOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.wageManagementToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherWageManagementToolControl.markRequiredOrNot(toolsIds?.indexOf(this.wageManagementToolOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.personnelInformationSystemToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherpersonnelInformationSystemToolControl.markRequiredOrNot(toolsIds?.indexOf(this.personnelInformationSystemToolOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.isDigitalSafeToolsControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.digitalSafeToolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.digitalSafeToolsControl.markAsValid();
        })

        this.hasDigitalSafeTools = value;

        if (value === false) {
          this.digitalSafeToolsControl.patchValue([]);
          this.otherDigitalSafeToolControl.patchValue(null);
          this.isIntrestedInDigitalSafeToolControl.markRequiredOrNot(this.getIsControlRequired(formFields.social.isIntrestedInDigitalSafeTool));
        } else {
          this.isIntrestedInDigitalSafeToolControl.patchValue(null);
          this.isIntrestedInDigitalSafeToolControl.markAsNotRequred();
        }
      }
    });

    this.subscriptionHandler.subscriptions = this.isPersonnelInformationSystemControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.personnelInformationSystemToolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.personnelInformationSystemToolsControl.markAsValid();
        })

        this.hasPersonnelInformationSystemTools = value;

        if (value === false) {
          this.personnelInformationSystemToolsControl.patchValue([]);
          this.otherpersonnelInformationSystemToolControl.patchValue(null);
          this.isIntrestedInPersonnelInformationSystemToolControl.markRequiredOrNot(this.getIsControlRequired(formFields.social.isIntrestedInPersonnelInformationSystemTool));
        } else {
          this.isIntrestedInPersonnelInformationSystemToolControl.patchValue(null);
          this.isIntrestedInPersonnelInformationSystemToolControl.markAsNotRequred();
        }
      }
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      otherDigitalSafeTool: new NameWithDigitsFormControl(false, false),
      otherWageManagementTool: new NameWithDigitsFormControl(false, false),
      otherPersonnelInformationSystemTool: new NameWithDigitsFormControl(false, false)
    })

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getSocialFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  restoreAnswers(): void {
    const viewModel = socialFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  get isDigitalSafeToolsTrue(): boolean {
    return this.isDigitalSafeToolsControl.value === true;
  }

  get isOtherDigitalSafeTool(): boolean {
    return this.digitalSafeToolsControl.value && this.digitalSafeToolsControl.value.indexOf(this.digitalSafeToolsOtherId.toString()) !== -1;
  }

  get isOtherWageManagementTool(): boolean {
    return this.wageManagementToolsControl.value && this.wageManagementToolsControl.value?.indexOf(this.wageManagementToolOtherId.toString()) !== -1;
  }

  get isPersonnelInformationSystemTrue(): boolean {
    return this.isPersonnelInformationSystemControl.value === true;
  }

  get isOtherPersonnelInformationSystemTool(): boolean {
    return this.personnelInformationSystemToolsControl.value && this.personnelInformationSystemToolsControl.value.indexOf(this.personnelInformationSystemToolOtherId.toString()) !== -1;
  }
}
