<div id="app">
  <app-spinner></app-spinner>

  <div class="header row justify-content-center" *ngIf="isLoggedIn">
    <div class="header-content col-sm-12 col-md-12">
      <img [src]="logoPath" class="logo" alt="" />
      <div class="user-block">
        <p class="fw-600">{{ user?.fullName }}</p>
        <p class="fw-600">{{ user?.office?.name }}</p>
      </div>
    </div>
  </div>

  <mat-sidenav-container class="example-container" autosize>
    <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" [disableClose]="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" *ngIf="isLoggedIn">
      <mat-nav-list>
        <!-- <mat-list-item routerLink="clients" class="parent" [routerLinkActive]="['active-link']" (click)="noSubmenuItemClick()">
          <span class="menu-icon clients"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Clients</a>
        </mat-list-item> -->

        <mat-list-item routerLink="prospects" [routerLinkActive]="['active-link']" (click)="noSubmenuItemClick()">
          <span class="menu-icon prospects"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Prospects</a>
        </mat-list-item>

        <mat-list-item class="parent" [ngClass]="{ expanded: showDevisSubmenu, 'active-link': showDevisSubmenu }" (click)="showDevisSubmenuClick($event)">
          <span class="menu-icon devis"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Devis</a>
          <span class="full-width menu-icon expand" [ngClass]="{ visible: isShowing }"></span>
        </mat-list-item>

        <div class="submenu" [ngClass]="{ expanded: showDevisSubmenu }" *ngIf="isShowing || isExpanded">
          <mat-list-item routerLink="devis" [routerLinkActive]="['active-link']">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Consultation</a>
          </mat-list-item>

          <mat-list-item routerLink="devis-admin" [routerLinkActive]="['active-link']" accessControl [user]="user" [accessRole]="Role.SuperAdministrator">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Administration</a>
          </mat-list-item>
        </div>

        <mat-list-item class="parent" [ngClass]="{ expanded: showLettreMissionSubmenu, 'active-link': showLettreMissionSubmenu }" (click)="showLettreMissionSubmenuClick()">
          <span class="menu-icon lettre-mission"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Lettre de mission</a>
          <span class="full-width menu-icon expand" [ngClass]="{ visible: isShowing }"></span>
        </mat-list-item>
        <div class="submenu" [ngClass]="{ expanded: showLettreMissionSubmenu }" *ngIf="isShowing || isExpanded">
          <mat-list-item routerLink="lettre-mission" [routerLinkActive]="['active-link']">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Consultation</a>
          </mat-list-item>
          <mat-list-item routerLink="lettre-mission-admin" [routerLinkActive]="['active-link']" accessControl [user]="user" [accessRole]="Role.SuperAdministrator">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Administration</a>
          </mat-list-item>
        </div>

        <!-- <mat-list-item routerLink="calendriers" [routerLinkActive]="['active-link']" (click)="noSubmenuItemClick()">
          <span class="menu-icon calendriers"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Calendriers</a>
        </mat-list-item> -->

        <mat-list-item class="parent" [ngClass]="{ expanded: showDashboardSubmenu, 'active-link': showDashboardSubmenu }" (click)="showDashboardSubmenuClick()">
          <span class="menu-icon dashboards"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Dashboards</a>
          <span class="full-width menu-icon expand" [ngClass]="{ visible: isShowing }"></span>
        </mat-list-item>
        <div class="submenu" [ngClass]="{ expanded: showDashboardSubmenu }" *ngIf="isShowing || isExpanded">
          <!-- <mat-list-item routerLink="dashboards/clients" [routerLinkActive]="['active-link']">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Clients</a>
          </mat-list-item> -->
          <mat-list-item routerLink="dashboards/prospects" [routerLinkActive]="['active-link']">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Prospects</a>
          </mat-list-item>
          <!-- <mat-list-item routerLink="dashboards/devis" [routerLinkActive]="['active-link']">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Devis</a>
          </mat-list-item>
          <mat-list-item routerLink="dashboards/lettre" [routerLinkActive]="['active-link']">
            <span class="menu-icon"></span>
            <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Lettre de mission</a>
          </mat-list-item> -->
        </div>

        <mat-list-item routerLink="alerts" [routerLinkActive]="['active-link']" (click)="noSubmenuItemClick()">
          <span class="menu-icon alerts"></span>
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Alerts</a>
        </mat-list-item>

        <mat-list-item routerLink="/authentication/logout">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Se déconnecter</a>
          <mat-icon mat-list-icon>exit_to_app</mat-icon>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list> </mat-nav-list>
    </mat-sidenav>

    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>
</div>
