import { Injectable } from '@angular/core';
import { GeneralInfo, GeneralInfoClient } from '../../../../../../webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class GeneralInfoService extends AbstractFormService<GeneralInfo> {
  constructor(public webApi: GeneralInfoClient) {
    super(webApi);
  }
}
