import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function matDatepickerMinValidator() {
    return (control: FormControl) => {
        const date = control.value;
        const minDate = control.get('minDate'); 

        if (date && minDate) {
            if (moment.utc(date) < moment.utc(minDate.value)) {
                return {
                    matDatepickerMin: true
                };
            } else {
                return null;
            }
        }
        return null;
    };
}
