import { Injectable } from '@angular/core';
import { Expenses, ExpensesClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class ExpensesService extends AbstractFormService<Expenses> {
  constructor(public webApi: ExpensesClient) {
    super(webApi);
  }
}
