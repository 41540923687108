import { NumberFormControl } from 'projects/difference/app/shared/components/controls/number-form-control/number-form-control';
import { NameWithDigitsFormControl } from 'projects/difference/app/shared/components/controls/name-with-digits-form-control/name-with-digits-form-control';
import { DateFormControl } from './../../../controls/date-form-control/date-form-control';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { ControlCenterService } from './control-center-form.service';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { AbstractForm } from '../../abstract-form';
import { ControlCenter, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { controlCenterFormToViewModel, getControlCenterFormComponentControl } from './control-center-form.extensions';

@Component({
  selector: 'app-control-center-form',
  templateUrl: './control-center-form.component.html',
  styleUrls: ['./control-center-form.component.scss']
})
export class ControlCenterFormComponent extends AbstractForm<ControlCenter> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public isMemberManagementCenterControl: RadioGroupFormControl;
  accessionDateControl: DateFormControl;
  managementCenterNameControl: NameWithDigitsFormControl;
  managementCenterAddressControl: NameWithDigitsFormControl;
  managementCenterNumberControl: NumberFormControl;
  isToDoMemberManagementCenterControl: RadioGroupFormControl;

  constructor(
    public controlCenterService: ControlCenterService,
    public lookupDataService: LookupDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.controlCenterService.getConfig(); 
    this.formData = await this.controlCenterService.get(this.profileGuid);
    this.formId = this.formData?.taxControlCenterId;
  }

  initControls(): void {
    this.isMemberManagementCenterControl = this.form.get(formFields.controlCenterForm.isMemberManagementCenter) as RadioGroupFormControl;
    this.accessionDateControl = this.form.get(formFields.controlCenterForm.accessionDate) as DateFormControl;
    this.managementCenterNameControl = this.form.get(formFields.controlCenterForm.managementCenterName) as NameWithDigitsFormControl;
    this.managementCenterAddressControl = this.form.get(formFields.controlCenterForm.managementCenterAddress) as NameWithDigitsFormControl;
    this.managementCenterNumberControl = this.form.get(formFields.controlCenterForm.managementCenterNumber) as NumberFormControl;
    this.isToDoMemberManagementCenterControl = this.form.get(formFields.controlCenterForm.isToDoMemberManagementCenter) as RadioGroupFormControl;

    this.subscriptionHandler.subscriptions = this.isMemberManagementCenterControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.accessionDateControl.markRequiredOrNot(value);
        this.managementCenterNameControl.markRequiredOrNot(value);
        this.managementCenterAddressControl.markRequiredOrNot(value);
        this.managementCenterNumberControl.markRequiredOrNot(value);
        this.isToDoMemberManagementCenterControl.markRequiredOrNot(!value);

        if (value === false) {
          this.accessionDateControl.patchValue(null);
          this.managementCenterNameControl.patchValue(null);
          this.managementCenterAddressControl.patchValue(null);
          this.managementCenterNumberControl.patchValue(null);
          this.isToDoMemberManagementCenterControl.patchValue(null);
        }
      }
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getControlCenterFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  restoreAnswers(): void {
    const viewModel = controlCenterFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  get isMemberManagementCenterTrue(): boolean {
    return this.isMemberManagementCenterControl?.value === true;
  }

  get isMemberManagementCenterFalse(): boolean {
    return this.isMemberManagementCenterControl?.value === false;
  }

}
