import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from '../../services/progress-bar.service';
import { SubscriptionHandler } from '../../shared/components/subscriptionHandler';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public progressWidth: number;

  constructor(
    public progressBarService: ProgressBarService,
  ) { }

  ngOnInit(): void {
    this.subscriptionHandler.subscriptions = this.progressBarService.getStep().subscribe((step: any) => {
      this.progressWidth = step.text;
    });
  }

}
