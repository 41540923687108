import { NumberFormControl } from './../../../controls/number-form-control/number-form-control';
import { DropDownFormControl } from './../../../controls/dropdown-form-control/dropdown-form-control';
import { CheckboxFormControl } from './../../../controls/checkbox-form-control/checkbox-form-control';
import { adminConstants } from './../../../../../../../difference-admin/app/shared/constants/constants';
import { NameWithDigitsFormControl } from './../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { SalesService } from './sales.service';
import { DataFieldConfig, Sales } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractForm } from '../../abstract-form';
import { getSalesFormComponentControl, salesFormToViewModel } from './sales-form.extensions';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-ventes-prestation-form',
  templateUrl: './ventes-prestation-form.component.html',
  styleUrls: ['./ventes-prestation-form.component.scss']
})
export class VentesPrestationFormComponent extends AbstractForm<Sales> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;

  public isWithoutInvoicesControl: CheckboxFormControl;
  public toolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.VolumeSalesTools].otherId;
  public toolsControl: CheckboxListFormControl;
  public otherToolControl: NameWithDigitsFormControl;
  public isSalesManagementToolControl: RadioGroupFormControl;
  public isWantSalesManagementToolControl: RadioGroupFormControl;
  public numberPerPeriodTitle: string = '';
  public hasToolTitle: string = '';
  public toolsTitle: string = '';
  public periodIdControl: DropDownFormControl;
  public numberPerPeriodControl: NumberFormControl;
  public formatIdControl: DropDownFormControl;

  constructor(
    public salesService: SalesService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.initSubscriptions();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.salesService.getConfig();
    this.formData = await this.salesService.get(this.profileGuid);
    this.formId = this.formData?.volumeSaleId;
  }

  hasCompanyOrNotProcessing() {
    this.numberPerPeriodTitle = this.customerDataService.hasCompanyValue ? 'Nombre de pièces par période' : 'Nombre de pièces prévisionnel par période (si connu)';
    this.hasToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un outil de gestion des ventes ? ' : 'Disposerez-vous d’un outil de gestion des ventes ?';
    this.toolsTitle = this.customerDataService.hasCompanyValue ? 'Quel(s) outil(s) utilisez-vous ? ' : 'Quel(s) outil(s) utiliserez-vous ?';
  }

  initControls(): void {
    this.isWithoutInvoicesControl = this.form.get(formFields.salesForm.isWithoutInvoices) as CheckboxFormControl;
    this.periodIdControl = this.form.get(formFields.salesForm.periodId) as DropDownFormControl;
    this.numberPerPeriodControl = this.form.get(formFields.salesForm.numberPerPeriod) as NumberFormControl;
    this.formatIdControl = this.form.get(formFields.salesForm.formatId) as DropDownFormControl;
    this.isSalesManagementToolControl = this.form.get(formFields.salesForm.hasTool) as RadioGroupFormControl;
    this.isWantSalesManagementToolControl = this.form.get(formFields.salesForm.isInterested) as RadioGroupFormControl;
    this.toolsControl = this.form.get(formFields.salesForm.managementTools) as CheckboxListFormControl;
    this.otherToolControl = this.form.get(adminConstants.otherToolControlName) as NameWithDigitsFormControl;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.toolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherToolControl.markRequiredOrNot(toolsIds?.indexOf(this.toolsOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.isWithoutInvoicesControl.valueChanges.subscribe((value: any) => {
      if (value !== undefined) {
        if (value === true) {
          this.periodIdControl.markAsNotRequred();
          this.numberPerPeriodControl.markAsNotRequred();
          this.formatIdControl.markAsNotRequred();
          this.periodIdControl.patchValue(null);
          this.numberPerPeriodControl.patchValue(null);
          this.formatIdControl.patchValue(null);
        } else {
          this.periodIdControl?.markRequiredOrNot(this.getIsControlRequired(formFields.salesForm.periodId));
          this.numberPerPeriodControl?.markRequiredOrNot(this.getIsControlRequired(formFields.salesForm.numberPerPeriod));
          this.formatIdControl?.markRequiredOrNot(this.getIsControlRequired(formFields.salesForm.formatId));
        }
      }
    })

    this.subscriptionHandler.subscriptions = this.isSalesManagementToolControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.toolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.toolsControl.markAsValid();
        })

        this.isWantSalesManagementToolControl.markRequiredOrNot(!value);

        if (value === false) {
          this.isWantSalesManagementToolControl.patchValue(null);
          this.toolsControl.patchValue([]);
          this.otherToolControl.patchValue(null);
        }
      }
    });
  }

  restoreAnswers(): void {
    const viewModel = salesFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      otherTool: new NameWithDigitsFormControl(false, false)
    })

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getSalesFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  get isSalesManagementTool(): boolean {
    return this.isSalesManagementToolControl?.value === true;
  }

  get isOtherTool(): boolean {
    return this.toolsControl.value && this.toolsControl.value.indexOf(this.toolsOtherId.toString()) !== -1; // todo: rewrite 
  }

  get isWithoutInvoices(): boolean {
    return this.isWithoutInvoicesControl.value === true;
  }

}
