<div class="row justify-content-center">
  <div class="col col-sm-10 col-md-10 footer-text">
    Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifée, les données à caractère personnel recueillies font l’objet d’un traitement automatisé destiné à la production de devis.
    Elles peuvent également être utilisées à des fns de traitement exclusifs au cabinet <span (click)="goToCabinet($event)" class="link-like"> {{ companyName }}</span
    >. Les destinataires de ces données sont le cabinet <span (click)="goToCabinet($event)" class="link-like"> {{ companyName }}</span
    >, le cas échéant ses mandataires, les organismes professionnels concernés et les autorités françaises pour satisfaire aux obligations légales et réglementaires en vigueur. Vous bénéfciez d’un
    droit d’accès, d’opposition et de rectifcation aux informations qui vous concernent. Vous pouvez exercer vos droits à l'accès et à la rectifcation via le lien reçu par email suite à la validation
    de ce formulaire et votre droit à la suppression en envoyant un email à
    <span (click)="goToEmail($event)" class="link-like"> {{ email }}</span>
  </div>
</div>
