import { Injectable } from '@angular/core';
import { AcdClient } from 'projects/difference/webapi/Difference.WebApi';

@Injectable({
  providedIn: 'root'
})
export class AcdService {
  constructor(private webApi: AcdClient) {}

  public async createFolder(engagementLetterId: number): Promise<string> {
    return this.webApi.createFolder(engagementLetterId).toPromise();
  }
}
