import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CompanyAgreement, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { ToogleFormControl } from '../../../controls/toogle-form-control/toogle-form-control';
import { FormValuesChangedModel } from '../../abstract-form';

function getCompanyAgreementFormComponentControl(config: DataFieldConfig, mode: FormMode): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.companyAgreement.conventionTeam: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly)
        }
        case formFields.companyAgreement.isCompanyAgreement: {
            return new ToogleFormControl(config.isRequired, isReadOnly)
        }
        case formFields.companyAgreement.details: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly)
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function companyAgreementFormToViewModel(config: DataFieldConfig[], data: any) {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
        viewModel[configItem.name] = data[propertyName]
    })

    return viewModel;
}

function companyAgreementModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): CompanyAgreement {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            objModel[propertyNameForSaveObj] = model[configItem.name];
        })

        objModel.socialCompanyAgreementId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new CompanyAgreement(objModel);
}

export {
    getCompanyAgreementFormComponentControl,
    companyAgreementFormToViewModel,
    companyAgreementModelToDataModel
}
