import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, ManagementTool, OtherTools, OtherUtils } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { adminConstants } from 'projects/difference-admin/app/shared/constants/constants';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { FormValuesChangedModel } from '../../abstract-form';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';

function getOtherToolsFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.otherToolsForm.hasManagementMoneyTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherToolsForm.isIntrestedInManagementMoneyTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherToolsForm.managementMoneyTools: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherToolsCashManagement],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }

        case formFields.otherToolsForm.hasManagementStocksTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherToolsForm.isIntrestedInManagementStocksTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherToolsForm.managementStocksTools: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherToolsStockManagement],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }

        case formFields.otherToolsForm.hasElectronicInvoicingTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherToolsForm.isIntrestedInElectronicInvoicingTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherToolsForm.electronicInvoicingTools: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.ElectronicInvoicingTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function otherToolsFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): any[] {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

        if (configItem.name === formFields.otherToolsForm.managementMoneyTools) {
            viewModel[configItem.name] = data.managementMoneyTools?.tools.map((item: any) => { return item + '' });

            if (data.managementMoneyTools?.otherToolName) {
                viewModel[adminConstants.otherMoneyToolName] = data.managementMoneyTools.otherToolName;

                const otherId = lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherToolsCashManagement].otherId;
                viewModel[configItem.name].push(otherId.toString());
            }
            return;
        }
        if (configItem.name === formFields.otherToolsForm.managementStocksTools) {
            viewModel[configItem.name] = data.managementStocksTools?.tools.map((item: any) => { return item + '' });

            if (data.managementStocksTools?.otherToolName) {
                viewModel[adminConstants.otherStocksToolName] = data.managementStocksTools.otherToolName;

                const otherId = lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherToolsStockManagement].otherId;
                viewModel[configItem.name].push(otherId.toString());
            }
            return;
        }
        if (configItem.name === formFields.otherToolsForm.electronicInvoicingTools) {
            viewModel[configItem.name] = data.electronicInvoicingTools?.tools.map((item: any) => { return item + '' });

            if (data.electronicInvoicingTools?.otherToolName) {
                viewModel[adminConstants.otherElectronicInvoicingToolName] = data.electronicInvoicingTools.otherToolName;

                const otherId = lookupDataService.lookupDataObj[LookupTypeId.ElectronicInvoicingTools].otherId;
                viewModel[configItem.name].push(otherId.toString());
            }
            return;
        }
        if (configItem.name === formFields.otherToolsForm.otherUtils) {
            data[propertyName]?.forEach((item: any) => {
                const tools = Object.assign(item[adminConstants.otherMoneyToolsName]?.tools ?? []);
                const otherToolNameValue = item[adminConstants.otherMoneyToolsName]?.otherToolName;
                const otherToolName = otherToolNameValue ? Object.assign(otherToolNameValue) : null;

                // TODO: check this part of code. We now have a textfield in the UI, not a dropdown list.
                if (otherToolName) {
                    const otherId = lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherToolsCashManagement].otherId;
                    item[adminConstants.otherMoneyToolsName] = [(otherId.toString())];

                    item.otherOtherTool = otherToolName;
                }

                if (!otherToolName) {
                    item[adminConstants.otherMoneyToolsName] = [];
                }

                item[adminConstants.otherMoneyToolsName] = item[adminConstants.otherMoneyToolsName]?.concat(tools.map((item: any) => { return item + '' }));

                if (!viewModel[configItem.name]) {
                    viewModel[configItem.name] = [];
                }
                viewModel[configItem.name].push(item);
            });
        } else {
            viewModel[configItem.name] = data[propertyName];
        }
    })

    return viewModel;
}

function otherToolsViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): OtherTools {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

            if (propertyNameForSaveObj === 'otherUtils') {
                const otherUtils = data?.model.OtherUtils;

                if (otherUtils && otherUtils.length > 0) {
                    objModel.otherUtils = [];
                }

                otherUtils?.forEach((otherUtils: any, index: number) => {
                    let tools: number[] = otherUtils.otherMoneyTools?.map((id: string) => parseInt(id));

                    if (!tools) {
                        tools = [];
                    }

                    const otherUtilsObj = new OtherUtils({
                        description: otherUtils.description
                    })

                    objModel.otherUtils.push(otherUtilsObj);
                })
            } else if (configItem.name === formFields.otherToolsForm.managementMoneyTools) {
                const tools: number[] = model[formFields.otherToolsForm.managementMoneyTools]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherMoneyTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else if (configItem.name === formFields.otherToolsForm.managementStocksTools) {
                const tools: number[] = model[formFields.otherToolsForm.managementStocksTools]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherStocksTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else if (configItem.name === formFields.otherToolsForm.electronicInvoicingTools) {
                const tools: number[] = model[formFields.otherToolsForm.electronicInvoicingTools]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherElectronicInvoicingTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else {
                objModel[propertyNameForSaveObj] = model[configItem.name];
            }
        })

        objModel.volumeOtherToolId = data.formId.volumeOtherToolId;
        objModel.profileGuid = data.profileGuid;
    }

    return new OtherTools(objModel);
}

export {
    getOtherToolsFormComponentControl,
    otherToolsFormToViewModel,
    otherToolsViewModelToDataModel
}
