import { ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class ToogleFormControl extends AbstractFormControl {
  constructor(required: boolean = false, disabled: boolean = false, options?: ToogleFormControlOptions) {
    super(required, disabled);
    this.setValue(false);

    if (options?.defaultValue) {
      this.setValue(options.defaultValue);
    }
  }

  protected getValidators(): ValidatorFn[] {
    return [];
  }
}

interface ToogleFormControlOptions {
  defaultValue?: boolean;
}
