import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class CompanyNameAutocompleteFormControl extends AbstractFormControl {

    validators: any;
    constructor(required: boolean = false, disabled: boolean = false, private options?: CompanyOptions) {
        super(required, disabled);

        if (options && options.defaultValue) {
            this.setValue(options.defaultValue);
        } else {
            this.setValue(null);
        }

        this.updateValidators(required);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    private updateValidators(required: boolean): void {
        const validators = [];

        if (required) {
            validators.push(Validators.required);
        }

        this.validators = validators;
        super.setValidators(validators);
    }

    protected getValidators(): ValidatorFn[] {
        return this.validators;
    }

}

export interface CompanyOptions {
    defaultValue?: any;
    readOnlyCss?: boolean;
}
