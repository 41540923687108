
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appNoSymbols]'
})
export class NoSymbolsDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('blur') onBlur() {
    const value = this.ngControl.control.value;
    if (value) {
      this.ngControl.control.patchValue(value.trim().replace(/[^a-zA-Z0-9 ]/g, ''));
    }
  }

}
