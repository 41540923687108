
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgressBarService } from '../../services/progress-bar.service';
import { constants } from '../../shared/constants/constants';
import { MatAccordion } from '@angular/material/expansion';
import { VolumesTabModel } from 'projects/difference/webapi/Difference.WebApi';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CustomerDataService } from '../../services/customer-data.service';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { TabsService } from '../../services/tabs.service';
import { purchasesViewModelToDataModel } from '../../shared/components/forms/volumes/achats-form/purchases-form.extensions';
import { PurchasesService } from '../../shared/components/forms/volumes/achats-form/purchases.service';
import { salesViewModelToDataModel } from '../../shared/components/forms/volumes/ventes-prestation-form/sales-form.extensions';
import { SalesService } from '../../shared/components/forms/volumes/ventes-prestation-form/sales.service';
import { expensesViewModelToDataModel } from '../../shared/components/forms/volumes/notes-de-frais-form/expenses-form.extensions';
import { ExpensesService } from '../../shared/components/forms/volumes/notes-de-frais-form/expenses.service';
import { OtherStreamsService } from '../../shared/components/forms/volumes/autres-flux-form/other-streams-form.service';
import { otherStreamsViewModelToDataModel } from '../../shared/components/forms/volumes/autres-flux-form/other-streams-form.extensions';
import { OtherToolsService } from '../../shared/components/forms/volumes/autres-outils-form/other-tools-form.service';
import { otherToolsViewModelToDataModel } from '../../shared/components/forms/volumes/autres-outils-form/other-tools-form.extensions';
import { BanksService } from '../../shared/components/forms/volumes/banques-form/banks-form.service';
import { banksViewModelToDataModel } from '../../shared/components/forms/volumes/banques-form/banks-form.extensions';
import { MenuDataService } from '../../services/menu-data.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';

@Component({
  selector: 'app-volumes',
  templateUrl: './volumes.component.html',
  styleUrls: ['./volumes.component.scss']
})
export class VolumesComponent extends AbstractClientTabComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public FormMode = FormMode;
  public profileGuid: string;
  public isVentesPrestationFormValid: boolean;
  public ventesPrestationFormChangedModel: FormValuesChangedModel;

  public achatsFormChangedModel: FormValuesChangedModel;
  public isAchatsFormValid: boolean;

  public notesDeFraisFormChangedModel: FormValuesChangedModel;
  public isNotesDeFraisFormValid: boolean;

  public autresFluxFormChangedModel: FormValuesChangedModel;
  public isAutresFluxFormValid: boolean;

  public autresOutilsFormChangedModel: FormValuesChangedModel;
  public isAutresOutilsFormValid: boolean;

  public banquesFormChangedModel: FormValuesChangedModel;
  public isBanquesFormValid: boolean;

  constructor(
    public route: ActivatedRoute,
    public progressBarService: ProgressBarService,
    public customerDataService: CustomerDataService,
    public tabsService: TabsService,
    public salesService: SalesService,
    public purchasesService: PurchasesService,
    public expensesService: ExpensesService,
    public otherStreamsService: OtherStreamsService,
    public otherToolsService: OtherToolsService,
    public banksService: BanksService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Volumes, bsModalService);
  }

  ngOnInit(): void {
    this.progressBarService.sendStep(constants.steps.volumes.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isVolumesValid === undefined) {
        return;
      }

      if ((this.isVolumesPristine && this.isVolumesValid)) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isVolumesValid) {
        const model = this.prepareModelToSave();
        const response = await this.tabsService.saveVolumes(model);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  goBack(): void {
    if (this.customerDataService.hasCompanyValue) {
      this.menuDataService.onChangeStep(ClientMenuStep.Interlocutors);
    } else {
      this.menuDataService.onChangeStep(ClientMenuStep.Besoin);
    }
  }

  onVentesPrestationFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isVentesPrestationFormValid = data.validState;
    })
    this.ventesPrestationFormChangedModel = data;
  }

  onAchatsFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isAchatsFormValid = data.validState;
    })
    this.achatsFormChangedModel = data;
  }

  onNotesDeFraisFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isNotesDeFraisFormValid = data.validState;
    })
    this.notesDeFraisFormChangedModel = data;
  }

  onAutresFluxFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isAutresFluxFormValid = data.validState;
    })
    this.autresFluxFormChangedModel = data;
  }

  onAutresOutilsFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isAutresOutilsFormValid = data.validState;
    })
    this.autresOutilsFormChangedModel = data;
  }

  onBanquesFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isBanquesFormValid = data.validState;
    })
    this.banquesFormChangedModel = data;
  }

  get isVolumesValid(): boolean {
    return this.isVentesPrestationFormValid &&
      this.isAchatsFormValid &&
      this.isNotesDeFraisFormValid &&
      this.isAutresOutilsFormValid &&
      this.isAutresFluxFormValid &&
      this.isBanquesFormValid;
  }

  get isVolumesPristine(): boolean {
    return this.ventesPrestationFormChangedModel?.isPristine &&
      this.achatsFormChangedModel?.isPristine &&
      this.notesDeFraisFormChangedModel?.isPristine &&
      this.autresFluxFormChangedModel?.isPristine &&
      this.autresOutilsFormChangedModel?.isPristine &&
      this.banquesFormChangedModel?.isPristine;
  }

  async submitForm(): Promise<void> {
    const model = this.prepareModelToSave();
    const response = await this.tabsService.saveVolumes(model);

    if (this.customerDataService.hasCompanyValue) {
      this.menuDataService.onChangeStep(ClientMenuStep.Taxes);
    } else {
      this.menuDataService.onChangeStep(ClientMenuStep.Accounting);
    }
  }

  prepareModelToSave(): VolumesTabModel {
    return {
      sale: salesViewModelToDataModel(this.salesService.config, this.ventesPrestationFormChangedModel),
      purchases: purchasesViewModelToDataModel(this.purchasesService.config, this.achatsFormChangedModel),
      expenses: expensesViewModelToDataModel(this.expensesService.config, this.notesDeFraisFormChangedModel),
      otherStreams: otherStreamsViewModelToDataModel(this.otherStreamsService.config, this.autresFluxFormChangedModel),
      otherTools: otherToolsViewModelToDataModel(this.otherToolsService.config, this.autresOutilsFormChangedModel),
      banks: banksViewModelToDataModel(this.banksService.config, this.banquesFormChangedModel),
      profileGuid: this.profileGuid
    } as VolumesTabModel;
  }

}
