import { Injectable } from '@angular/core';
import { BaseEventsService } from 'projects/difference-admin/app/shared/services/base-event-service';
import { eventServicesList } from 'projects/difference-admin/app/shared/services/event-services-list';

@Injectable({
  providedIn: 'root'
})

export class FilesEventsService extends BaseEventsService {

  constructor() {
    super(eventServicesList.files);
  }
}
