import { NameWithDigitsFormControl } from './../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { NumberFormControl } from './../../../controls/number-form-control/number-form-control';
import { PhoneFormControl } from './../../../controls/phone-form-control/phone-form-control';
import { NameFormControl } from 'projects/difference/app/shared/components/controls/name-form-control/name-form-control';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { DataFieldConfig, Stakeholder } from 'projects/difference/webapi/Difference.WebApi';
import { EmailFormControl } from '../../../controls/email-form-control/email-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { FormValuesChangedModel } from '../../abstract-form';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';

function getStakeholdersFormComponentControl(config: DataFieldConfig, mode: FormMode): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.stakeholdersForm.nature: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.stakeholdersForm.address: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.stakeholdersForm.firstName: {
            return new NameFormControl(config.isRequired, isReadOnly);
        }
        case formFields.stakeholdersForm.lastName: {
            return new NameFormControl(config.isRequired, isReadOnly);
        }
        case formFields.stakeholdersForm.email: {
            return new EmailFormControl(config.isRequired, isReadOnly);
        }
        case formFields.stakeholdersForm.phone: {
            return new PhoneFormControl(config.isRequired, isReadOnly);
        }
        case formFields.stakeholdersForm.stakeholderTypeId: {
            return new NumberFormControl(false, false);
        }
    }
}

function stakeholdersToViewModel(config: DataFieldConfig[], stakeholders: Stakeholder[], lookupDataService: LookupDataService) {
    const viewModel: any[] = [];

    stakeholders?.forEach((stakeholder: any) => {
        const benObj: any = {};

        config?.forEach((configItem: any) => {
            const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            benObj[configItem.name] = stakeholder[propertyName]
        })

        viewModel.push(benObj);
    })

    return viewModel;
}

function stakeholdersViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel, lookupDataService: LookupDataService): Stakeholder[] {
    const objModelArray: Stakeholder[] = [];
    const model = data?.model?.stakeholders;

    if (!data || !data.model) {
        return null;
    }

    model?.forEach((stakeholderFormModel: any, index: number) => {
        const stakeholderObjModel: any = {};

        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            stakeholderObjModel[propertyNameForSaveObj] = stakeholderFormModel[configItem.name];
        })

        if (data.formId && data.formId[index]) {
            stakeholderObjModel.stakeholderId = data.formId[index];
        }

        stakeholderObjModel.profileGuid = data.profileGuid;

        objModelArray.push(stakeholderObjModel);
    })

    return objModelArray;
}

export {
    getStakeholdersFormComponentControl,
    stakeholdersToViewModel,
    stakeholdersViewModelToDataModel
}
