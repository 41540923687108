import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private subject = new Subject<any>();

  constructor() { }

  getStep(): Observable<any> {
    return this.subject.asObservable();
  }

  sendStep(message: number): void {
    this.subject.next({ text: message });
  }
}
