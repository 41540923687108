import { NgModule } from '@angular/core';
import { FacturationPipe } from './facturation.pipe';
import { GradePipe } from './grade.pipe';
import { InvoiceGatelPipe } from './invoice-gatel.pipe';
import { MissionTypePipe } from './mission-type.pipe';
import { TagPipe } from './tag.pipe';
import { ThreeDotsMenuPipe } from './three-dots-menu.pipe';
import { TypePrestationPipe } from './type-prestation.pipe';

@NgModule({
  declarations: [
    ThreeDotsMenuPipe,
    InvoiceGatelPipe,
    FacturationPipe,
    MissionTypePipe,
    TagPipe,
    TypePrestationPipe,
    GradePipe
  ],
  imports: [
  ],
  exports: [
    ThreeDotsMenuPipe,
    InvoiceGatelPipe,
    FacturationPipe,
    MissionTypePipe,
    TagPipe,
    TypePrestationPipe,
    GradePipe
  ],
  providers: [
    ThreeDotsMenuPipe,
    TagPipe,
    TypePrestationPipe,
    GradePipe
  ]
})
export class AdminPipesModule { }
