<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="row">
    <div class="col-12 col-xl-6" *ngIf="isShowControl(formFields.otherToolsForm.hasManagementMoneyTool, config)">
      <app-radio-group-form-control [parentForm]="form" [controlName]="getControlName(formFields.otherToolsForm.hasManagementMoneyTool, config)" [title]="hasManagementMoneyToolTitle" [inline]="true">
      </app-radio-group-form-control>
    </div>
    <div class="col-12 col-xl-6" *ngIf="isManageMoneyToolControl?.value === false && isShowControl(formFields.otherToolsForm.isIntrestedInManagementMoneyTool, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.otherToolsForm.isIntrestedInManagementMoneyTool, config)"
        [title]="'Êtes-vous intéressé par un outil de ce type ?'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isManageMoneyTool && isShowControl(formFields.otherToolsForm.managementMoneyTools, config)">
    <div class="col-12 col-sm-6 col-md-8 col-xl-9">
      <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.otherToolsForm.managementMoneyTools, config)" [title]="toolsTitle" [inline]="true">
      </app-checkbox-list-form-control>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3" *ngIf="isOtherMoneyTool">
      <app-name-with-digits-form-control [parentForm]="form" [controlName]="'otherMoneyTool'" [title]="''" [placeholder]="'Nom de l\'outil'" class="like-no-title-in-mobile">
      </app-name-with-digits-form-control>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-12 col-xl-6" *ngIf="isShowControl(formFields.otherToolsForm.hasManagementStocksTool, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.otherToolsForm.hasManagementStocksTool, config)"
        [title]="hasManagementStocksToolTitle"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
    <div class="col-12 col-xl-6" *ngIf="isManageStocksToolControl?.value === false && isShowControl(formFields.otherToolsForm.isIntrestedInManagementStocksTool, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.otherToolsForm.isIntrestedInManagementStocksTool, config)"
        [title]="'Êtes-vous intéressé par un outil de ce type ?'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isManageStocksTool && isShowControl(formFields.otherToolsForm.managementStocksTools, config)">
    <div class="col-12 col-sm-6 col-md-8 col-xl-9">
      <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.otherToolsForm.managementStocksTools, config)" [title]="toolsTitle" [inline]="true">
      </app-checkbox-list-form-control>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3" *ngIf="isOtherStocksTool">
      <app-name-with-digits-form-control [parentForm]="form" [controlName]="'otherStocksTool'" [title]="''" [placeholder]="'Nom de l\'outil'" class="like-no-title-in-mobile">
      </app-name-with-digits-form-control>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-12 col-xl-6" *ngIf="isShowControl(formFields.otherToolsForm.hasElectronicInvoicingTool, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.otherToolsForm.hasElectronicInvoicingTool, config)"
        [title]="hasElectronicInvoicingToolTitle"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
    <div class="col-12 col-xl-6" *ngIf="isElectronicInvoicingToolControl?.value === false && isShowControl(formFields.otherToolsForm.isIntrestedInElectronicInvoicingTool, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.otherToolsForm.isIntrestedInElectronicInvoicingTool, config)"
        [title]="'Êtes-vous intéressé par un outil de ce type ?'"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isElectronicInvoicingTool && isShowControl(formFields.otherToolsForm.electronicInvoicingTools, config)">
    <div class="col-12 col-sm-6 col-md-8 col-xl-9">
      <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.otherToolsForm.electronicInvoicingTools, config)" [title]="toolsTitle" [inline]="true">
      </app-checkbox-list-form-control>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3" *ngIf="isOtherElectronicInvoicingTool">
      <app-name-with-digits-form-control [parentForm]="form" [controlName]="'otherElectronicInvoicingTool'" [title]="''" [placeholder]="'Nom de l\'outil'" class="like-no-title-in-mobile">
      </app-name-with-digits-form-control>
    </div>
  </div>

  <div *ngIf="isShowControl(formFields.otherToolsForm.otherUtils, config)">
    <div *ngFor="let otherUtilForm of otherUtils.controls; index as i">
      <hr />
      <ng-container [formGroup]="otherUtilForm">
        <div class="col-12 justify-content-center title-with-del">
          <h5>Outil complémentaire {{ i + 2 }}</h5>
          <img *ngIf="isEditMode" src="../../../../../../assets/img/delete-icon.svg" class="icon-in-label red" alt="" (click)="deleteOtherUtils(i)" />
        </div>

        <div class="row">
          <div class="col-12 col-xl-6">
            <app-name-with-digits-form-control
              [parentForm]="otherUtilForm"
              [controlName]="'description'"
              [title]="'Quel et le nom de cet outil et à quelle fonction est-il dédié ? '"
              [placeholder]="'Saisissez le nom et la fonction de cet outil'"
            >
            </app-name-with-digits-form-control>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="row" *ngIf="isEditMode">
  <div class="col-12 text-end">
    <button class="btn pink w100mobile one-in-col" (click)="addNewUtil()">
      <img src="../../../../../../assets/img/plus-icon.svg" alt="" />
      Ajouter un outil
    </button>
  </div>
</div>
