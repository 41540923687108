import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteConfirmComponent } from '../../modals/delete-confirm/delete-confirm.component';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { BeneficiariesService } from './benificiaries-form.service';
import { AbstractForm, FormValuesChangedModel } from '../abstract-form';
import { Beneficiary, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { benificiariesToViewModel, getBeneficiariesFormComponentControl } from './benificiaries-form.extensions';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { collections } from '../../../constants/collections';
import { NumberFormControl } from '../../controls/number-form-control/number-form-control';

@Component({
  selector: 'app-benificiaries-form',
  templateUrl: './benificiaries-form.component.html'
})
export class BenificiariesFormComponent extends AbstractForm<Beneficiary[]> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public collections = collections;
  public benificiatySumError: boolean = false;

  constructor(
    public bsModalService: BsModalService,
    public beneficiariesService: BeneficiariesService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      //this.initControls();
      this.initSubscriptions();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.form.valueChanges.subscribe((value: any) => {
      if (!this.benificiaries || this.benificiaries.length === 0) {
        this.benificiatySumError = false;
        return;
      }

      let summ = 0;

      this.benificiaries.controls.forEach((fg: FormGroup) => {
        const allocatedShareControl = fg.get(this.formFields.beneficiariesForm.sharesPercentage) as NumberFormControl;
        summ += allocatedShareControl.value;
      });

      if (summ !== 100) {
        const data = { model: this.form.getRawValue(), validState: false, isPristine: this.form.pristine, formId: this.formId, profileGuid: this.profileGuid } as FormValuesChangedModel;
        setTimeout(() => {
          this.onFormValuesChanged.emit(data);
        })
      } else {
        const data = { model: this.form.getRawValue(), validState: this.form.valid, isPristine: this.form.pristine, formId: this.formId, profileGuid: this.profileGuid } as FormValuesChangedModel;
        setTimeout(() => {
          this.onFormValuesChanged.emit(data);
        })
      }

      this.benificiatySumError = summ !== 100;
    });
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.beneficiariesService.getConfig();
    this.formData = await this.beneficiariesService.get(this.profileGuid);
    this.formId = this.formData?.map((ben: Beneficiary) => ben.beneficiaryId);
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      benificiaries: new FormArray([])
    })
  }

  addNewBenificiar(benificiar?: Beneficiary): void {
    const newFormGroup = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getBeneficiariesFormComponentControl(configItem, this.mode);

      if (controlToBeAdded) {
        newFormGroup.addControl(configItem.name, controlToBeAdded);
      }
    })

    this.benificiaries.push(newFormGroup);

    const data = { model: this.form.getRawValue(), validState: false, isPristine: this.form.pristine, formId: this.formId, profileGuid: this.profileGuid } as FormValuesChangedModel;
    this.onFormValuesChanged.emit(data);
  }

  async deleteBenificiar(index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(DeleteConfirmComponent, {
      class: 'delete-item-confirm-dialog',
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.benificiaries.markAsDirty();
        this.benificiaries.removeAt(index);
      }
    });
  }

  restoreAnswers(): void {
    if (this.isNew) {
      return null;
    } else {
      if (this.formData?.length > 0) {
        const countOfForms = this.formData.length;
        let i = 0;

        while (i < countOfForms) {
          this.addNewBenificiar(this.formData[i]);
          i++;
        }

        const viewModel = benificiariesToViewModel(this.config, this.formData);
        this.benificiaries.patchValue(viewModel);
      }
    }

    this.onFormDataLoaded.next(true);
  }

  get benificiaries(): FormArray {
    return this.form?.get('benificiaries') as FormArray;
  }

  get isNew(): boolean {
    return !this.formData;
  }

  get isEditMode(): boolean {
    return this.mode === FormMode.Edit;
  }

  get isViewMode(): boolean {
    return this.mode === FormMode.View;
  }

}
