import { Pipe, PipeTransform } from '@angular/core';
import { Criterion } from '../data-table-search/data-table-search.component';

@Pipe({
  name: 'criterion'
})
export class CriterionPipe implements PipeTransform {
  transform(id: number, criterions: Criterion[]): any {

    return criterions?.find((c: Criterion) => {
      return c.id === id;
    })?.title;
  }
}
