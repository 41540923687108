<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="row">
    <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.purchasesForm.periodId, config)">
      <app-dropdown-form-control [parentForm]="form" [controlName]="getControlName(formFields.purchasesForm.periodId, config)" [title]="'Période'" [placeholder]="'Sélectionnez une période'">
      </app-dropdown-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.purchasesForm.numberPerPeriod, config)">
      <app-number-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.purchasesForm.numberPerPeriod, config)"
        [title]="numberPerPeriodTitle"
        [placeholder]="'Saisissez le nombre de factures'"
      >
      </app-number-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.purchasesForm.formatId, config)">
      <app-dropdown-form-control [parentForm]="form" [controlName]="getControlName(formFields.purchasesForm.formatId, config)" [title]="'Format des pièces'" [placeholder]="'Sélectionnez un format'">
      </app-dropdown-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-xl-6" *ngIf="isShowControl(formFields.purchasesForm.hasTool, config)">
      <app-radio-group-form-control [parentForm]="form" [controlName]="getControlName(formFields.purchasesForm.hasTool, config)" [title]="hasToolTitle" [inline]="true"> </app-radio-group-form-control>
    </div>
    <div class="col-12 col-xl-6" *ngIf="isSalesManagementToolControl?.value === false && isShowControl(formFields.purchasesForm.isInterested, config)">
      <app-radio-group-form-control
        [parentForm]="form"
        [controlName]="getControlName(formFields.purchasesForm.isInterested, config)"
        [title]="'Êtes-vous intéressé par un outil de ce type ? '"
        [inline]="true"
      >
      </app-radio-group-form-control>
    </div>
  </div>

  <div class="row" *ngIf="isSalesManagementTool && isShowControl(formFields.purchasesForm.managementTools, config)">
    <div class="col-12 col-sm-6 col-md-8 col-xl-9">
      <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.purchasesForm.managementTools, config)" [title]="toolsTitle" [inline]="true">
      </app-checkbox-list-form-control>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3" *ngIf="isOtherTool">
      <app-name-with-digits-form-control [parentForm]="form" [controlName]="'otherTool'" [title]="''" [placeholder]="'Nom de l\'outil'" class="like-no-title-in-mobile">
      </app-name-with-digits-form-control>
    </div>
  </div>
</div>
