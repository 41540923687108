import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, MainTaxes } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { FormValuesChangedModel } from '../../abstract-form';
import { AutocompleteFormControl } from '@controls/autocomplete-form-control/autocomplete-form-control';

function getMainTaxesFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.mainTaxesForm.natureId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.TaxationNatures]
            });
        }
        case formFields.mainTaxesForm.methodId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.TaxationMethods]
            });
        }
        case formFields.mainTaxesForm.currentTaxRegimeId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.CurrentTaxRegimes],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.mainTaxesForm.taxationModeId: {
            return new AutocompleteFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.TaxationModes]
            });
        }
        case formFields.mainTaxesForm.responsibleForVatid: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.ResponsibleForVAT]
            })
        }
        case formFields.mainTaxesForm.tvaNumber: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function mainTaxesFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): any[] {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }
    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
        viewModel[configItem.name] = data[propertyName];
    })

    return viewModel;
}

function mainTaxesViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): MainTaxes {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            objModel[propertyNameForSaveObj] = model[configItem.name];
        })

        objModel.taxMainId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new MainTaxes(objModel);
}

export {
    getMainTaxesFormComponentControl,
    mainTaxesFormToViewModel,
    mainTaxesViewModelToDataModel
}
