import { Injectable } from '@angular/core';
import { AdditionalInfo, AdditionalInfoClient, CompanyInfo } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class AdditionalInfoService extends AbstractFormService<AdditionalInfo> {
  constructor(public webApi: AdditionalInfoClient) {
    super(webApi);
  }

  public async getCompanyInfo(sirenNumber: string | null): Promise<CompanyInfo> {
    return this.webApi.getCompanyInfo(sirenNumber).toPromise();
  }
}
