import { Injectable } from '@angular/core';
import { ResponsibleManagement, ResponsibleManagementClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class ResponsibleManagementService extends AbstractFormService<ResponsibleManagement> {
  constructor(public webApi: ResponsibleManagementClient) {
    super(webApi);
  }
}
