<div class="row" *ngIf="form">
  <div class="col-12 col-md-6" *ngIf="isShowControl(formFields.responsibleManagement.responsibleManagementId, config)">
    <app-dropdown-form-control
      [parentForm]="form"
      [controlName]="getControlName(formFields.responsibleManagement.responsibleManagementId, config)"
      [title]="responsibleManagementIdTitle"
      [placeholder]="'Choisissez une entité'"
    >
    </app-dropdown-form-control>
  </div>
  <div class="col-12 col-md-6" *ngIf="isShowControl(formFields.responsibleManagement.serviceProviderName, config) && isExternalProvider">
    <app-name-with-digits-form-control
      [parentForm]="form"
      [controlName]="getControlName(formFields.responsibleManagement.serviceProviderName, config)"
      [title]="'Nom du prestataire'"
      [placeholder]="'Nom du prestataire'"
    >
    </app-name-with-digits-form-control>
  </div>
  <div class="col-12" *ngIf="isShowControl(formFields.responsibleManagement.socialFeatures, config)">
    <app-info-form-control
      [parentForm]="form"
      [controlName]="getControlName(formFields.responsibleManagement.socialFeatures, config)"
      [title]="'Particularités sociales'"
      [placeholder]="'Si vous souhaitez nous partager plus de détails.'"
    >
    </app-info-form-control>
  </div>
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
</div>
