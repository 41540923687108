<div class="progress-loader" [hidden]="!loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="progress-loader non-blocking" [hidden]="!loadingNonBlocking">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
