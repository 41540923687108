import { LookupTypeId, LookupDataService } from './../../../../../services/lookup-data.service';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { Accounting, DataFieldConfig, ManagementTool } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { InfoFormControl } from '../../../controls/info-form-control/info-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { FormValuesChangedModel } from '../../abstract-form';

function getAccountingFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.accountingForm.accountingEntryId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.AccountingEntry]
            })
        }
        case formFields.accountingForm.frequencyId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.Frequencies]
            })
        }
        case formFields.accountingForm.isIntermediateSituationalPoints: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.accountingForm.howFrequencyId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.Frequencies]
            })
        }
        case formFields.accountingForm.hasAccountingSoftware: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.accountingForm.accountingSoftware: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.AccountingTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.isIntrestedInAccountingSoftware: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.accountingForm.comment: {
            return new InfoFormControl(config.isRequired, isReadOnly, true);
        }

        case formFields.accountingForm.hasBusinessManagementSoftware: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.accountingForm.businessManagementSoftware: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.BusinessManagementSoftwareTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.isIntrestedInBusinessManagementSoftware: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }

        case formFields.accountingForm.useTools: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.WantToUseSoftwareTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.credentialTransferMethods: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.ClassificationModeAccountingParts],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.accountingClassificationMode: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.CommunicationModeAccountingParts],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.archivingAccounts: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.AccountingArchiving],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.formats: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.Formats],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.accountingForm.transmissionFrequencyId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.Frequencies],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }

        default: {
            return null;
        }
    }
}

function accountingFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): any[] {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

        if (configItem.name === formFields.accountingForm.accountingClassificationMode) {
            viewModel[configItem.name] = data.accountingClassificationMode.tools.map((item: any) => { return item.toString() });

            if (data.accountingClassificationMode.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.CommunicationModeAccountingParts].otherId;
                if (otherId) {
                    viewModel[configItem.name].push(otherId.toString());
                    viewModel['otherClassificationModeAccountingParts'] = data.accountingClassificationMode.otherToolName;
                }

            }
        } else if (configItem.name === formFields.accountingForm.accountingSoftware) {
            viewModel[configItem.name] = data.accountingSoftware.tools.map((item: any) => { return item.toString() });

            if (data.accountingSoftware.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.AccountingTools].otherId;
                viewModel[configItem.name].push(otherId.toString());

                viewModel['otherAccountingSoftwareTool'] = data.accountingSoftware.otherToolName;
            }
        } else if (configItem.name === formFields.accountingForm.businessManagementSoftware) {
            viewModel[configItem.name] = data.businessManagementSoftware.tools.map((item: any) => { return item.toString() });

            if (data.businessManagementSoftware.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.BusinessManagementSoftwareTools].otherId;
                viewModel[configItem.name].push(otherId.toString());

                viewModel['otherBusinessManagementSoftwareTool'] = data.businessManagementSoftware.otherToolName;
            }
        } else if (configItem.name === formFields.accountingForm.archivingAccounts ||
            configItem.name === formFields.accountingForm.credentialTransferMethods ||
            configItem.name === formFields.accountingForm.formats ||
            configItem.name === formFields.accountingForm.useTools) {
            viewModel[configItem.name] = data[propertyName]
                .map((item: number) => {
                    return item.toString();
                })
        } else {
            viewModel[configItem.name] = data[propertyName]
        }
    })

    return viewModel;
}

function accountingViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): Accounting {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            if (configItem.name === formFields.accountingForm.accountingSoftware) {
                const tools: number[] = model[formFields.accountingForm.accountingSoftware]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherAccountingSoftwareTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else if (configItem.name === formFields.accountingForm.businessManagementSoftware) {
                const tools: number[] = model[formFields.accountingForm.businessManagementSoftware]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherBusinessManagementSoftwareTool;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else if (configItem.name === formFields.accountingForm.accountingClassificationMode) {
                const tools: number[] = model[formFields.accountingForm.accountingClassificationMode]?.map((id: string) => parseInt(id));
                const otherToolName: string = model.otherClassificationModeAccountingParts;

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else {
                objModel[propertyNameForSaveObj] = model[configItem.name];
            }
        })

        objModel.accountingId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new Accounting(objModel);
}

export {
    getAccountingFormComponentControl,
    accountingFormToViewModel,
    accountingViewModelToDataModel
}
