import { Subject, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { Criterion, SearchItemModel } from 'projects/difference/app/shared/components/data-table/data-table-search/data-table-search.component';
import { Order } from 'projects/difference/webapi/Difference.WebApi';

@Injectable({
    providedIn: 'root'
})

export class BaseEventsService {
    private subject = new Subject<EventsServiceData>();
    private filterConditionsSubject = new Subject<SearchItemModel[]>();
    private filterCriterions: Criterion[];
    public dataWasLoaded: boolean;

    private filters: SearchItemModel[];
    private order: Order[];
    private pagination: Pagination;
    public eventServiceName: string;

    constructor(@Inject(String) eventServiceName: string) {
        this.eventServiceName = eventServiceName;
    }

    public getSubject(): Observable<EventsServiceData> {
        return this.subject.asObservable();
    }

    public onSelected(id: string[]): void {
        this.subject.next({ id, action: EventsServiceAction.Select });
    }

    public onView(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.View });
    }

    public onEdit(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Edit });
    }

    public onDelete(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Delete });
    }

    public onSearch(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Search });
    }

    public onCancel(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Cancel });
    }

    public onApply(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Apply });
    }

    public onCase(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Case });
    }

    public onDetails(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Details });
    }

    public onDownload(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Download });
    }

    public onCalendar(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Calendar });
    }

    public onCitation(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Citation });
    }

    public onLeter(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Leter });
    }

    public onPreview(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.Previw });
    }

    public onCreateQuoteForProspect(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.CreateQuoteForProspect });
    }

    public onShowQuotesForProspect(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.ShowQuotesForProspect });
    }

    public onShowLettersForProspect(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.ShowLettersForProspect });
    }

    public onCreateEngLetterForQuatation(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.CreateEngLetterForQuatation });
    }

    public onAlertNavigateByType(id: number): void {
        this.subject.next({ id, action: EventsServiceAction.AlertNavigateByType });
    }

    public getFilterConditionsSubject(): Observable<SearchItemModel[]> {
        return this.filterConditionsSubject.asObservable();
    }

    public saveFilters(filters: SearchItemModel[]): void {
        localStorage.setItem(this.filterStorageName, JSON.stringify(filters));
        this.filters = filters;
    }

    public getFilters(): SearchItemModel[] {
        if (!this.filters) {
            this.filters = JSON.parse(localStorage.getItem(this.filterStorageName));
        }

        return this.filters;
    }

    public onFilter(filters: SearchItemModel[]): void {
        this.saveFilters(filters);
        this.filterConditionsSubject.next(filters);
    }

    public setFilterCriterions(criterions: Criterion[]): void {
        localStorage.setItem(this.filterCriterionsStorageName, JSON.stringify(criterions));
        this.filterCriterions = criterions;
    }

    public getFilterCriterions(): Criterion[] {
        if (!this.filterCriterions) {
            this.filterCriterions = JSON.parse(localStorage.getItem(this.filterCriterionsStorageName));
        }

        return this.filterCriterions;
    }

    public saveOrder(order: Order[]): void {
        this.order = order;
    }

    public getOrder(): Order[] {
        return this.order;
    }

    public savePagination(pagination: Pagination): void {
        this.pagination = pagination;
    }

    public getPagination(): Pagination {
        return this.pagination;
    }

    public resetPagination(): void {
        this.pagination = {
            start: 0,
            length: this.pagination?.length ? this.pagination.length : 10
        } as Pagination;
    }

    get filterStorageName(): string {
        return this.eventServiceName + '_filters';
    }

    get filterCriterionsStorageName(): string {
        return this.eventServiceName + '_filterCriterions';
    }
}

export class EventsServiceData {
    id: any;
    action: EventsServiceAction;
}

export class Pagination {
    start: number;
    length: number;
}

export enum EventsServiceAction {
    View = 1,
    Edit = 2,
    Delete = 3,
    Search = 4,
    Cancel = 5,
    Apply = 6,
    Case = 7,
    Details = 8,
    Download = 9,
    Calendar = 10,
    Citation = 11,
    Leter = 11,
    Previw = 12,
    Select = 13,
    CreateQuoteForProspect = 14,
    ShowQuotesForProspect = 15,
    ShowLettersForProspect = 16,
    CreateEngLetterForQuatation = 17,
    AlertNavigateByType = 18
}
