import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { ComboBoxFormControl } from './combobox-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-combobox-form-control',
    templateUrl: './combobox-form-control.component.html',
})
export class ComboBoxFormControlComponent extends AbstractFormControlComponent<ComboBoxFormControl> {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;

    faCheck: any = faCheck;

    change(): void {
        this.onChange.emit(this.control.value);
    }

    public get collection(): any[] {
        return this.control.collection || [];
    }

    public get keyPropertyName(): string {
        return this.control.keyPropertyName || 'id';
    }

    public get valuePropertyName(): string {
        return this.control.valuePropertyName || 'id';
    }

    public get tooltiPropertyName(): string {
        return 'tooltip';
    }

    public get isWithTooltip(): boolean {
        return this.control.isWithTooltip;
    }

    public isSelected(value: any): boolean {
        return this.control.value == value;
    }
}
