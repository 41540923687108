import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-error-modal',
  templateUrl: './delete-error-modal.component.html',
  styleUrls: ['./delete-error-modal.component.scss']
})
export class DeleteErrorModalComponent {
  @Input() title: string = 'Suppression impossible';
  @Input() body: string;

  constructor(private bsModalRef: BsModalRef) { }

  continue(): void {
    this.bsModalRef.hide();
  }

}
