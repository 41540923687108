import { BaseEventsService } from 'projects/difference-admin/app/shared/services/base-event-service';
import { Router } from '@angular/router';
import { dataTableEvents } from 'projects/difference-admin/app/shared/constants/data-table-events';

function processCommonMenuItems(eventsService: BaseEventsService, router: Router, event: any) {
    // common
    if (event.target.hasAttribute(dataTableEvents.common.cancel)) {
        eventsService.onCancel(event.target.getAttribute(dataTableEvents.common.cancel));
    }
    if (event.target.hasAttribute(dataTableEvents.common.edit)) {
        eventsService.onEdit(event.target.getAttribute(dataTableEvents.common.edit));
    }
    if (event.target.hasAttribute(dataTableEvents.common.delete)) {
        eventsService.onDelete(event.target.getAttribute(dataTableEvents.common.delete));
    }
    if (event.target.hasAttribute(dataTableEvents.common.search)) {
        eventsService.onSearch(event.target.getAttribute(dataTableEvents.common.search));
    }
    if (event.target.hasAttribute(dataTableEvents.common.apply)) {
        eventsService.onApply(event.target.getAttribute(dataTableEvents.common.apply));
    }
    if (event.target.hasAttribute(dataTableEvents.common.case)) {
        eventsService.onCase(event.target.getAttribute(dataTableEvents.common.case));
    }
    if (event.target.hasAttribute(dataTableEvents.common.details)) {
        eventsService.onDetails(event.target.getAttribute(dataTableEvents.common.details));
    }
    if (event.target.hasAttribute(dataTableEvents.common.download)) {
        eventsService.onDownload(event.target.getAttribute(dataTableEvents.common.download));
    }
    if (event.target.hasAttribute(dataTableEvents.common.calendar)) {
        eventsService.onCalendar(event.target.getAttribute(dataTableEvents.common.calendar));
    }
    if (event.target.hasAttribute(dataTableEvents.common.citation)) {
        eventsService.onCitation(event.target.getAttribute(dataTableEvents.common.citation));
    }
    if (event.target.hasAttribute(dataTableEvents.common.leter)) {
        eventsService.onLeter(event.target.getAttribute(dataTableEvents.common.leter));
    }
    if (event.target.hasAttribute(dataTableEvents.common.previw)) {
        eventsService.onPreview(event.target.getAttribute(dataTableEvents.common.previw));
    }

    // prospects
    if (event.target.hasAttribute(dataTableEvents.prospects.createQuotation)) {
        eventsService.onCreateQuoteForProspect(event.target.getAttribute(dataTableEvents.prospects.createQuotation));
    }
    if (event.target.hasAttribute(dataTableEvents.prospects.showQuotations)) {
        eventsService.onShowQuotesForProspect(event.target.getAttribute(dataTableEvents.prospects.showQuotations));
    }
    if (event.target.hasAttribute(dataTableEvents.prospects.showLetters)) {
        eventsService.onShowLettersForProspect(event.target.getAttribute(dataTableEvents.prospects.showLetters));
    }

    // quatations
    if (event.target.hasAttribute(dataTableEvents.quatations.createEngLetter)) {
        eventsService.onCreateEngLetterForQuatation(event.target.getAttribute(dataTableEvents.quatations.createEngLetter));
    }

    // alerts
    if (event.target.hasAttribute(dataTableEvents.alerts.navigateByType)) {
        eventsService.onAlertNavigateByType(event.target.getAttribute(dataTableEvents.alerts.navigateByType));
    }
}

export {
    processCommonMenuItems
}
