import { FormControl } from '@angular/forms';

export function autocompleteValidator() {
    return (control: FormControl) => {
        const value = control.value;

        if (typeof value === 'string') {
            return { autocompleteValidator: true };
        } else {
            return null
        }

    };
}
