import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { AccountingFirm, ContactTabsModel, DataFieldConfig, GeneralInfo } from 'projects/difference/webapi/Difference.WebApi';
import { AccountingFirmsService } from '../../services/accounting-firms.service';
import { CustomerDataService } from '../../services/customer-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { TabsService } from '../../services/tabs.service';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { contactsMainViewModelToDataModel } from '../../shared/components/forms/contact/contact-main-form/contact-main-form.extensions';
import { GeneralInfoService } from '../../shared/components/forms/contact/contact-main-form/general-info.service';
import { contactsViewModelToDataModel } from '../../shared/components/forms/contact/contact-second-form/contact-second-form.extensions';
import { ContactService } from '../../shared/components/forms/contact/contact-second-form/contact.service';
import { SubscriptionHandler } from '../../shared/components/subscriptionHandler';
import { constants } from '../../shared/constants/constants';
import { formFields } from '../../shared/constants/form-fields';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends AbstractClientTabComponent implements OnInit {
  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  public hasCompany: boolean = true;
  public agreePersonalDataSharing: boolean = false;
  public agreeMail: boolean = false;

  public generalInfo: GeneralInfo;
  public config: DataFieldConfig[];
  public FormMode = FormMode;
  public isMainFormValid: boolean;
  public isContactFormValid: boolean;
  public mainFormChangedModel: FormValuesChangedModel;
  public contactFormChangedModel: FormValuesChangedModel;
  public profileGuid: string;
  public generalInfoFormData: GeneralInfo;

  public accountingFirm: AccountingFirm;
  public companyName: string = '';

  constructor(
    public route: ActivatedRoute,
    public tabsService: TabsService,
    public generalInfoService: GeneralInfoService,
    public contactService: ContactService,
    public progressBarService: ProgressBarService,
    public customerDataService: CustomerDataService,
    public accountingFirmsService: AccountingFirmsService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.General, bsModalService);
  }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.contacts.countOfTheSteps);
    this.menuDataService.onChangeStep(ClientMenuStep.General);
    this.profileGuid = this.customerDataService.userIdValue;
    this.accountingFirm = await this.accountingFirmsService.get();
    this.companyName = this.accountingFirm.name ?? '';
    this.initSubscriptions();
    await this.restoreAnswers();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isContactsValid === undefined) {
        return;
      }

      if (this.isContactsPristine && this.isContactsValid) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isContactsValid) {
        const model = this.prepareModelToSave();
        const response = await this.tabsService.saveContactTab(model);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  async restoreAnswers(): Promise<void> {
    this.generalInfo = new GeneralInfo();
    this.generalInfoFormData = await this.generalInfoService.get(this.profileGuid);

    if (this.generalInfoFormData) {
      this.agreePersonalDataSharing = this.generalInfoFormData.isAcceptGdpr;
      this.agreeMail = this.generalInfoFormData.isAcceptNewsletter;
      this.setHasCompany(this.generalInfoFormData.hasCompany);
    }
  }

  setHasCompany(val: boolean): void {
    this.hasCompany = val;
    this.customerDataService.setHasCompany(val);
  }

  async goToCabinet(event: PointerEvent): Promise<void> {
    event?.preventDefault();

    // todo: navigate
  }

  async submitContactForm(): Promise<void> {
    const model = this.prepareModelToSave();

    const response = await this.tabsService.saveContactTab(model);

    this.menuDataService.onChangeStep(ClientMenuStep.Besoin);
  }

  prepareModelToSave(): ContactTabsModel {
    const extraModel = {
      hasCompany: this.hasCompany,
      isAcceptGdpr: this.agreePersonalDataSharing,
      isAcceptNewsletter: this.agreeMail
    } as ExtraGeneralInfoData;

    return {
      generalInfo: contactsMainViewModelToDataModel(this.generalInfoService.config, this.mainFormChangedModel, extraModel),
      contacts: contactsViewModelToDataModel(this.contactService.config, this.contactFormChangedModel),
      profileGuid: this.profileGuid
    } as ContactTabsModel;
  }

  onMainFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isMainFormValid = data.validState;
    })

    this.mainFormChangedModel = data;

    if (!data.model[formFields.contactMainForm.isCompany]) {
      this.generalInfo = data.model;
    }
  }

  onContactFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isContactFormValid = data.validState;
    })

    this.contactFormChangedModel = data;
  }

  get isContactsValid(): boolean {
    return this.isMainFormValid && this.isContactFormValid;
  }

  get isContactsPristine(): boolean {
    return this.hasCompany === this.generalInfoFormData?.hasCompany && this.mainFormChangedModel.isPristine && this.contactFormChangedModel.isPristine;
  }

}

export class MainDataToBeUpdated {
  civility?: number;
  firstName?: string;
  lastName?: string;
}

export class ExtraGeneralInfoData {
  hasCompany?: boolean;
  isAcceptGdpr?: boolean;
  isAcceptNewsletter?: boolean;
}
