import { Directive, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Directive()
export abstract class ModalComponent {
    @Input() title: string = '';
    @Input() body: string;

    onContinue: Subject<void> = new Subject();
    onCancel: Subject<void> = new Subject();

    constructor(public bsModalRef: BsModalRef) { }

    cancel(): void {
        this.onCancel.next();
        this.onCancel.complete();
        this.bsModalRef.hide();
    }

    continue(): void {
        this.onContinue.next();
        this.onContinue.complete();
        this.bsModalRef.hide();
    }

}
