import { Mission } from './../../../../../webapi/Difference.WebApi';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { DataFieldConfig, Introduction } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from '../../../constants/form-fields';
import { CheckboxFormControl } from '../../controls/checkbox-form-control/checkbox-form-control';
import { DateFormControl } from '../../controls/date-form-control/date-form-control';
import { DropDownFormControl } from '../../controls/dropdown-form-control/dropdown-form-control';
import { InfoFormControl } from '../../controls/info-form-control/info-form-control';
import { NameWithDigitsFormControl } from '../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { NumberFormControl } from '../../controls/number-form-control/number-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { FormValuesChangedModel } from '../abstract-form';
import { AutocompleteFormControl } from '@controls/autocomplete-form-control/autocomplete-form-control';

function getIntroductionFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.introductionForm.nafCode: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.activityStartDate: {
            return new DateFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.activityDescription: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.activityAreaId: {
            return new AutocompleteFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.ActivityAreas],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        case formFields.introductionForm.activityAreaDescription: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.turnover: {
            return new NumberFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.employeesNumber: {
            return new NumberFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.individualsPercentage: {
            return new NumberFormControl(config.isRequired, isReadOnly, null, null, true, { min: 0, max: 100, isPercentages: true })
        }
        case formFields.introductionForm.othersPercentage: {
            return new NumberFormControl(config.isRequired, isReadOnly, null, null, true, { min: 0, max: 100, isPercentages: true })
        }
        case formFields.introductionForm.professionalsPercentage: {
            return new NumberFormControl(config.isRequired, isReadOnly, null, null, true, { min: 0, max: 100, isPercentages: true })
        }
        case formFields.introductionForm.communitiesPercentage: {
            return new NumberFormControl(config.isRequired, isReadOnly, null, null, true, { min: 0, max: 100, isPercentages: true })
        }
        case formFields.introductionForm.customizedMissions: {
            return new DateFormControl(config.isRequired, isReadOnly)
        }
        case formFields.introductionForm.otherMissionRequestDescription: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.missionsStartDate: {
            return new DateFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.isCustomMissionsStartDate: {
            return new CheckboxFormControl(config.isRequired, isReadOnly);
        }
        case formFields.introductionForm.details: {
            return new InfoFormControl(config.isRequired, isReadOnly, true);
        }
        case formFields.introductionForm.availability: {
            return new InfoFormControl(config.isRequired, isReadOnly, true);
        }
    }
}

function introductionFormToViewModel(lookupDataService: LookupDataService, config: DataFieldConfig[], data: any): any[] {
    const viewModel: any = {};

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

        if (configItem.name === formFields.introductionForm.customizedMissions) {
            viewModel.Missions = data[propertyName]?.map((item: Mission) => {
                return item.id.toString();
            })
            data.customizedMissions?.forEach((mission: Mission) => {
                const startDate = data[propertyName].filter((item: any) => {
                    return item.id === mission.id;
                })[0]?.startDate;
                const controlName = getCustomizedMissionsControlNameById(lookupDataService, mission.id);
                viewModel[controlName] = startDate;
            });
        } else {
            viewModel[configItem.name] = data[propertyName]
        }
    })

    return viewModel;
}

function introductionViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel, lookupDataService: LookupDataService): Introduction {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

            if (configItem.name === formFields.introductionForm.customizedMissions) {
                const objModelArray: Mission[] = [];
                model.Missions?.forEach((id: string) => {
                    let missionObjModel: any = {};

                    if (model[formFields.introductionForm.isCustomMissionsStartDate]) {
                        missionObjModel = new Mission({
                            missionId: 0,
                            id: parseInt(id, 10),
                            startDate: getMissionCustomDateByMissionId(id, model, lookupDataService)
                        });
                    } else {
                        missionObjModel = new Mission({
                            missionId: 0,
                            id: parseInt(id, 10),
                            startDate: null
                        });
                    }

                    objModelArray.push(missionObjModel);
                });

                objModel[propertyNameForSaveObj] = objModelArray;
            } else if (configItem.name === formFields.introductionForm.activityStartDate) {
                objModel[propertyNameForSaveObj] = model[configItem.name] ? new Date(model[configItem.name]) : null;
            } else {
                objModel[propertyNameForSaveObj] = model[configItem.name];
            }

        })

        objModel.introductionId = data.formId || null;
        objModel.profileGuid = data.profileGuid;
    }

    return new Introduction(objModel);
}

function getMissionCustomDateByMissionId(id: string, model: any, lookupDataService: LookupDataService): Date {
    const comptabiliteDateControlName = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteDateControlName;
    const socialDateControlName = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialDateControlName;
    const juridicalDateControlName = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].juridicalDateControlName;
    const fiscaliteDateControlName = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].fiscaliteDateControlName;
    const auditLegalDateControlName = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].auditLegalDateControlName;
    const conseilOtherDateControlName = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].conseilOtherDateControlName;

    const comptabiliteId = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;
    const socialId = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialId;
    const juridiqueId = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].juridiqueId;
    const fiscaliteId = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].fiscaliteId;
    const auditLegalId = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].auditLegalId;
    const conseilOtherId = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].conseilOtherId;

    switch (parseInt(id)) {
        case comptabiliteId: {
            return new Date(model[comptabiliteDateControlName]);
        }
        case socialId: {
            return new Date(model[socialDateControlName]);
        }
        case juridiqueId: {
            return new Date(model[juridicalDateControlName]);
        }
        case fiscaliteId: {
            return new Date(model[fiscaliteDateControlName]);
        }
        case auditLegalId: {
            return new Date(model[auditLegalDateControlName]);
        }
        case conseilOtherId: {
            return new Date(model[conseilOtherDateControlName]);
        }
        default: return new Date();
    }
}

function getCustomizedMissionsControlNameById(lookupDataService: LookupDataService, id: number): string { // todo: refactor when api ready
    const collection = lookupDataService.lookupDataObj[LookupTypeId.MissionTypes];
    const item = collection.filter((item: any) => {
        return item.id === id;
    });

    return item?.[0]?.controlName || '';
}

export {
    getIntroductionFormComponentControl,
    introductionFormToViewModel,
    introductionViewModelToDataModel,
    getCustomizedMissionsControlNameById
}
