<div class="row mb-2">
  <div class="col fw-600">
    Grâce à toutes ces informations, nous vous comprenons beaucoup mieux <img src="../../../assets/img/smile-outlined.svg" class="icon-in-description" alt="" /> Ici, nous allons vous demander des
    informations sur votre comptabilité.
  </div>
</div>

<app-organisation-comptable-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onOrganisationComptableFormValuesChanged($event)"></app-organisation-comptable-form>

<div class="row submitCancel">
  <div class="col text-end">
    <button class="btn pink" (click)="goBack()">Précédent</button>
    <button [disabled]="!isAccountingValid" class="btn pink" (click)="submitForm()">Valider</button>
  </div>
</div>
