import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CompanyAgreement, Mission, ResponsibleManagement, Social, SocialTabModel } from 'projects/difference/webapi/Difference.WebApi';
import { CustomerDataService } from '../../services/customer-data.service';
import { LookupDataService, LookupTypeId } from '../../services/lookup-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { TabsService } from '../../services/tabs.service';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { responsibleManagementModelToDataModel } from '../../shared/components/forms/social/social-bottom-form/responsible-management-form.extensions';
import { ResponsibleManagementService } from '../../shared/components/forms/social/social-bottom-form/responsible-management-form.service';
import { socialModelToDataModel } from '../../shared/components/forms/social/social-middle-form/social-form.extensions';
import { SocialService } from '../../shared/components/forms/social/social-middle-form/social-form.service';
import { companyAgreementModelToDataModel } from '../../shared/components/forms/social/social-top-form/company-agreement-form.extensions';
import { CompanyAgreementService } from '../../shared/components/forms/social/social-top-form/company-agreement-form.service';
import { constants } from '../../shared/constants/constants';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent extends AbstractClientTabComponent implements OnInit {
  public FormMode = FormMode;
  public profileGuid: string;
  public isSocialTopFormValid: boolean;
  public socialTopFormChangedModel: FormValuesChangedModel;
  public isSocialMiddleFormValid: boolean;
  public socialMiddleFormChangedModel: FormValuesChangedModel;
  public isSocialButtomFormValid: boolean;
  public socialButtomFormChangedModel: FormValuesChangedModel;

  public comptabiliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;

  constructor(
    public route: ActivatedRoute,
    public progressBarService: ProgressBarService,
    public tabsService: TabsService,
    public customerDataService: CustomerDataService,
    public companyAgreementService: CompanyAgreementService,
    public socialService: SocialService,
    public lookupDataService: LookupDataService,
    public responsibleManagementService: ResponsibleManagementService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Social, bsModalService);
  }

  ngOnInit(): void {
    this.progressBarService.sendStep(constants.steps.social.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isSocialValid === undefined) {
        return;
      }

      if ((this.isSocialPristine && this.isSocialValid)) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isSocialValid) {
        const model = this.prepareModelToSave();
        const response = await this.tabsService.saveSocial(model);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  onSocialTopFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isSocialTopFormValid = data.validState;
    })
    this.socialTopFormChangedModel = data;
  }

  onSocialMiddleFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isSocialMiddleFormValid = data.validState;
    })
    this.socialMiddleFormChangedModel = data;
  }

  onSocialBottomFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isSocialButtomFormValid = data.validState;
    })
    this.socialButtomFormChangedModel = data;
  }

  goBack(): void {
    const customizedMissionsIds = this.customerDataService.introductionModelValue.customizedMissions?.map((m: Mission) => {
      return m.id;
    });

    if (customizedMissionsIds.indexOf(this.comptabiliteId) !== -1) {
      this.menuDataService.onChangeStep(ClientMenuStep.Accounting);
    } else {
      if (this.customerDataService.hasCompanyValue) {
        this.menuDataService.onChangeStep(ClientMenuStep.Interlocutors);
      } else {
        this.menuDataService.onChangeStep(ClientMenuStep.Besoin);
      }
    }
  }

  async submitForm(): Promise<void> {
    const model = this.prepareModelToSave();
    const response = await this.tabsService.saveSocial(model);

    this.menuDataService.onTryToChangeStep(ClientMenuStep.Files);
  }

  prepareModelToSave(): SocialTabModel {
    return {
      companyAgreement: companyAgreementModelToDataModel(this.companyAgreementService.config, this.socialTopFormChangedModel),
      social: socialModelToDataModel(this.socialService.config, this.socialMiddleFormChangedModel),
      responsibleManagement: responsibleManagementModelToDataModel(this.responsibleManagementService.config, this.socialButtomFormChangedModel),
      profileGuid: this.profileGuid
    } as SocialTabModel;
  }

  get isSocialValid(): boolean {
    return this.isSocialTopFormValid && this.isSocialMiddleFormValid && this.isSocialButtomFormValid;
  }

  get isSocialPristine(): boolean {
    return this.socialTopFormChangedModel?.isPristine &&
      this.socialMiddleFormChangedModel?.isPristine &&
      this.socialButtomFormChangedModel?.isPristine;
  }
}

export class SocialCommon {
  socialTop?: CompanyAgreement;
  socialMiddle?: Social;
  socialBottom?: ResponsibleManagement;
}
