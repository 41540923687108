import { Injectable } from '@angular/core';
import { CompanyInfo } from 'projects/difference/webapi/Difference.WebApi';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedCompanyDataService {
  public selectedCompany: any;
  public selectedCompanyExtraInformation: CompanyInfo;
  public selectedCompanyExtraInformation$ = new Subject<CompanyInfo>();

  constructor() { }

  public async clear(): Promise<any> {
    this.selectedCompany = null;
    this.selectedCompanyExtraInformation = null;
  }

  setSelectedCompanyExtraInformation(data: CompanyInfo) {
    this.selectedCompanyExtraInformation = data;
    this.selectedCompanyExtraInformation$.next(data);
  }

}
