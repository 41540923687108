import { Pipe, PipeTransform } from '@angular/core';
import { collections } from '../../../constants/collections';

@Pipe({
  name: 'searchOperator'
})
export class SearchOperatorPipe implements PipeTransform {
  transform(id: number[]): any {

    return collections.allSearchOperatorsCollection.find((operator: any) => {
      return operator.id === id;
    })?.description;
  }
}
