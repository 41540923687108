import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, OtherStreams } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { NumberFormControl } from '../../../controls/number-form-control/number-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { FormValuesChangedModel } from '../../abstract-form';

function getOtherStreamsFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.otherStreamsForm.natureFlex: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.otherStreamsForm.comments: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.otherStreamsForm.periodId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.SalesPeriods]
            });
        }
        case formFields.otherStreamsForm.numberPerPeriod: {
            return new NumberFormControl(config.isRequired, isReadOnly);
        }
        case formFields.otherStreamsForm.formatId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.SalesFormats]
            });
        }
        case formFields.otherStreamsForm.hasManagementTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherStreamsForm.IsIntrestedInManagementTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.otherStreamsForm.toolName: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherStreamsTools],
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function otherStreamsFormToViewModel(config: DataFieldConfig[], dataArr: any): any[] {
    const viewModel: any[] = [];

    dataArr?.forEach((item: any) => {
        const dataObj: any = {};

        config?.forEach((configItem: any) => {
            const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            dataObj[configItem.name] = item[propertyName]
        })

        viewModel.push(dataObj);
    })

    return viewModel;
}

function otherStreamsViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): OtherStreams[] {
    const objModelArray: OtherStreams[] = [];
    const model = data?.model?.otherFlows;

    if (!data || !data.model) {
        return objModelArray;
    }

    model?.forEach((otherStreamsFormModel: any, index: number) => {
        const otherStreamsObjModel: any = {};

        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            otherStreamsObjModel[propertyNameForSaveObj] = otherStreamsFormModel[configItem.name];
        })

        // restore id of the otherStreams
        if (data.formId && data.formId[index]) {
            otherStreamsObjModel.volumeOtherStreamId = data.formId[index];
        }

        otherStreamsObjModel.profileGuid = data.profileGuid;

        objModelArray.push(otherStreamsObjModel);
    })

    return objModelArray;
}

export {
    getOtherStreamsFormComponentControl,
    otherStreamsFormToViewModel,
    otherStreamsViewModelToDataModel
}
