import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DateFormControl } from './date-form-control';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

@Component({
  selector: 'app-date-form-control',
  templateUrl: './date-form-control.component.html'
})
export class DateFormControlComponent extends AbstractFormControlComponent<DateFormControl> implements OnInit {
  @Input() has18YearsOldRestriction: boolean = false;
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  @Input() inline: boolean;

  faCheck: any = faCheck;

  public get isInline(): boolean {
    return this.inline || false;
  }

  @Input() set maxDate(value: Date) {
    if (value) {
      this.control['maxDate'] = new Date(new Date(value).setHours(23, 59, 59, 999));
    } else {
      this.control['maxDate'] = value;
    }
  }

  @Input() set minDate(value: Date) {
    if (value) {
      this.control['minDate'] = new Date(new Date(value).setHours(0, 0, 0, 0));
    } else {
      this.control['minDate'] = value;
    }
  }

  get maxDate(): Date {
    return this.control['maxDate'];
  }

  get minDate(): Date {
    return this.control['minDate'];
  }

  public get hideSuccessMark(): boolean {
    return this.control.hideSuccessMark || false;
  }

  public get hideErrorMessages(): boolean {
    return this.control.hideErrorMessages || false;
  }

  ngOnInit(): void {
    if (this.has18YearsOldRestriction) {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      this.maxDate = date;
    }
  }

  changeDate(event: MatDatepickerInputEvent<Date>): void {
    const date = event.value as Date;

    this.control.setValue(date);
    this.control.markAsTouched();
}

  open(): void {
    if (this.has18YearsOldRestriction && !this.control.value) {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 40);
      this.changeDate({ value: date, target: null, targetElement: null });
    }
  }
}
