import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { constants } from '../../constants/constants';

@Component({
  selector: 'app-simple-tooltip',
  templateUrl: './simple-tooltip.component.html',
  styleUrls: ['./simple-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SimpleTooltipComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() class: string = '';
  @Input() hideDelay: number = constants.tooltipHideDelay;
  @Input() tooltipPosition: string = 'right';
  @Input() escape: boolean = false; // html rendering

  public htmlText = '';

  constructor() { }

  ngOnInit(): void {
    if (this.title && this.text) {
      this.htmlText = `<p class="tooltip-header">${this.title}</b> <br/> <p> ${this.text} </p>`;
    } else if (this.text) {
      this.htmlText = `<br/> <p> ${this.text} </p>`;
    }
  }

}
