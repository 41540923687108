<div class="form-group" [class.required]="isRequired" [class.disabled]="isDisabled" [class.inline]="isInline" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="floatLabelText" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>
    <mat-select
      disableOptionCentering
      [class.is-invalid]="control?.invalid && control.touched"
      (selectionChange)="change($event)"
      class="form-control"
      [placeholder]="placeholderText"
      [formControlName]="controlName"
      [multiple]="multi"
      (opened)="selectOpened(control.value)"
    >
      <mat-option *ngIf="disableChooseOption != true" [value]="null" selected></mat-option>

      <mat-option [value]="item[keyPropertyName]" [id]="item[keyPropertyName]" *ngFor="let item of collection; let i = index" class="dropdown-item-class auto-height">
        {{ item[valuePropertyName] || item }}
      </mat-option>

      <mat-option [value]="item[keyPropertyName]" *ngFor="let item of hiddenOptions" [class.d-none]="true">
        {{ item[valuePropertyName] || item }}
      </mat-option>
    </mat-select>

    <!-- <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <span class="error-mark" *ngIf="control.touched && control.errors && !control.hideErrorMark">
      <fa-icon [icon]="faClose"></fa-icon>
    </span> -->

    <mat-error *ngIf="!control.hideErrorMessages">
      <span *ngIf="control.errors?.required">
        {{ control.requiredErrorMessage }}
      </span>
    </mat-error>
  </mat-form-field>
</div>
