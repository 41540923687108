<div>
  <div class="modal-header" *ngIf="title">
    <h5 class="modal-title" [innerHtml]="title"></h5>
  </div>
  <div class="modal-body" *ngIf="body">
    <p class="confirmation-modal-text" [innerHtml]="body"></p>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="continue()" class="btn pink">Valider</button>
  </div>
</div>
