import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { Bank, DataFieldConfig, OtherStreams } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { FormValuesChangedModel } from '../../abstract-form';

function getBanksFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.banksForm.bankId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.Banks]
            })
        }
        case formFields.banksForm.name: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function banksFormToViewModel(config: DataFieldConfig[], dataArr: any): any[] {
    const viewModel: any[] = [];

    dataArr?.forEach((item: any) => {
        const dataObj: any = {};

        config?.forEach((configItem: any) => {
            const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            dataObj[configItem.name] = item[propertyName]
        })

        viewModel.push(dataObj);
    })

    return viewModel;
}

function banksViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): Bank[] {
    const objModelArray: OtherStreams[] = [];
    const model = data?.model?.banks;

    if (!data || !data.model) {
        return objModelArray;
    }

    model?.forEach((bankFormModel: any, index: number) => {
        const bankObjModel: any = {};

        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            bankObjModel[propertyNameForSaveObj] = bankFormModel[configItem.name];
        })

        // restore id of the bank
        if (data.formId && data.formId[index]) {
            bankObjModel.volumeBankId = data.formId[index];
        }

        bankObjModel.profileGuid = data.profileGuid;

        objModelArray.push(bankObjModel);
    })

    return objModelArray;
}

export {
    getBanksFormComponentControl,
    banksFormToViewModel,
    banksViewModelToDataModel
}
