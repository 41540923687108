import { TrimDirective } from './trim.directive';
import { CapitalizeAllDirective } from './capitalize-all.directive';
import { OnlyLettersDirective } from './only-letters.directive';
import { NgModule } from '@angular/core';
import { NoSymbolsDirective } from './no-symbols.directive';
import { AccessControlDirective } from './access-сontrol.directive';

@NgModule({
  declarations: [
    TrimDirective,
    CapitalizeAllDirective,
    OnlyLettersDirective,
    NoSymbolsDirective,
    AccessControlDirective
  ],
  imports: [
  ],
  exports: [
    TrimDirective,
    CapitalizeAllDirective,
    OnlyLettersDirective,
    NoSymbolsDirective,
    AccessControlDirective
  ],
  providers: [
  ]

})

export class DirectivesModule { }
