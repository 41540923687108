import { ValidatorFn } from '@angular/forms';
import { dayMonthValidator } from '../../../validators/dayMonthValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class DayMonthFormControl extends AbstractFormControl {
    constructor(
        required?: boolean,
        disabled: boolean = false,
        private options?: DayMonthOptions
    ) {
        super(required, disabled);
    }

    protected getValidators(): ValidatorFn[] {
        return [dayMonthValidator()];
    }

}

export interface DayMonthOptions {

}
