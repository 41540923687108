import { Injectable } from '@angular/core';
import { AccountingFirm, AccountingFirmsClient } from 'projects/difference/webapi/Difference.WebApi';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountingFirmsService {
  accountingFirmData: AccountingFirm;

  constructor(
    private webApi: AccountingFirmsClient
  ) { }

  public async get(): Promise<AccountingFirm> {
    if (this.accountingFirmData) {
      return new Promise(resolve => {
        resolve(this.accountingFirmData);
      });
    } else {
      return this.webApi.get().pipe(
        map((response: AccountingFirm) => {
          this.accountingFirmData = response;
          return response;
        })
      ).toPromise();
    }
  }

}
