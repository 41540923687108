import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/difference/environments/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const AUTH_TOKEN_STORAGE_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  readonly authServerUrl: string;
  readonly clientId: string;
  readonly clientSecret: string;

  constructor(private http: HttpClient) {
    this.authServerUrl = environment.authServerUrl;
    // this.clientId = environment.clientId;
    // this.clientSecret = environment.clientSecret;
  }

  public getToken(): string {
    return window.sessionStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  }

  public saveToken(token: string): void {
    this.clearToken();
    window.sessionStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
  }

  public clearToken(): void {
    window.sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  }

  public refreshToken(): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret);

    return this.http.post<TokenResponse>(this.authServerUrl + '/connect/token', body, httpOptions).pipe(
      map(response => {
        return response.access_token;
      }),
      tap((token: string) => {
        this.saveToken(token);
      })
    );
  }
}

interface TokenResponse {
  access_token: string;
}
