import { DropDownFormControl } from 'projects/difference/app/shared/components/controls/dropdown-form-control/dropdown-form-control';
import { LookupDataService, LookupTypeId } from './../../../../../services/lookup-data.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { Accounting, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { AbstractForm } from '../../abstract-form';
import { accountingFormToViewModel, getAccountingFormComponentControl } from './accounting-form.extensions';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { AccountingService } from './accounting-form.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-organisation-comptable-form',
  templateUrl: './organisation-comptable-form.component.html',
  styleUrls: ['./organisation-comptable-form.component.scss']
})
export class OrganisationComptableFormComponent extends AbstractForm<Accounting> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public accountingEntryIdControl: DropDownFormControl;
  public isIntermediateSituationControl: RadioGroupFormControl;
  public parLeCabinetTypeId = this.lookupDataService.lookupDataObj[LookupTypeId.AccountingEntry].parLeCabinetId;
  // second part
  public toolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.AccountingTools].otherId;
  public businessManagementSoftwareToolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.BusinessManagementSoftwareTools].otherId;
  public classificationModeAccountingPartsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.ClassificationModeAccountingParts].otherId;
  public accountingSoftwareToolsControl: CheckboxListFormControl;
  public businessManagementSoftwareToolsControl: CheckboxListFormControl;
  public otherAccountingSoftwareToolControl: NameWithDigitsFormControl;
  public otherBusinessManagementSoftwareToolControl: NameWithDigitsFormControl;
  public isBusinessManagementSoftwareControl: RadioGroupFormControl;
  public isAccountingSoftwareControl: RadioGroupFormControl;
  // third part
  public classificationModeAccountingPartsControl: CheckboxListFormControl;
  public otherClassificationModeAccountingPartsControl: NameWithDigitsFormControl;

  public hasAccountingSoftwareTitle: string = '';
  public hasBusinessManagementSoftwareTitle: string = '';
  public toolsTitle: string = '';
  public howFrequencyIdControl: DropDownFormControl;
  public parLeCabinetTypeSelected: boolean;

  constructor(
    public accountingService: AccountingService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.accountingService.getConfig();
    this.formData = await this.accountingService.get(this.profileGuid);
    this.formId = this.formData?.accountingId;
  }

  hasCompanyOrNotProcessing() {
    this.hasAccountingSoftwareTitle = 'Disposez-vous d’un logiciel comptable ?';
    this.hasBusinessManagementSoftwareTitle = 'Disposez-vous d’un logiciel de gestion d’entreprise ?';
    this.toolsTitle = this.customerDataService.hasCompanyValue ? 'Quel(s) outil(s) utilisez-vous ? ' : 'Quel(s) outil(s) utiliserez-vous ?';
  }

  restoreAnswers(): void {
    const viewModel = accountingFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  initControls(): void {
    this.accountingEntryIdControl = this.form.get(formFields.accountingForm.accountingEntryId) as DropDownFormControl;
    this.isIntermediateSituationControl = this.form.get(formFields.accountingForm.isIntermediateSituationalPoints) as RadioGroupFormControl;
    this.howFrequencyIdControl = this.form.get(formFields.accountingForm.howFrequencyId) as DropDownFormControl;

    this.subscriptionHandler.subscriptions = this.isIntermediateSituationControl.valueChanges.subscribe((value: boolean) => {
      this.howFrequencyIdControl.markRequiredOrNot(value);

      if (value === false) {
        this.howFrequencyIdControl.patchValue(null);
      }
    });

    // second part

    this.isAccountingSoftwareControl = this.form.get(formFields.accountingForm.hasAccountingSoftware) as RadioGroupFormControl;
    this.accountingSoftwareToolsControl = this.form.get(formFields.accountingForm.accountingSoftware) as CheckboxListFormControl;
    this.otherAccountingSoftwareToolControl = this.form.get('otherAccountingSoftwareTool') as NameWithDigitsFormControl;

    this.isBusinessManagementSoftwareControl = this.form.get(formFields.accountingForm.hasBusinessManagementSoftware) as RadioGroupFormControl;
    this.businessManagementSoftwareToolsControl = this.form.get(formFields.accountingForm.businessManagementSoftware) as CheckboxListFormControl;
    this.otherBusinessManagementSoftwareToolControl = this.form.get('otherBusinessManagementSoftwareTool') as NameWithDigitsFormControl;

    this.subscriptionHandler.subscriptions = this.accountingEntryIdControl.valueChanges.subscribe((accountingType: number) => {
      if (accountingType === this.parLeCabinetTypeId) {
        this.parLeCabinetTypeSelected = true;
        this.isAccountingSoftwareControl.markAsNotRequred();
        this.isAccountingSoftwareControl.patchValue(null);
        this.accountingSoftwareToolsControl.patchValue(null);
        setTimeout(() => {
          this.accountingSoftwareToolsControl.markAsValid();
        })
      } else {
        this.parLeCabinetTypeSelected = false;
        this.isAccountingSoftwareControl?.markRequiredOrNot(this.getIsControlRequired(formFields.accountingForm.hasAccountingSoftware));
      }

      this.form.updateValueAndValidity();
    });

    this.subscriptionHandler.subscriptions = this.isAccountingSoftwareControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.accountingSoftwareToolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.accountingSoftwareToolsControl.markAsValid();
        })

        if (value === false) {
          this.form.get(formFields.accountingForm.accountingSoftware).patchValue(null);
          this.otherAccountingSoftwareToolControl.patchValue(null);
          this.accountingSoftwareToolsControl.patchValue(null);
          this.otherAccountingSoftwareToolControl.markAsNotRequred();
          this.form.get(formFields.accountingForm.isIntrestedInAccountingSoftware).patchValue(null);
          this.form.updateValueAndValidity();
        }
      }
    });

    this.subscriptionHandler.subscriptions = this.accountingSoftwareToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherAccountingSoftwareToolControl.markRequiredOrNot(toolsIds && toolsIds?.indexOf(this.toolsOtherId.toString()) !== -1);
      this.form.updateValueAndValidity();
    });

    this.subscriptionHandler.subscriptions = this.isBusinessManagementSoftwareControl.valueChanges.subscribe((value: boolean) => {
      this.businessManagementSoftwareToolsControl.markRequiredOrNot(value);
      setTimeout(() => {
        this.businessManagementSoftwareToolsControl.markAsValid();
      })

      if (value === false) {
        this.form.get(formFields.accountingForm.businessManagementSoftware).patchValue(null);
        this.otherBusinessManagementSoftwareToolControl.patchValue(null);
        this.businessManagementSoftwareToolsControl.patchValue(null);
        this.otherBusinessManagementSoftwareToolControl.markAsNotRequred();
        this.form.get(formFields.accountingForm.isIntrestedInBusinessManagementSoftware).patchValue(null);
        this.form.updateValueAndValidity();
      }
    });

    this.subscriptionHandler.subscriptions = this.businessManagementSoftwareToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherBusinessManagementSoftwareToolControl.markRequiredOrNot(toolsIds && toolsIds?.indexOf(this.businessManagementSoftwareToolsOtherId.toString()) !== -1);
      this.form.updateValueAndValidity();
    });

    // third part 

    this.classificationModeAccountingPartsControl = this.form.get(formFields.accountingForm.credentialTransferMethods) as CheckboxListFormControl;
    this.otherClassificationModeAccountingPartsControl = this.form.get('otherClassificationModeAccountingParts') as NameWithDigitsFormControl;
  }

  initNewFormGroup(): void {
    const isReadOnly = this.mode === FormMode.View;

    this.form = new FormGroup({
      otherAccountingSoftwareTool: new NameWithDigitsFormControl(false, isReadOnly),
      otherBusinessManagementSoftwareTool: new NameWithDigitsFormControl(false, isReadOnly),
      otherClassificationModeAccountingParts: new NameWithDigitsFormControl(false, isReadOnly)
    })

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getAccountingFormComponentControl(configItem, this.mode, this.lookupDataService);

      if (controlToBeAdded) {
        this.form.addControl(configItem.name, controlToBeAdded);
      }
    })
  }

  get isIntermediateSituationTrue(): boolean {
    return this.isIntermediateSituationControl?.value === true;
  }

  get isAccountingSoftwareTrue(): boolean {
    return this.isAccountingSoftwareControl?.value === true;
  }

  get isAccountingSoftwareFalse(): boolean {
    return this.isAccountingSoftwareControl?.value === false;
  }

  get isBusinessManagementSoftwareControlTrue(): boolean {
    return this.isBusinessManagementSoftwareControl?.value === true;
  }

  get isBusinessManagementSoftwareControlFalse(): boolean {
    return this.isBusinessManagementSoftwareControl?.value === false;
  }

  get isOtherAccountingSoftwareTool(): boolean {
    return this.accountingSoftwareToolsControl.value && this.accountingSoftwareToolsControl.value.indexOf(this.toolsOtherId.toString()) !== -1;
  }

  get isOtherBusinessManagementSoftwareTool(): boolean {
    return this.businessManagementSoftwareToolsControl.value && this.businessManagementSoftwareToolsControl.value.indexOf(this.businessManagementSoftwareToolsOtherId.toString()) !== -1;
  }

  get isOtherClassificationModeAccountingParts(): boolean {
    return this.classificationModeAccountingPartsControl.value && this.classificationModeAccountingPartsControl.value.indexOf(this.classificationModeAccountingPartsOtherId.toString()) !== -1;
  }
}
