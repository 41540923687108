import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { AccountingTabModel, Mission } from 'projects/difference/webapi/Difference.WebApi';
import { CustomerDataService } from '../../services/customer-data.service';
import { LookupDataService, LookupTypeId } from '../../services/lookup-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { TabsService } from '../../services/tabs.service';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { accountingViewModelToDataModel } from '../../shared/components/forms/accounting/organisation-comptable-form/accounting-form.extensions';
import { AccountingService } from '../../shared/components/forms/accounting/organisation-comptable-form/accounting-form.service';
import { constants } from '../../shared/constants/constants';

@Component({
  selector: 'app-comptabilite',
  templateUrl: './comptabilite.component.html',
  styleUrls: ['./comptabilite.component.scss']
})
export class ComptabiliteComponent extends AbstractClientTabComponent implements OnInit {
  public FormMode = FormMode;
  public profileGuid: string;
  public isOrganisationComptableFormValid: boolean;
  public organisationComptableFormChangedModel: FormValuesChangedModel;

  public comptabiliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;
  public socialId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialId;

  constructor(
    public route: ActivatedRoute,
    public progressBarService: ProgressBarService,
    public customerDataService: CustomerDataService,
    public lookupDataService: LookupDataService,
    public tabsService: TabsService,
    public accountingService: AccountingService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Accounting, bsModalService);
  }

  ngOnInit(): void {
    this.progressBarService.sendStep(constants.steps.comptabilite.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isAccountingValid === undefined) {
        return;
      }

      if ((this.isAccountingPristine && this.isAccountingValid)) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isAccountingValid) {
        const model = accountingViewModelToDataModel(this.accountingService.config, this.organisationComptableFormChangedModel);
        const response = await this.accountingService.saveAccounting(model);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  goBack(): void {
    const customizedMissionsIds = this.customerDataService.introductionModelValue.customizedMissions?.map((m: Mission) => {
      return m.id;
    });

    if (customizedMissionsIds.indexOf(this.comptabiliteId) !== -1) {
      if (this.customerDataService.hasCompanyValue) {
        this.menuDataService.onChangeStep(ClientMenuStep.Taxes);
      } else {
        this.menuDataService.onChangeStep(ClientMenuStep.Volumes);
      }
    } else {
      if (this.customerDataService.hasCompanyValue) {
        this.menuDataService.onChangeStep(ClientMenuStep.Interlocutors);
      } else {
        this.menuDataService.onChangeStep(ClientMenuStep.Besoin);
      }
    }
  }

  onOrganisationComptableFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isOrganisationComptableFormValid = data.validState;
    })
    this.organisationComptableFormChangedModel = data;
  }

  async submitForm(): Promise<void> {
    const model = this.prepareModelToSave();

    const response = await this.tabsService.saveAccounting(model);

    const customizedMissionsIds = this.customerDataService.introductionModelValue.customizedMissions?.map((m: Mission) => {
      return m.id;
    });

    if (customizedMissionsIds.indexOf(this.socialId) !== -1) {
      this.menuDataService.onChangeStep(ClientMenuStep.Social);
    } else {
      this.menuDataService.onChangeStep(ClientMenuStep.Files);
    }
  }

  prepareModelToSave(): AccountingTabModel {
    return {
      accounting: accountingViewModelToDataModel(this.accountingService.config, this.organisationComptableFormChangedModel),
      profileGuid: this.profileGuid
    } as AccountingTabModel;
  }

  get isAccountingValid(): boolean {
    return this.isOrganisationComptableFormValid;
  }

  get isAccountingPristine(): boolean {
    return this.organisationComptableFormChangedModel?.isPristine;
  }
}
