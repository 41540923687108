import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { SelectedCompanyDataService } from 'projects/difference/app/services/selected-company-data.service';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { CompanyAgreement, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { ToogleFormControl } from '../../../controls/toogle-form-control/toogle-form-control';
import { AbstractForm } from '../../abstract-form';
import { companyAgreementFormToViewModel, getCompanyAgreementFormComponentControl } from './company-agreement-form.extensions';
import { CompanyAgreementService } from './company-agreement-form.service';

@Component({
  selector: 'app-social-top-form',
  templateUrl: './social-top-form.component.html',
  styleUrls: ['./social-top-form.component.scss']
})
export class SocialTopFormComponent extends AbstractForm<CompanyAgreement> implements OnChanges {
  @Input() mode: FormMode;
  public form: FormGroup;
  public conventionTeamControl: NameWithDigitsFormControl;
  public isCompanyAgreementControl: ToogleFormControl;
  public detailsControl: NameWithDigitsFormControl;

  constructor(
    public companyAgreementService: CompanyAgreementService,
    public selectedCompanyDataService: SelectedCompanyDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.initSubscriptions();
      this.restoreAnswers();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.companyAgreementService.getConfig(); 
    this.formData = await this.companyAgreementService.get(this.profileGuid);
    this.formId = this.formData?.socialCompanyAgreementId;
  }

  initControls(): void {
    this.conventionTeamControl = this.form.get(formFields.companyAgreement.conventionTeam) as NameWithDigitsFormControl;
    this.isCompanyAgreementControl = this.form.get(formFields.companyAgreement.isCompanyAgreement) as ToogleFormControl;
    this.detailsControl = this.form.get(formFields.companyAgreement.details) as NameWithDigitsFormControl;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.isCompanyAgreementControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        if (value === false) {
          this.detailsControl.patchValue(null);
        }
      }
    })
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getCompanyAgreementFormComponentControl(configItem, this.mode);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  get isCompanyAgreement(): boolean {
    return this.isCompanyAgreementControl?.value === true;
  }

  restoreAnswers(): void {
    const viewModel = companyAgreementFormToViewModel(this.config, this.formData);
    this.form.patchValue(viewModel);
    this.checkAndRestoreSelectedCompanyData();
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  checkAndRestoreSelectedCompanyData(): void {
    if (this.selectedCompanyDataService.selectedCompanyExtraInformation) {
      if (this.conventionTeamControl.value === undefined || this.conventionTeamControl.value === null) {
        this.conventionTeamControl.patchValue(this.selectedCompanyDataService.selectedCompanyExtraInformation.collectiveConventions[0]?.name);
        this.form.markAsDirty();
      }
      if (this.detailsControl.value === undefined || this.detailsControl.value === null) {
        this.detailsControl.patchValue(this.selectedCompanyDataService.selectedCompanyExtraInformation.collectiveConventions[0]?.idcc);
        this.isCompanyAgreementControl.patchValue(this.selectedCompanyDataService.selectedCompanyExtraInformation.collectiveConventions[0]?.confirmed);
        this.form.markAsDirty();
      }
    }
  }

}
