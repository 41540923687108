import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })

export class PhonePipe implements PipeTransform {
  constructor() { }

  transform(value: string) {
    if (value) {
      return `+33${value}`;
    } else {
      return '-';
    }
  }
}
