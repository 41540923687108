import { MissionType } from './../models/mission-type';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'missionType' })
export class MissionTypePipe implements PipeTransform {
  constructor() { }

  transform(tyoeId: MissionType) {
    return MissionType[tyoeId];
  }
}
