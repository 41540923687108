export enum ClientMenuStep {
    General = 1, // contact
    Besoin = 2, // besoin
    Interlocutors = 3, // info
    Volumes = 4, // volumes
    Taxes = 5, // regime
    Accounting = 6, // comptabilite
    Social = 7, // social
    Files = 8, // files
    Confirmation = 9 // confirmation
}
