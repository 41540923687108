import { Validators } from '@angular/forms';
import { NameFormControl, NameOptions } from '../name-form-control/name-form-control';

export class InfoFormControl extends NameFormControl {
    private caretPosition: number = 0;
    rowsCount: number;
    maxLength: number = 200;

    constructor(required: boolean = false, disabled: boolean = false, private multiple: boolean = true, private opt?: InfoOptions) {
        super(required, disabled, opt);

        const validators = [];
        if (required) {
            validators.push(Validators.required);
        }
        if (opt && opt.minLength) {
            validators.push(Validators.minLength(opt.minLength));
        }
        if (opt && opt.rows) {
            this.rowsCount = opt.rows;
        }
        if (opt && opt.maxLength) {
            this.maxLength = opt.maxLength;
        }

        this.setValidators(validators);
    }

    get isMultiline(): boolean {
        return this.multiple;
    }

    get caretPos(): number {
        return this.caretPosition;
    }

    set caretPos(caretPos: number) {
        this.caretPosition = caretPos;
    }

    get hideErrorMessages(): boolean {
        return this.opt?.hideErrorMessages;
    }

    insertTextAtCursor(value: string): void {
        if (this.value) {
            const newValue = this.value.substring(0, this.caretPos) + value + this.value.substring(this.caretPos);
            this.setValue(newValue);
        } else {
            this.setValue(value);
        }

        this.caretPos = (this.value as string).length;
    }
}

export interface InfoOptions {
    capitalizeAll?: boolean;
    minLength?: number;
    maxLength?: number;
    defaultValue?: any;
    readOnlyCss?: boolean;
    rows?: number;
    hideErrorMessages?: boolean;
}
