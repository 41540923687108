import { Injectable } from '@angular/core';
import { Stakeholder, StakeholdersClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class StakeholdersService extends AbstractFormService<Stakeholder[]> {
  constructor(public webApi: StakeholdersClient) {
    super(webApi);
  }
}
