import { Component, OnInit } from '@angular/core';
import { AccountingFirm } from 'projects/difference/webapi/Difference.WebApi';
import { AccountingFirmsService } from '../../services/accounting-firms.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  accountingFirm: AccountingFirm;
  companyName: string = '';
  email: string = '';

  constructor(
    private accountingFirmsService: AccountingFirmsService
  ) { }

  async ngOnInit() {
    this.accountingFirm = await this.accountingFirmsService.get();
    this.companyName = this.accountingFirm.name ?? '';
    this.email = this.accountingFirm.email ?? '';
  }

  async goToCabinet(event: PointerEvent): Promise<void> {
    event?.preventDefault();

    // todo: navigate
  }

  async goToEmail(event: PointerEvent): Promise<void> {
    event?.preventDefault();

    // todo: navigate
  }

}
