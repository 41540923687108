import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { FormGroup } from '@angular/forms';
import { CompanyNameAutocompleteFormControl } from './company-name-autocomplete-form-control';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-company-name-autocomplete-form-control',
    templateUrl: './company-name-autocomplete-form-control.component.html',
    styleUrls: ['./company-name-autocomplete-form-control.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class CompanyNameAutocompleteFormControlComponent extends AbstractFormControlComponent<CompanyNameAutocompleteFormControl> implements OnInit {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() placeholder: string;
    @Input() inline: boolean;
    @Input() http: HttpClient;
    @Input() selectedCompanyName: HttpClient;
    @Output() onSelected: EventEmitter<any> = new EventEmitter();

    faClose: any = faTimes;

    filteredCompanies: any;
    isLoading = false;
    minLengthTerm = 3;
    selectedCompany: any = '';

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                filter(res => {
                    return res !== null && res.length >= this.minLengthTerm
                }),
                distinctUntilChanged(),
                debounceTime(1000),
                tap(() => {
                    this.filteredCompanies = [];
                    this.isLoading = true;
                }),
                switchMap(searchStr => this.http.get(`https://suggestions.pappers.fr/v2?q=${searchStr}`)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                        })
                    )
                )
            )
            .subscribe((data: any) => {
                if (data['resultats_nom_entreprise'] === undefined) {
                    this.filteredCompanies = [];
                } else {
                    this.filteredCompanies = data['resultats_nom_entreprise'];
                }
            });

        if (this.selectedCompanyName) {
            this.selectedCompany = {
                nom_entreprise: this.selectedCompanyName
            }
        }
    }

    onItemSelected() {
        this.onSelected.emit(this.selectedCompany);
    }

    displayWith(value: any) {
        return value?.nom_entreprise;
    }

    clearSelection() {
        this.control.patchValue('');
        this.filteredCompanies = [];
    }

    public get isInline(): boolean {
        return this.inline || false;
    }

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    public get placeholderText(): string {
        return this.placeholder || this.title;
    }
}
