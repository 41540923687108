import { Directive, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TabChangesModalComponent } from 'projects/difference-admin/app/shared/modals/tab-changes-modal/tab-changes-modal.component';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { SubscriptionHandler } from './subscriptionHandler';

@Directive()
export abstract class AbstractClientTabComponent implements OnDestroy {
    public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor(public clientMenuStep: ClientMenuStep, public bsModalService: BsModalService) {
        this.clientMenuStep = clientMenuStep;
    }

    ngOnDestroy(): void {
        this.subscriptionHandler?.unsubscribeAll();
    }

    public showChangeTabAlert(): void {
        const modal = this.bsModalService.show(TabChangesModalComponent, {
            initialState: {
                body: `Veuillez remplir tous les champs obligatoires et vous assurer d'avoir fourni des données valides avant de changer d'onglet.`
            }
        });

        this.subscriptionHandler.subscriptions = modal.content.onContinue.subscribe((data: any) => { });
    }
}
