<div *ngIf="form">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div *ngFor="let benificiarForm of benificiaries.controls; index as i" class="row">
    <ng-container [formGroup]="benificiarForm">
      <div class="col-12 justify-content-center title-with-del" [class.mt-0]="i === 0">
        <h5>{{ 'Bénéficiaires ' + (i + 1) }}</h5>
        <img *ngIf="isEditMode" src="../../../../../assets/img/delete-icon.svg" class="icon-in-label red" alt="" (click)="deleteBenificiar(i)" />
      </div>

      <div class="col-12 mt-3">
        <app-checkbox-form-control [parentForm]="benificiarForm" [controlName]="getControlName(formFields.beneficiariesForm.isDirect, config)" [title]="'Bénéficiaire direct'">
        </app-checkbox-form-control>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-3">
        <app-name-form-control
          [parentForm]="benificiarForm"
          [controlName]="getControlName(formFields.beneficiariesForm.lastName, config)"
          [title]="'Nom'"
          [placeholder]="'Saisissez le nom du bénéficiare'"
        >
        </app-name-form-control>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <app-name-form-control
          [parentForm]="benificiarForm"
          [controlName]="getControlName(formFields.beneficiariesForm.firstName, config)"
          [title]="'Prénom'"
          [placeholder]="'Saisissez le prénom du bénéficiare'"
        >
        </app-name-form-control>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <app-name-with-digits-form-control
          [parentForm]="benificiarForm"
          [controlName]="getControlName(formFields.beneficiariesForm.address, config)"
          [title]="'Adresse'"
          [placeholder]="'Saisissez l\'adresse du bénéficiare'"
        >
        </app-name-with-digits-form-control>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <app-number-form-control
          [parentForm]="benificiarForm"
          [controlName]="getControlName(formFields.beneficiariesForm.sharesPercentage, config)"
          [title]="'Pourcentage des parts'"
          [placeholder]="'Saisissez un nombre'"
          [postfix]="'%'"
        >
        </app-number-form-control>
      </div>
    </ng-container>
  </div>

  <div class="row mt-3 red-text" *ngIf="benificiatySumError">
    <div class="col">
      {{ collections.messages.benificiatySumError }}
    </div>
  </div>
</div>

<div class="row" *ngIf="isEditMode">
  <div class="col-12 text-end">
    <button class="btn pink w100mobile one-in-col" (click)="addNewBenificiar()">
      <img src="../../../../../assets/img/plus-icon.svg" alt="" />
      Ajouter un bénéficiaire
    </button>
  </div>
</div>
