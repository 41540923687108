import { NameWithDigitsFormControl } from './../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { DeleteConfirmComponent } from '../../../modals/delete-confirm/delete-confirm.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AbstractForm } from '../../abstract-form';
import { OtherToolsService } from './other-tools-form.service';
import { DataFieldConfig, OtherTools, OtherUtils } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { getOtherToolsFormComponentControl, otherToolsFormToViewModel } from './other-tools-form.extensions';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-autres-outils-form',
  templateUrl: './autres-outils-form.component.html',
  styleUrls: ['./autres-outils-form.component.scss']
})
export class AutresOutilsFormComponent extends AbstractForm<OtherTools> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  // TODO: check this part of code. We now have a textfield in the UI, not a dropdown list.
  public toolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.VolumeOtherToolsStockManagement].otherId;
  public electronicInvoicingToolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.ElectronicInvoicingTools].otherId;
  public hasManagementMoneyToolTitle: string = '';
  public hasManagementStocksToolTitle: string = '';
  public hasElectronicInvoicingToolTitle: string = '';
  public toolsTitle: string = '';

  public isManageMoneyToolControl: RadioGroupFormControl;
  public isWantManageMoneyToolControl: RadioGroupFormControl;
  public moneyToolsControl: CheckboxListFormControl;
  public otherMoneyToolControl: NameWithDigitsFormControl;

  public isManageStocksToolControl: RadioGroupFormControl;
  public isWantManageStocksToolControl: RadioGroupFormControl;
  public stocksToolsControl: CheckboxListFormControl;
  public otherStocksToolControl: NameWithDigitsFormControl;

  public isElectronicInvoicingToolControl: RadioGroupFormControl;
  public isWantElectronicInvoicingToolControl: RadioGroupFormControl;
  public electronicInvoicingToolsControl: CheckboxListFormControl;
  public otherElectronicInvoicingToolControl: NameWithDigitsFormControl;

  constructor(
    public bsModalService: BsModalService,
    public otherToolsService: OtherToolsService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.otherToolsService.getConfig();
    this.formData = await this.otherToolsService.get(this.profileGuid);
    this.formId = {
      volumeOtherToolId: this.formData?.volumeOtherToolId,
      volumeOtherUtilIds: this.formData?.otherUtils?.map((ben: OtherUtils) => ben.volumeOtherUtilId)
    };
  }

  hasCompanyOrNotProcessing() {
    this.hasManagementMoneyToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un outil de gestion dédié à la gestion de caisse ?' : 'Disposerez-vous d’un outil de gestion dédié à la gestion de caisse ?';
    this.hasManagementStocksToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un outil de gestion dédié à la gestion des stocks ?' : 'Disposerez-vous d’un outil de gestion dédié à la gestion des stocks ?';
    this.hasElectronicInvoicingToolTitle = this.customerDataService.hasCompanyValue ? 'Utilisez-vous déjà une plateforme de facturation électronique ?' : 'Utiliserez-vous une plateforme de facturation électronique ?';
    this.toolsTitle = this.customerDataService.hasCompanyValue ? 'Quel(s) outil(s) utilisez-vous ? ' : 'Quel(s) outil(s) utiliserez-vous ?';
  }

  restoreAnswers(): void {
    if (this.formData?.otherUtils?.length > 0) {
      const countOfForms = this.formData.otherUtils.length;
      let i = 0;

      while (i < countOfForms) {
        this.addNewUtil(this.formData.otherUtils[i]);
        i++;
      }

    }

    const viewModel = otherToolsFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  initControls(): void {
    this.isManageMoneyToolControl = this.form.get(formFields.otherToolsForm.hasManagementMoneyTool) as RadioGroupFormControl;
    this.isWantManageMoneyToolControl = this.form.get(formFields.otherToolsForm.isIntrestedInManagementMoneyTool) as RadioGroupFormControl;
    this.moneyToolsControl = this.form.get(formFields.otherToolsForm.managementMoneyTools) as CheckboxListFormControl;
    this.otherMoneyToolControl = this.form.get('otherMoneyTool') as NameWithDigitsFormControl;

    this.isManageStocksToolControl = this.form.get(formFields.otherToolsForm.hasManagementStocksTool) as RadioGroupFormControl;
    this.isWantManageStocksToolControl = this.form.get(formFields.otherToolsForm.isIntrestedInManagementStocksTool) as RadioGroupFormControl;
    this.stocksToolsControl = this.form.get(formFields.otherToolsForm.managementStocksTools) as CheckboxListFormControl;
    this.otherStocksToolControl = this.form.get('otherStocksTool') as NameWithDigitsFormControl;

    this.isElectronicInvoicingToolControl = this.form.get(formFields.otherToolsForm.hasElectronicInvoicingTool) as RadioGroupFormControl;
    this.isWantElectronicInvoicingToolControl = this.form.get(formFields.otherToolsForm.isIntrestedInElectronicInvoicingTool) as RadioGroupFormControl;
    this.electronicInvoicingToolsControl = this.form.get(formFields.otherToolsForm.electronicInvoicingTools) as CheckboxListFormControl;
    this.otherElectronicInvoicingToolControl = this.form.get('otherElectronicInvoicingTool') as NameWithDigitsFormControl;

    this.subscriptionHandler.subscriptions = this.isManageMoneyToolControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.moneyToolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.moneyToolsControl.markAsValid();
        })

        this.isWantManageMoneyToolControl.markRequiredOrNot(!value);

        if (value === false) {
          this.isWantManageMoneyToolControl.patchValue(null);
          this.moneyToolsControl.patchValue([]);
          this.otherMoneyToolControl.patchValue(null);
        }
      }
    });

    this.subscriptionHandler.subscriptions = this.moneyToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherMoneyToolControl.markRequiredOrNot(toolsIds?.indexOf(this.toolsOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.isManageStocksToolControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.stocksToolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.stocksToolsControl.markAsValid();
        })

        this.isWantManageStocksToolControl.markRequiredOrNot(!value);

        if (value === false) {
          this.isWantManageStocksToolControl.patchValue(null);
          this.stocksToolsControl.patchValue([]);
          this.otherStocksToolControl.patchValue(null);
        }
      }
    });

    this.subscriptionHandler.subscriptions = this.stocksToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherStocksToolControl.markRequiredOrNot(toolsIds?.indexOf(this.toolsOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.isElectronicInvoicingToolControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.electronicInvoicingToolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.electronicInvoicingToolsControl.markAsValid();
        })

        this.isWantElectronicInvoicingToolControl.markRequiredOrNot(!value);

        if (value === false) {
          this.isWantElectronicInvoicingToolControl.patchValue(null);
          this.electronicInvoicingToolsControl.patchValue([]);
          this.otherElectronicInvoicingToolControl.patchValue(null);
        }
      }
    });

    this.subscriptionHandler.subscriptions = this.electronicInvoicingToolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherElectronicInvoicingToolControl.markRequiredOrNot(toolsIds?.indexOf(this.electronicInvoicingToolsOtherId.toString()) !== -1);
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      otherMoneyTool: new NameWithDigitsFormControl(false, false),
      otherStocksTool: new NameWithDigitsFormControl(false, false),
      otherElectronicInvoicingTool: new NameWithDigitsFormControl(false, false),
      OtherUtils: new FormArray([])
    })

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getOtherToolsFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  get isManageMoneyTool(): boolean {
    return this.isManageMoneyToolControl?.value === true;
  }

  get isManageStocksTool(): boolean {
    return this.isManageStocksToolControl?.value === true;
  }

  get isElectronicInvoicingTool(): boolean {
    return this.isElectronicInvoicingToolControl?.value === true;
  }

  get otherUtils(): FormArray {
    return this.form?.get(formFields.otherToolsForm.otherUtils) as FormArray;
  }

  addNewUtil(otherUtils?: OtherUtils): void {
    const isReadOnly = this.mode === FormMode.View

    this.otherUtils.push(
      new FormGroup({
        description: new NameWithDigitsFormControl(true, isReadOnly)
      })
    )
  }

  async deleteOtherUtils(index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(DeleteConfirmComponent, {
      class: 'delete-item-confirm-dialog',
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.otherUtils.removeAt(index);
      }
    });
  }

  get isOtherMoneyTool(): boolean {
    return this.moneyToolsControl?.value?.indexOf ? this.moneyToolsControl.value?.indexOf(this.toolsOtherId.toString()) !== -1 : false;
  }

  get isOtherStocksTool(): boolean {
    return this.stocksToolsControl?.value && this.stocksToolsControl.value?.indexOf(this.toolsOtherId.toString()) !== -1;
  }

  get isOtherElectronicInvoicingTool(): boolean {
    return this.electronicInvoicingToolsControl?.value && this.electronicInvoicingToolsControl.value?.indexOf(this.electronicInvoicingToolsOtherId.toString()) !== -1;
  }

}
