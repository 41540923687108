import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function matDatepickerMaxValidator() {
  return (control: FormControl) => {
    const date = control.value;
    const maxDate = control.get('maxDate');

    if (date && maxDate) {
      if (moment.utc(date) > moment.utc(maxDate.value)) {
        return {
          matDatepickerMax: true
        };
      } else {
        return null;
      }
    }
    return null;
  };
}
