<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm" [class.inline]="isInline" [class.with-postfix]="isPostfix">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      mask
      [mask]="control.separator"
      decimalMarker=","
      [allowNegativeNumbers]="allowNegativeNumbers"
      thousandSeparator=" "
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      [attr.maxlength]="maxLength"
      class="form-control"
      [formControlName]="controlName"
      [placeholder]="placeholder"
    />
    <span *ngIf="isPostfix" matSuffix> {{ postfix }} </span>
  </mat-form-field>
</div>
