import { ValidatorFn, Validators } from '@angular/forms';
import { phoneValidator } from '../../../validators/phoneValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class PhoneFormControl extends AbstractFormControl {
    validators: any;

    constructor(
        required?: boolean,
        disabled?: boolean,
        private options?: PhoneOptions
    ) {
        super(required, disabled, { updateOn: 'change' });
        this.setValue(null);

        this.updateValidators(required);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    private updateValidators(required: boolean): void {
        const validators = [];

        validators.push(phoneValidator());

        if (required) {
            validators.push(Validators.required);
        }

        this.validators = validators;
        super.setValidators(validators);
    }

    protected getValidators(): ValidatorFn[] {
        return this.validators;
    }

    get hideErrorMark(): boolean {
        return this.options?.hideErrorMark;
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark;
    }

    get phoneTypeText(): string {
        return 'fixe';
    }
}

export interface PhoneOptions {
    readOnlyCss?: boolean;
    hideErrorMark?: boolean;
    hideSuccessMark?: boolean;
}
