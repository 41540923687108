import { Injectable } from '@angular/core';
import { Sales, SalesClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class SalesService extends AbstractFormService<Sales> {
  constructor(public webApi: SalesClient) {
    super(webApi);
  }
}
