import { Component, OnInit, OnDestroy } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { AccountingTabModel, BesoinTabsModel, InterlocutorsTabsModel, Introduction, Mission, SocialTabModel, TaxesTabModel, VolumesTabModel } from 'projects/difference/webapi/Difference.WebApi';
import { CustomerDataService } from '../../services/customer-data.service';
import { LookupDataService, LookupTypeId } from '../../services/lookup-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { SubscriptionHandler } from '../../shared/components/subscriptionHandler';
import { constants } from '../../shared/constants/constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  public ClientMenuSteps = ClientMenuStep;
  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public currentStep: any;
  public userId: string;
  public hasCompany: boolean;
  public customizedMissions: Mission[];
  public customizedMissionsIds: number[] = [];

  public comptabiliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;
  public socialId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialId;
  public introductionModel: Introduction;
  public introductionId: number;

  readonly myMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'exact',
    paths: 'exact',
    fragment: 'exact'
  };

  public besoinTabsModel: BesoinTabsModel;
  public currentClientMenuStep: number;
  interlocutorsTabsModel: InterlocutorsTabsModel;
  volumesTabModel: VolumesTabModel;
  taxesTabModel: TaxesTabModel;
  accountingTabModel: AccountingTabModel;
  socialTabModel: SocialTabModel;

  constructor(
    public progressBarService: ProgressBarService,
    public customerDataService: CustomerDataService,
    public lookupDataService: LookupDataService,
    public menuDataService: MenuDataService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.initSubscriptions();
    this.restoreAnswers();
  }

  restoreAnswers(): void {
    this.hasCompany = this.customerDataService.hasCompanyValue;
    this.besoinTabsModel = this.customerDataService.besoinTabsModel$.getValue();
  }

  onMenuClick(step: number): void {
    this.menuDataService.tryChangeMenuStep$.next(step);
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.onChangeStep$.asObservable().subscribe((step: ClientMenuStep) => {
      if (!this.customerDataService.userIdValue) {
        return null;
      }

      this.currentClientMenuStep = step;

      switch (step) {
        case ClientMenuStep.General: {
          return this.router.navigate(['contact'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Besoin: {
          return this.router.navigate(['besoin'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Interlocutors: {
          return this.router.navigate(['info'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Volumes: {
          return this.router.navigate(['volumes'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Taxes: {
          return this.router.navigate(['regime'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Accounting: {
          return this.router.navigate(['comptabilite'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Social: {
          return this.router.navigate(['social'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Files: {
          return this.router.navigate(['files'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        case ClientMenuStep.Confirmation: {
          return this.router.navigate(['confirmation'], { queryParams: { userId: this.customerDataService.userIdValue } });
        }
        default:
          {
            return this.router.navigate(['contact'], { queryParams: { userId: this.customerDataService.userIdValue } });
          }
      }
    });

    this.subscriptionHandler.subscriptions = this.progressBarService.getStep().subscribe((step: any) => {
      this.currentStep = step.text;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.getUserId().subscribe((quid: string) => {
      this.userId = quid;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.getHasCompany().subscribe((hasCompany: boolean) => {
      this.hasCompany = hasCompany;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.getCustomizedMissionsIds().subscribe((ids: string[]) => {
      this.customizedMissionsIds = ids?.map((id: string) => {
        return parseInt(id);
      });
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.introductionModel.asObservable().subscribe((introductionModel: Introduction) => {
      this.introductionModel = introductionModel;
      this.introductionId = introductionModel.introductionId;

      if (introductionModel.customizedMissions) {
        this.customizedMissionsIds = introductionModel.customizedMissions?.map((m: Mission) => {
          return m.id;
        });
      }
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.besoinTabsModel$.asObservable().subscribe((model: BesoinTabsModel) => {
      this.besoinTabsModel = model;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.interlocutorsTabsModel$.asObservable().subscribe((model: InterlocutorsTabsModel) => {
      this.interlocutorsTabsModel = model;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.volumesTabModel$.asObservable().subscribe((model: VolumesTabModel) => {
      this.volumesTabModel = model;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.taxesTabModel$.asObservable().subscribe((model: TaxesTabModel) => {
      this.taxesTabModel = model;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.accountingTabModel$.asObservable().subscribe((model: AccountingTabModel) => {
      this.accountingTabModel = model;
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.socialTabModel$.asObservable().subscribe((model: SocialTabModel) => {
      this.socialTabModel = model;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  get isContactsInactive(): boolean {
    return false;
  }

  get isContactsViewed(): boolean {
    return this.currentStep >= constants.steps.contacts.countOfTheSteps;
  }

  get isBesoinInactive(): boolean {
    return !this.besoinTabsModel?.introduction?.introductionId;
  }

  get isBesoinViewed(): boolean {
    return this.currentStep >= constants.steps.besoin.countOfTheSteps;
  }

  get isInformationInactive(): boolean {
    return !this.interlocutorsTabsModel?.additionalInfo?.additionalInfoId;
  }

  get isInformationViewed(): boolean {
    return this.currentStep >= constants.steps.information.countOfTheSteps;
  }

  get isInformationShowing(): boolean {
    return this.hasCompany;
  }

  get isVolumesInactive(): boolean {
    return !this.volumesTabModel?.expenses?.volumeExpenseId &&
      !this.volumesTabModel?.otherTools?.volumeOtherToolId &&
      !this.volumesTabModel?.purchases?.volumePurchaseId &&
      !this.volumesTabModel?.sale?.volumeSaleId;
  }

  get isVolumesViewed(): boolean {
    return this.currentStep >= constants.steps.volumes.countOfTheSteps;
  }

  get isVolumesShowing(): boolean {
    return this.customizedMissionsIds && this.customizedMissionsIds?.indexOf(this.comptabiliteId) !== -1; // DIFF-51
  }

  get isRegimeInactive(): boolean {
    return !this.taxesTabModel?.controlCenter?.taxControlCenterId &&
      !this.taxesTabModel?.debDes?.taxDebDesId &&
      !this.taxesTabModel?.featuresTaxation?.taxFeaturesTaxationId;
  }

  get isRegimeViewed(): boolean {
    return this.currentStep >= constants.steps.regime.countOfTheSteps;
  }

  get isRegimeShowing(): boolean {
    return this.hasCompany && this.customizedMissionsIds && this.customizedMissionsIds?.indexOf(this.comptabiliteId) !== -1; // DIFF-64
  }

  get isComptabiliteInactive(): boolean {
    return !this.accountingTabModel?.accounting?.accountingId;
  }

  get isComptabiliteViewed(): boolean {
    return this.currentStep >= constants.steps.comptabilite.countOfTheSteps;
  }

  get isComptabiliteShowing(): boolean {
    return this.customizedMissionsIds && this.customizedMissionsIds?.indexOf(this.comptabiliteId) !== -1;
  }

  get isSocialInactive(): boolean {
    return !this.socialTabModel?.companyAgreement?.socialCompanyAgreementId &&
      !this.socialTabModel?.responsibleManagement?.responsibleManagementId &&
      !this.socialTabModel?.social?.socialId;
  }

  get isSocialViewed(): boolean {
    return this.currentStep >= constants.steps.social.countOfTheSteps;
  }

  get isSocialShowing(): boolean {
    return this.customizedMissionsIds && this.customizedMissionsIds?.indexOf(this.socialId) !== -1;
  }

  get isFilesInactive(): boolean {
    return this.currentStep < constants.steps.files.countOfTheSteps;
  }

  get isFilesViewed(): boolean {
    return this.currentStep >= constants.steps.files.countOfTheSteps;
  }

  get isConfirmationInactive(): boolean {
    return this.currentStep < constants.steps.confirmation.countOfTheSteps;
  }

  get isConfirmationViewed(): boolean {
    return this.currentStep >= constants.steps.confirmation.countOfTheSteps;
  }
}
