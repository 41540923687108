<div class="row mb-2">
  <div class="col fw-600">Voici les dernières questions ! Nous voulons en savoir plus sur votre environnement.</div>
</div>

<div>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
          <h5>Informations générales</h5>
        </div>
      </div>
    </div>
    <div class="card-body">
      <app-social-top-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onSocialTopFormValuesChanged($event)"></app-social-top-form>

      <app-social-middle-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onSocialMiddleFormValuesChanged($event)"></app-social-middle-form>

      <app-social-bottom-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onSocialBottomFormValuesChanged($event)"></app-social-bottom-form>
    </div>
  </div>
</div>

<div class="row submitCancel">
  <div class="col text-end">
    <button class="btn pink" (click)="goBack()">Précédent</button>
    <button [disabled]="!isSocialValid" class="btn pink" (click)="submitForm()">Valider</button>
  </div>
</div>
