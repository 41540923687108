import { Mission } from './../../../webapi/Difference.WebApi';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { BesoinTabsModel } from 'projects/difference/webapi/Difference.WebApi';
import { CustomerDataService } from '../../services/customer-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { TabsService } from '../../services/tabs.service';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { benificiariesViewModelToDataModel } from '../../shared/components/forms/benificiaries-form/benificiaries-form.extensions';
import { BeneficiariesService } from '../../shared/components/forms/benificiaries-form/benificiaries-form.service';
import { introductionViewModelToDataModel } from '../../shared/components/forms/besoin-form/introduction-form.extensions';
import { IntroductionService } from '../../shared/components/forms/besoin-form/introduction.service';
import { constants } from '../../shared/constants/constants';
import { MenuDataService } from '../../services/menu-data.service';
import { SubscriptionHandler } from '../../shared/components/subscriptionHandler';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';

@Component({
  selector: 'app-besoin',
  templateUrl: './besoin.component.html',
  styleUrls: ['./besoin.component.scss']
})
export class BesoinComponent extends AbstractClientTabComponent implements OnInit {
  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  public form: FormGroup;
  public FormMode = FormMode;
  public profileGuid: string;
  public isIntroductionFormValid: boolean;
  public introductionFormChangedModel: FormValuesChangedModel;
  public beneficiariesFormChangedModel: FormValuesChangedModel;
  public isBeneficiariesFormValid: boolean = true;

  public comptabiliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;
  public socialId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialId;

  constructor(
    public route: ActivatedRoute,
    public tabsService: TabsService,
    public introductionService: IntroductionService,
    public beneficiariesService: BeneficiariesService,
    public customerDataService: CustomerDataService,
    public progressBarService: ProgressBarService,
    public lookupDataService: LookupDataService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Besoin, bsModalService);
  }

  ngOnInit(): void {
    this.progressBarService.sendStep(constants.steps.besoin.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.initSubscriptions();
    this.initNewFormGroup();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isBesoinValid === undefined) {
        return;
      }

      if (this.isBesoinPristine && this.isBesoinValid) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isBesoinValid) {
        const model = this.prepareModelToSave();
        const response = await this.tabsService.saveBesoinTab(model);
        this.customerDataService.setIntroductionModelValue(response.introduction);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
    });
  }

  goBack(): void {
    this.menuDataService.onChangeStep(ClientMenuStep.General);
  }

  prepareModelToSave(): BesoinTabsModel {
    return {
      introduction: introductionViewModelToDataModel(this.introductionService.config, this.introductionFormChangedModel, this.lookupDataService),
      beneficiaries: benificiariesViewModelToDataModel(this.beneficiariesService.config, this.beneficiariesFormChangedModel),
      profileGuid: this.profileGuid
    } as BesoinTabsModel;
  }

  async submitForm(): Promise<void> {
    const model = this.prepareModelToSave();
    const response = await this.tabsService.saveBesoinTab(model);

    this.customerDataService.setIntroductionModelValue(response.introduction);

    if (this.customerDataService.hasCompanyValue) {
      this.menuDataService.onChangeStep(ClientMenuStep.Interlocutors);
    } else {
      const customizedMissionsIds = response.introduction.customizedMissions?.map((m: Mission) => {
        return m.id;
      })

      if (customizedMissionsIds.indexOf(this.comptabiliteId) !== -1) {
        this.menuDataService.onChangeStep(ClientMenuStep.Volumes);
      } else if (customizedMissionsIds.indexOf(this.socialId) !== -1) {
        this.menuDataService.onChangeStep(ClientMenuStep.Social);
      } else {
        this.menuDataService.onChangeStep(ClientMenuStep.Files);
      }
    }
  }

  onIntroductionFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isIntroductionFormValid = data.validState;
    })
    this.introductionFormChangedModel = data;
  }

  onBeneficiariesFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isBeneficiariesFormValid = data.validState;
    })
    this.beneficiariesFormChangedModel = data;
  }

  get isBesoinValid(): boolean {
    return this.isIntroductionFormValid && this.isBeneficiariesFormValid;
  }

  get isBesoinPristine(): boolean {
    return this.introductionFormChangedModel?.isPristine && this.beneficiariesFormChangedModel?.isPristine;
  }
}
