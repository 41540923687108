import { Injectable } from '@angular/core';
import { OtherStreams, OtherStreamsClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class OtherStreamsService extends AbstractFormService<OtherStreams[]> {
  constructor(public webApi: OtherStreamsClient) {
    super(webApi);
  }
}
