import { NameWithDigitsFormControl } from 'projects/difference/app/shared/components/controls/name-with-digits-form-control/name-with-digits-form-control';
import { NameFormControl } from './../../../controls/name-form-control/name-form-control';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { DataFieldConfig, ResponsibleManagement } from 'projects/difference/webapi/Difference.WebApi';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { AbstractForm } from '../../abstract-form';
import { getResponsibleManagementFormComponentControl, responsibleManagementFormToViewModel } from './responsible-management-form.extensions';
import { ResponsibleManagementService } from './responsible-management-form.service';

@Component({
  selector: 'app-social-bottom-form',
  templateUrl: './social-bottom-form.component.html',
  styleUrls: ['./social-bottom-form.component.scss']
})
export class SocialBottomFormComponent extends AbstractForm<ResponsibleManagement> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public responsibleManagementIdTitle: string = '';
  public externalProviderTypeId = this.lookupDataService.lookupDataObj[LookupTypeId.Persons].externalProviderId;
  public isExternalProvider: boolean = false;
  public responsibleManagementControl: DropDownFormControl;
  public serviceProviderNameControl: NameWithDigitsFormControl;

  constructor(
    public responsibleManagementService: ResponsibleManagementService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  initControls(): void {
    this.responsibleManagementControl = this.form.get(this.formFields.responsibleManagement.responsibleManagementId) as DropDownFormControl;
    this.serviceProviderNameControl = this.form.get(this.formFields.responsibleManagement.serviceProviderName) as NameWithDigitsFormControl;

    this.subscriptionHandler.subscriptions = this.responsibleManagementControl.valueChanges.subscribe((id: string) => {
      this.isExternalProvider = id === this.externalProviderTypeId;
      this.serviceProviderNameControl.markRequiredOrNot(this.isExternalProvider);

      if (!this.isExternalProvider) {
        this.serviceProviderNameControl.patchValue(null);
      }
    });
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.responsibleManagementService.getConfig();
    this.formData = await this.responsibleManagementService.get(this.profileGuid);
    this.formId = this.formData?.socialResponsibleManagementId;
  }

  hasCompanyOrNotProcessing() {
    this.responsibleManagementIdTitle = this.customerDataService.hasCompanyValue ? 'Qui se charge de la gestion de paie (si ce n’est pas le cabinet comptable) ?' : 'Qui se chargera de la gestion de paie ?';
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getResponsibleManagementFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  restoreAnswers(): void {
    const viewModel = responsibleManagementFormToViewModel(this.config, this.formData);
    this.form.patchValue(viewModel);
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

}
