import { NameWithDigitsFormControl } from './../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { DataFieldConfig, Expenses } from 'projects/difference/webapi/Difference.WebApi';
import { ExpensesService } from './expenses.service';
import { AbstractForm } from '../../abstract-form';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { adminConstants } from 'projects/difference-admin/app/shared/constants/constants';
import { expensesFormToViewModel, getExpensesFormComponentControl } from './expenses-form.extensions';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-notes-de-frais-form',
  templateUrl: './notes-de-frais-form.component.html',
  styleUrls: ['./notes-de-frais-form.component.scss']
})
export class NotesDeFraisFormComponent extends AbstractForm<Expenses> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public toolsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.VolumeExpensesTools].otherId;
  public toolsControl: CheckboxListFormControl;
  public otherToolControl: NameWithDigitsFormControl;
  public isSalesManagementToolControl: RadioGroupFormControl;
  public isWantSalesManagementToolControl: RadioGroupFormControl;
  public numberPerPeriodTitle: string = '';
  public hasToolTitle: string = '';
  public toolsTitle: string = '';

  constructor(
    public expensesService: ExpensesService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.expensesService.getConfig(); 
    this.formData = await this.expensesService.get(this.profileGuid);
    this.formId = this.formData?.volumeExpenseId;
  }

  hasCompanyOrNotProcessing() {
    this.numberPerPeriodTitle = this.customerDataService.hasCompanyValue ? 'Nombre de pièces par période' : 'Nombre de pièces prévisionnel par période (si connu)';
    this.hasToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un outil de gestion des notes de frais ? ' : 'Disposerez-vous d’un outil de gestion des notes de frais ?';
    this.toolsTitle = this.customerDataService.hasCompanyValue ? 'Quel(s) outil(s) utilisez-vous ? ' : 'Quel(s) outil(s) utiliserez-vous ?';
  }

  restoreAnswers(): void {
    const viewModel = expensesFormToViewModel(this.config, this.formData, this.lookupDataService);
    this.form.patchValue(viewModel);
    this.onFormDataLoaded.next(true);
  }

  initControls(): void {
    this.isSalesManagementToolControl = this.form.get(formFields.expensesForm.hasTool) as RadioGroupFormControl;
    this.isWantSalesManagementToolControl = this.form.get(formFields.expensesForm.isInterested) as RadioGroupFormControl;
    this.toolsControl = this.form.get(formFields.expensesForm.managementTools) as CheckboxListFormControl;
    this.otherToolControl = this.form.get(adminConstants.otherToolControlName) as NameWithDigitsFormControl;

    this.subscriptionHandler.subscriptions = this.toolsControl.valueChanges.subscribe((toolsIds: string[]) => {
      this.otherToolControl.markRequiredOrNot(toolsIds?.indexOf(this.toolsOtherId.toString()) !== -1);
    });

    this.subscriptionHandler.subscriptions = this.isSalesManagementToolControl.valueChanges.subscribe((value: boolean) => {
      if (value !== undefined) {
        this.toolsControl.markRequiredOrNot(value);
        setTimeout(() => {
          this.toolsControl.markAsValid();
        })

        this.isWantSalesManagementToolControl.markRequiredOrNot(!value);

        if (value === false) {
          this.isWantSalesManagementToolControl.patchValue(null);
          this.toolsControl.patchValue([]);
          this.otherToolControl.patchValue(null);
        }
      }
    });

    this.form.markAsPristine();
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      otherTool: new NameWithDigitsFormControl(false, false)
    })

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getExpensesFormComponentControl(configItem, this.mode, this.lookupDataService);

      this.form.addControl(configItem.name, controlToBeAdded);
    })
  }

  get isSalesManagementTool(): boolean {
    return this.isSalesManagementToolControl?.value === true;
  }

  get isOtherTool(): boolean {
    return this.toolsControl.value && this.toolsControl.value.indexOf(this.toolsOtherId.toString()) !== -1;
  }

}
