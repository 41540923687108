<div *ngIf="form">
  <div class="row mb-2">
    <div class="col fw-600">Passons à vos interlocuteurs habituels.</div>
  </div>

  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-sm-8 col-md-8 col-lg-9 align-self-center title-col">
          <h5>Informations sur l'entité</h5>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 m-0"></div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <table>
            <tr>
              <td class="top-align">
                <span class="info-icon">
                  <img src="../../../assets/img/company-icon.svg" alt="" />
                </span>
              </td>
              <td class="top-align">
                <tr>
                  <td>
                    <span class="info-title"> Nom de l'entreprise </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="info-text"> {{ commercialName }} </span>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <table>
            <tr>
              <td class="top-align">
                <span class="info-icon">
                  <img src="../../../assets/img/address-icon.svg" alt="" />
                </span>
              </td>
              <td class="top-align">
                <tr>
                  <td>
                    <span class="info-title"> Adresse </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="info-text"> {{ address }}</span>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <table>
            <tr>
              <td class="top-align">
                <span class="info-icon">
                  <img src="../../../assets/img/group-icon.svg" alt="" />
                </span>
              </td>
              <td class="top-align">
                <tr>
                  <td>
                    <span class="info-title"> Nombre de salariés </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="info-text"> {{ employeesNumber }}</span>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <table>
            <tr>
              <td class="top-align">
                <span class="info-icon">
                  <img src="../../../assets/img/activity-icon.svg" alt="" />
                </span>
              </td>
              <td class="top-align">
                <tr>
                  <td>
                    <span class="info-title"> Activité </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="info-text"> {{ activityDescription }} </span>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <table>
            <tr>
              <td class="top-align">
                <span class="info-icon">
                  <img src="../../../assets/img/calendar-icon.svg" alt="" />
                </span>
              </td>
              <td class="top-align">
                <tr>
                  <td>
                    <span class="info-title"> Date de début d'activité </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="info-text"> {{ activityStartDate }} </span>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <table>
            <tr>
              <td class="top-align">
                <span class="info-icon">
                  <img src="../../../assets/img/cube-icon.svg" alt="" />
                </span>
              </td>
              <td class="top-align">
                <tr>
                  <td>
                    <span class="info-title"> Type de mission </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="info-text"> {{ missions }}</span>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-sm-8 col-md-8 col-lg-9 align-self-center title-col">
          <h5>En savoir plus</h5>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 m-0"></div>
      </div>
    </div>
    <div class="card-body">
      <app-info-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onAdditionalInfoFormValuesChanged($event)"></app-info-form>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-sm-8 col-md-8 col-lg-9 align-self-center title-col">
          <h5>Autre(s) intervenant(s)</h5>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 m-0"></div>
      </div>
    </div>
    <div class="card-body">
      <app-info-other-form [mode]="FormMode.Edit" [profileGuid]="profileGuid" (onFormValuesChanged)="onStakeholdersFormValuesChanged($event)"></app-info-other-form>
    </div>
  </div>

  <div class="row submitCancel">
    <div class="col text-end">
      <button class="btn pink" (click)="goBack()">Précédent</button>
      <button [disabled]="!isInfoFormsValid" class="btn pink" (click)="submitForm()">Valider</button>
    </div>
  </div>
</div>
