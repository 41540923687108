import { NameWithDigitsFormControl } from './../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { Beneficiary } from './../../../../../webapi/Difference.WebApi';
import { DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from '../../../constants/form-fields';
import { CheckboxFormControl } from '../../controls/checkbox-form-control/checkbox-form-control';
import { NameFormControl } from '../../controls/name-form-control/name-form-control';
import { NumberFormControl } from '../../controls/number-form-control/number-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { FormValuesChangedModel } from '../abstract-form';

function getBeneficiariesFormComponentControl(config: DataFieldConfig, mode: FormMode): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.beneficiariesForm.isDirect: {
            return new CheckboxFormControl(config.isRequired, isReadOnly);
        }
        case formFields.beneficiariesForm.address: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
        case formFields.beneficiariesForm.firstName: {
            return new NameFormControl(config.isRequired, isReadOnly);
        }
        case formFields.beneficiariesForm.lastName: {
            return new NameFormControl(config.isRequired, isReadOnly);
        }
        case formFields.beneficiariesForm.sharesPercentage: {
            return new NumberFormControl(config.isRequired, isReadOnly, null, null, true, { min: 0, max: 100, isPercentages: true })
        }
    }
}

function benificiariesToViewModel(config: DataFieldConfig[], beneficiaries: Beneficiary[]) {
    const viewModel: any[] = [];

    beneficiaries?.forEach((beneficiary: any) => {
        const benObj: any = {};

        config?.forEach((configItem: any) => {
            const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            benObj[configItem.name] = beneficiary[propertyName]
        })

        viewModel.push(benObj);
    })

    return viewModel;
}

function benificiariesViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): Beneficiary[] {
    const objModelArray: Beneficiary[] = [];
    const model = data?.model?.benificiaries;

    if (!data || !data.model) {
        return objModelArray;
    }

    model?.forEach((beneficiaryFormModel: any, index: number) => {
        const beneficiaryObjModel: any = {};

        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            beneficiaryObjModel[propertyNameForSaveObj] = beneficiaryFormModel[configItem.name];
        })

        // restore id of the beneficiary
        if (data.formId && data.formId[index]) {
            beneficiaryObjModel.beneficiaryId = data.formId[index];
        }

        beneficiaryObjModel.profileGuid = data.profileGuid;

        objModelArray.push(beneficiaryObjModel);
    })

    return objModelArray;
}

export {
    getBeneficiariesFormComponentControl,
    benificiariesToViewModel,
    benificiariesViewModelToDataModel
}
