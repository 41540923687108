
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountingFirmsService } from 'projects/difference/app/services/accounting-firms.service';
import { AccountingFirm, GetUser } from 'projects/difference/webapi/Difference.WebApi';
import { AuthService } from './auth/auth.service';
import { Role } from './auth/role.enum';
import { UsersService } from './services/users.service';
import { SidebarService } from './shared/services/sidebar.service';
import * as version from '../../difference/environments/versions';


@Component({
  selector: 'app-root-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  public isExpanded = false;
  public isShowing = false;
  public isLoggedIn: boolean;

  public showDashboardSubmenu: boolean = false;
  public showDevisSubmenu: boolean = false;
  public showLettreMissionSubmenu: boolean = false;

  public accountingFirm: AccountingFirm;
  public logoPath: any;
  public user: GetUser;
  Role = Role;

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private accountingFirmsService: AccountingFirmsService,
    private sanitizer: DomSanitizer,
    private usersService: UsersService
  ) {
    this.sidebarService.collapseAll$.subscribe((collapse: boolean) => {
      this.noSubmenuItemClick();
    });
  }

  async ngOnInit(): Promise<void> {
    this.accountingFirm = await this.accountingFirmsService.get();
    this.logoPath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.accountingFirm.logo);

    this.authService.isAuthenticated().subscribe(async isAuthenticated => {
      this.isLoggedIn = isAuthenticated;
      if (isAuthenticated && this.user === undefined) {
        this.user = await this.usersService.me();
      }
    });

    console.log('version: ' + JSON.stringify(version))
  }

  noSubmenuItemClick(): void {
    this.showDevisSubmenu = false;
    this.showLettreMissionSubmenu = false;
    this.showDashboardSubmenu = false;
  }

  showDashboardSubmenuClick(): void {
    if (!this.showDashboardSubmenu) {
      this.showDevisSubmenu = false;
      this.showLettreMissionSubmenu = false;
    }

    this.showDashboardSubmenu = !this.showDashboardSubmenu
  }

  showLettreMissionSubmenuClick(): void {
    if (!this.showLettreMissionSubmenu) {
      this.showDashboardSubmenu = false;
      this.showDevisSubmenu = false;
    }

    this.showLettreMissionSubmenu = !this.showLettreMissionSubmenu
  }

  showDevisSubmenuClick(event: any): void {
    event.stopPropagation();
    if (!this.showDevisSubmenu) {
      this.showLettreMissionSubmenu = false;
      this.showDevisSubmenu = false;
    }

    this.showDevisSubmenu = !this.showDevisSubmenu
  }

  mouseenter(): void {
    this.isShowing = true;
  }

  mouseleave(): void {
    this.isShowing = false;
    // this.showDashboardSubmenu = false;
    // this.showDevisSubmenu = false;
    // this.showLettreMissionSubmenu = false;
  }

  onSubSidenavClick(event: any) {
    event.stopPropagation();
  }

}
