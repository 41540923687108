import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientMenuStep } from 'projects/difference-admin/app/shared/models/client-menu-steps';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { ControlCenter, DebDes, MainTaxes, TaxesTabModel } from 'projects/difference/webapi/Difference.WebApi';
import { CustomerDataService } from '../../services/customer-data.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { TabsService } from '../../services/tabs.service';
import { AbstractClientTabComponent } from '../../shared/components/abstract-client-tab-component';
import { FormValuesChangedModel } from '../../shared/components/forms/abstract-form';
import { controlCenterViewModelToDataModel } from '../../shared/components/forms/taxes/control-center-form/control-center-form.extensions';
import { ControlCenterService } from '../../shared/components/forms/taxes/control-center-form/control-center-form.service';
import { debDesViewModelToDataModel } from '../../shared/components/forms/taxes/deb-des-form/deb-des-form.extensions';
import { DebDesService } from '../../shared/components/forms/taxes/deb-des-form/deb-des-form.service';
import { featuresTaxationViewModelToDataModel } from '../../shared/components/forms/taxes/features-of-taxation-form/features-taxation-form.extensions';
import { FeaturesTaxationService } from '../../shared/components/forms/taxes/features-of-taxation-form/features-taxation-form.service';
import { mainTaxesViewModelToDataModel } from '../../shared/components/forms/taxes/main-taxes-form/main-taxes-form.extensions';
import { MainTaxesService } from '../../shared/components/forms/taxes/main-taxes-form/main-taxes-form.service';
import { constants } from '../../shared/constants/constants';

@Component({
  selector: 'app-regime',
  templateUrl: './regime.component.html',
  styleUrls: ['./regime.component.scss']
})
export class RegimeComponent extends AbstractClientTabComponent implements OnInit {
  public FormMode = FormMode;
  public profileGuid: string;
  public isMainTaxesFormValid: boolean;
  public mainTaxesFormChangedModel: FormValuesChangedModel;
  public isDebDesFormValid: boolean;
  public debDesFormChangedModel: FormValuesChangedModel;
  public isControlCenterFormValid: boolean;
  public controlCenterFormChangedModel: FormValuesChangedModel;
  public isFeaturesOfTaxationFormValid: boolean;
  public featuresOfTaxationFormChangedModel: FormValuesChangedModel;

  constructor(
    public route: ActivatedRoute,
    public progressBarService: ProgressBarService,
    public customerDataService: CustomerDataService,
    public tabsService: TabsService,
    public mainTaxesService: MainTaxesService,
    public debDesService: DebDesService,
    public controlCenterService: ControlCenterService,
    public featuresTaxationService: FeaturesTaxationService,
    public menuDataService: MenuDataService,
    public bsModalService: BsModalService
  ) {
    super(ClientMenuStep.Taxes, bsModalService);
  }

  ngOnInit(): void {
    this.progressBarService.sendStep(constants.steps.regime.countOfTheSteps);
    this.menuDataService.onChangeStep(this.clientMenuStep);
    this.profileGuid = this.route.snapshot.queryParamMap.get('userId');
    this.customerDataService.setUserId(this.profileGuid);
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.menuDataService.tryChangeMenuStep$.asObservable().subscribe(async (step: ClientMenuStep) => {
      if (!step || step === this.clientMenuStep || this.isTaxesValid === undefined) {
        return;
      }

      if ((this.isTaxesPristine && this.isTaxesValid)) {
        return this.menuDataService.onChangeStep(step);
      }

      if (this.isTaxesValid) {
        const model = this.prepareModelToSave();
        const response = await this.tabsService.saveTaxes(model);
        this.menuDataService.onChangeStep(step);
      } else {
        this.showChangeTabAlert();
      }
    });
  }

  goBack(): void {
    this.menuDataService.onChangeStep(ClientMenuStep.Volumes);
  }

  onMainTaxesFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isMainTaxesFormValid = data.validState;
    })
    this.mainTaxesFormChangedModel = data;
  }

  onDebDesFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isDebDesFormValid = data.validState;
    })
    this.debDesFormChangedModel = data;
  }

  onControlCenterFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isControlCenterFormValid = data.validState;
    })
    this.controlCenterFormChangedModel = data;
  }

  onFeaturesOfTaxationFormValuesChanged(data: FormValuesChangedModel): void {
    setTimeout(() => {
      this.isFeaturesOfTaxationFormValid = data.validState;
    })
    this.featuresOfTaxationFormChangedModel = data;
  }

  get isTaxesValid(): boolean {
    return this.isMainTaxesFormValid && this.isDebDesFormValid && this.isControlCenterFormValid && this.isFeaturesOfTaxationFormValid;
  }

  get isTaxesPristine(): boolean {
    return this.mainTaxesFormChangedModel?.isPristine &&
      this.debDesFormChangedModel?.isPristine &&
      this.controlCenterFormChangedModel?.isPristine &&
      this.featuresOfTaxationFormChangedModel?.isPristine;
  }

  async submitForm(): Promise<void> {
    const model = this.prepareModelToSave();
    const response = await this.tabsService.saveTaxes(model);
    this.menuDataService.onChangeStep(ClientMenuStep.Accounting);
  }

  prepareModelToSave(): TaxesTabModel {
    return {
      mainTaxes: mainTaxesViewModelToDataModel(this.mainTaxesService.config, this.mainTaxesFormChangedModel),
      debDes: debDesViewModelToDataModel(this.debDesService.config, this.debDesFormChangedModel),
      controlCenter: controlCenterViewModelToDataModel(this.controlCenterService.config, this.controlCenterFormChangedModel),
      featuresTaxation: featuresTaxationViewModelToDataModel(this.featuresTaxationService.config, this.featuresOfTaxationFormChangedModel),
      profileGuid: this.profileGuid
    } as TaxesTabModel;
  }
}

export class Taxes {
  mainTaxes?: MainTaxes;
  debDes?: DebDes;
  controlCenter?: ControlCenter;
}
