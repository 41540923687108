import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'projects/difference/environments/environment';

@Injectable()
export class PublicAuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private tokenSubject = new BehaviorSubject<any>(null);

  constructor(private tokenStorageService: TokenStorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if (this.isAuthRequest(request.url)) {
    //   return next.handle(request);
    // }

    // const accessToken = this.tokenStorageService.getToken();
    // if (accessToken) {
    //   return next.handle(this.addTokenToRequest(request, accessToken)).pipe(catchError(error => {
    //     if (error instanceof HttpErrorResponse && error.status === 401) {
    //       return this.requestToken(request, next);
    //     }
    //     return throwError(error);
    //   })
    //   );
    // }

    return this.requestToken(request, next);
  }

  private requestToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request);
    // if (!this.isRefreshing) {
    //   this.isRefreshing = true;
    //   this.tokenSubject.next(null);
    //   return this.tokenStorageService.refreshToken().pipe(
    //     switchMap((token: string) => {
    //       this.isRefreshing = false;

    //       this.tokenSubject.next(token);
    //       return next.handle(this.addTokenToRequest(request, token));
    //     }),
    //     catchError((error) => {
    //       this.isRefreshing = false;
    //       this.tokenStorageService.clearToken();
    //       return throwError(error);
    //     })
    //   );

    // } else {
    //   return this.tokenSubject.pipe(
    //     filter(token => token != null),
    //     take(1),
    //     switchMap(token => {
    //       return next.handle(this.addTokenToRequest(request, token));
    //     }));
    // }
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isAuthRequest(url: string): boolean {
    return url.startsWith(environment.authServerUrl);
  }
}
