<div class="container mt-2 mb-2">
  <div class="row">
    <div class="col">
      <div class="input-container">
        <div class="range-container">
          <span class="track progress-empty"></span>
          <span class="block-content">
            <span class="track progress" [style.width]="progressWidth + '%'">
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
