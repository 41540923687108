<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ title }}</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <span class="col-sm-2 icon delete-blue"></span>
      <div class="col" [innerHTML]="body"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="continue()" class="btn pink">Ok</button>
  </div>
</div>
