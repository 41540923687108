import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, ManagementTool, Purchases } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { CheckboxListFormControl } from '../../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { NumberFormControl } from '../../../controls/number-form-control/number-form-control';
import { adminConstants } from 'projects/difference-admin/app/shared/constants/constants';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { FormValuesChangedModel } from '../../abstract-form';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';

function getPurchasesFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.purchasesForm.periodId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.SalesPeriods]
            });
        }
        case formFields.purchasesForm.numberPerPeriod: {
            return new NumberFormControl(config.isRequired, isReadOnly);
        }
        case formFields.purchasesForm.formatId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.SalesFormats]
            });
        }
        case formFields.purchasesForm.hasTool: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.purchasesForm.isInterested: {
            return new RadioGroupFormControl(config.isRequired, isReadOnly, {
                collection: collections.yesNoBoolean,
                valuePropertyName: constants.valuePropertyName,
                keyPropertyName: constants.namePropertyName
            })
        }
        case formFields.purchasesForm.managementTools: {
            return new CheckboxListFormControl(config.isRequired, isReadOnly, {
                collection: lookupDataService.lookupDataObj[LookupTypeId.VolumePurchasesTools],
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName
            })
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function purchasesFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): any[] {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

        if (configItem.name === formFields.purchasesForm.managementTools) {
            viewModel[configItem.name] = data.managementTools?.tools?.map((item: any) => { return item + '' });

            if (data.managementTools?.otherToolName) {
                const otherId = lookupDataService.lookupDataObj[LookupTypeId.VolumePurchasesTools].otherId;
                viewModel[configItem.name].push(otherId.toString());

                viewModel[adminConstants.otherToolControlName] = data.managementTools.otherToolName;
            }
        } else {
            viewModel[configItem.name] = data[propertyName];
        }
    })

    return viewModel;
}

function purchasesViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): Purchases {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

            if (configItem.name === formFields.purchasesForm.managementTools) {
                const tools: number[] = model[formFields.purchasesForm.managementTools]?.map((id: string) => parseInt(id));
                const otherToolName: string = model[adminConstants.otherToolControlName];

                objModel[propertyNameForSaveObj] = new ManagementTool({ tools, otherToolName });
            } else {
                objModel[propertyNameForSaveObj] = model[configItem.name];
            }
        })

        objModel.volumePurchaseId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new Purchases(objModel);
}

export {
    getPurchasesFormComponentControl,
    purchasesFormToViewModel,
    purchasesViewModelToDataModel
}
