import { UsersService } from './../../../../difference-admin/app/services/users.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { Observable } from 'rxjs';
import { Role } from 'projects/difference-admin/app/auth/role.enum';

@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(
    public router: Router,
    public customerDataService: CustomerDataService,
    public usersService: UsersService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.usersService.meData;

    if (!user || user?.role !== Role.SuperAdministrator) {
      this.router.navigate(['']);
      return false
    }

    return true;
  }

}
