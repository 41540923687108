<div *ngIf="form" class="card">
  <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
        <h5>Centre/association de gestion</h5>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="isShowControl(formFields.controlCenterForm.isMemberManagementCenter, config)">
        <app-radio-group-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.controlCenterForm.isMemberManagementCenter, config)"
          [title]="'Êtes-vous déjà adhérent d\'un centre ou d\'une association de gestion ? '"
          [inline]="true"
        >
        </app-radio-group-form-control>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="isMemberManagementCenterTrue && isShowControl(formFields.controlCenterForm.accessionDate, config)">
        <app-date-form-control [parentForm]="form" [controlName]="getControlName(formFields.controlCenterForm.accessionDate, config)" [title]="'Date de l\'adhésion'" [inline]="true">
        </app-date-form-control>
      </div>
    </div>

    <div class="row" *ngIf="isMemberManagementCenterTrue">
      <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="isShowControl(formFields.controlCenterForm.managementCenterName, config)">
        <app-name-with-digits-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.controlCenterForm.managementCenterName, config)"
          [title]="'Nom du centre ou de l\'association de gestion'"
          [placeholder]="'Saisissez un nom'"
        >
        </app-name-with-digits-form-control>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="isShowControl(formFields.controlCenterForm.managementCenterAddress, config)">
        <app-name-with-digits-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.controlCenterForm.managementCenterAddress, config)"
          [title]="'Adresse du centre ou de l\'association de gestion'"
          [placeholder]="'Saisissez une adresse'"
        >
        </app-name-with-digits-form-control>
      </div>
    </div>

    <div class="row" *ngIf="isMemberManagementCenterTrue">
      <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="isShowControl(formFields.controlCenterForm.managementCenterNumber, config)">
        <app-number-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.controlCenterForm.managementCenterNumber, config)"
          [title]="'Numéro du centre ou de l\'association de gestion'"
          [placeholder]="'Saisissez un numéro'"
        >
        </app-number-form-control>
      </div>
    </div>

    <div class="row" *ngIf="isMemberManagementCenterFalse">
      <div class="col-12 col-sm-12 col-md-6 col-xl-6" *ngIf="isShowControl(formFields.controlCenterForm.isToDoMemberManagementCenter, config)">
        <app-radio-group-form-control
          [parentForm]="form"
          [controlName]="getControlName(formFields.controlCenterForm.isToDoMemberManagementCenter, config)"
          [title]="'Souhaitez-vous que nous réalisions pour vous une adhésion ?'"
          [inline]="true"
        >
        </app-radio-group-form-control>
      </div>
    </div>
  </div>
</div>
