<div class="mt-3 card">
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
        <h5>Nature de la mission</h5>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="form">
      <!-- showControlsWithErrors: {{ showControlsWithErrors }} -->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.accountingForm.accountingEntryId, config)">
          <app-dropdown-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.accountingEntryId, config)"
            [title]="'Saisie comptable'"
            [placeholder]="'Sélectionnez la saisie comptable'"
          >
          </app-dropdown-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.accountingForm.frequencyId, config)">
          <app-dropdown-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.frequencyId, config)"
            [title]="'Fréquence'"
            [placeholder]="'Sélectionnez la fréquence'"
          >
          </app-dropdown-form-control>
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.accountingForm.isIntermediateSituationalPoints, config)">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-radio-group-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.isIntermediateSituationalPoints, config)"
            [title]="'Souhaitez-vous des points de situation intermédiaires ?'"
            [inline]="true"
          >
          </app-radio-group-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isIntermediateSituationTrue && isShowControl(formFields.accountingForm.howFrequencyId, config)">
          <app-dropdown-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.howFrequencyId, config)"
            [title]="'A quelle fréquence ?'"
            [placeholder]="'Sélectionnez la fréquence'"
          >
          </app-dropdown-form-control>
        </div>
      </div>
      <div class="row" *ngIf="isShowControl(formFields.accountingForm.comment, config)">
        <div class="col-12">
          <app-info-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.comment, config)"
            [title]="'Commentaire'"
            [placeholder]="'Si vous souhaitez nous partager plus d\'informations.'"
          >
          </app-info-form-control>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3 card">
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-8 col-lg-9 align-self-center title-col">
        <h5>Organisation interne</h5>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="form">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="!parLeCabinetTypeSelected">
          <app-radio-group-form-control
            *ngIf="isShowControl(formFields.accountingForm.hasAccountingSoftware, config)"
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.hasAccountingSoftware, config)"
            [title]="hasAccountingSoftwareTitle"
            [inline]="true"
          >
          </app-radio-group-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-radio-group-form-control
            *ngIf="isShowControl(formFields.accountingForm.hasBusinessManagementSoftware, config)"
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.hasBusinessManagementSoftware, config)"
            [title]="hasBusinessManagementSoftwareTitle"
            [inline]="true"
            [titleTooltipText]="'Nom de l\'outil de gestion d\'entreprise que vous utilisez actuellement.'"
          >
          </app-radio-group-form-control>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="!parLeCabinetTypeSelected">
          <app-checkbox-list-form-control
            *ngIf="isAccountingSoftwareTrue && isShowControl(formFields.accountingForm.accountingSoftware, config)"
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.accountingSoftware, config)"
            [title]="toolsTitle"
            [inline]="true"
          >
          </app-checkbox-list-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-checkbox-list-form-control
            *ngIf="isBusinessManagementSoftwareControlTrue && isShowControl(formFields.accountingForm.businessManagementSoftware, config)"
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.businessManagementSoftware, config)"
            [title]="toolsTitle"
            [inline]="true"
          >
          </app-checkbox-list-form-control>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="!parLeCabinetTypeSelected">
          <app-name-with-digits-form-control
            *ngIf="isOtherAccountingSoftwareTool"
            [parentForm]="form"
            [controlName]="'otherAccountingSoftwareTool'"
            [title]="''"
            [placeholder]="'Saisissez le nom de l\'outil'"
            class="no-title like-no-title-in-mobile"
          >
          </app-name-with-digits-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-name-with-digits-form-control
            *ngIf="isOtherBusinessManagementSoftwareTool"
            [parentForm]="form"
            [controlName]="'otherBusinessManagementSoftwareTool'"
            [title]="''"
            [placeholder]="'Saisissez le nom de l\'outil'"
            class="no-title like-no-title-in-mobile"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="!parLeCabinetTypeSelected">
          <app-radio-group-form-control
            *ngIf="isAccountingSoftwareFalse && isShowControl(formFields.accountingForm.isIntrestedInAccountingSoftware, config)"
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.isIntrestedInAccountingSoftware, config)"
            [title]="'Êtes-vous intéressé par un outil de ce type ?'"
            [inline]="true"
          >
          </app-radio-group-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-radio-group-form-control
            *ngIf="isBusinessManagementSoftwareControlFalse && isShowControl(formFields.accountingForm.isIntrestedInBusinessManagementSoftware, config)"
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.isIntrestedInBusinessManagementSoftware, config)"
            [title]="'Êtes-vous intéressé par un outil de ce type ?'"
            [inline]="true"
          >
          </app-radio-group-form-control>
        </div>
      </div>
    </div>

    <div *ngIf="form">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.accountingForm.useTools, config)">
          <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.accountingForm.useTools, config)" [title]="'Souhaitez-vous utiliser ?'" [inline]="true">
          </app-checkbox-list-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.accountingForm.credentialTransferMethods, config)">
          <app-checkbox-list-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.credentialTransferMethods, config)"
            [title]="'Mode de classement des pièces comptables'"
            [inline]="true"
          >
          </app-checkbox-list-form-control>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.accountingForm.accountingClassificationMode, config)">
          <app-checkbox-list-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.accountingClassificationMode, config)"
            [title]="'Mode de communication des pièces comptables'"
            [inline]="true"
          >
          </app-checkbox-list-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-xl-6">
          <app-name-with-digits-form-control
            *ngIf="isOtherClassificationModeAccountingParts"
            [parentForm]="form"
            [controlName]="'otherClassificationModeAccountingParts'"
            [title]="''"
            [placeholder]="'Saisissez le nom de l\'outil'"
            class="no-title like-no-title-in-mobile"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-5 col-xl-3" *ngIf="isShowControl(formFields.accountingForm.archivingAccounts, config)">
          <app-checkbox-list-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.archivingAccounts, config)"
            [title]="'Archivage des pièces comptables'"
            [inline]="true"
          >
          </app-checkbox-list-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-7 col-xl-9" *ngIf="isShowControl(formFields.accountingForm.formats, config)">
          <app-checkbox-list-form-control [parentForm]="form" [controlName]="getControlName(formFields.accountingForm.formats, config)" [title]="'Format'" [inline]="true">
          </app-checkbox-list-form-control>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-xl-6" *ngIf="isShowControl(formFields.accountingForm.transmissionFrequencyId, config)">
          <app-dropdown-form-control
            [parentForm]="form"
            [controlName]="getControlName(formFields.accountingForm.transmissionFrequencyId, config)"
            [title]="'Fréquence de transmission'"
            [placeholder]="'Sélectionnez la fréquence'"
          >
          </app-dropdown-form-control>
        </div>
      </div>
    </div>
  </div>
</div>
