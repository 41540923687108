import { FilterOperator, TemplateType } from 'projects/difference/webapi/Difference.WebApi';
import { BillingTaskType } from 'projects/difference-admin/app/models/devis-task.model';
import { SearchCriterionsDataType } from 'projects/difference/app/shared/components/data-table/models/search-criterions-data-type';
import { ActiveInactiveEnum } from 'projects/difference/app/shared/components/data-table/models/search-operator-model';

export const collections = {
    allSearchOperatorsCollection: [
        {
            id: FilterOperator.Equal,
            description: 'égal'
        },
        {
            id: FilterOperator.Contains,
            description: 'contient'
        },
        {
            id: FilterOperator.NotEqual,
            description: 'différent'
        },
        {
            id: FilterOperator.GreaterOrEqualThan,
            description: 'supérieur ou égal'
        },
        {
            id: FilterOperator.LessOrEqualThan,
            description: 'inférieur ou égal'
        }
    ],
    stringSearchOperatorsCollection: [
        {
            id: FilterOperator.Equal,
            description: 'égal'
        },
        {
            id: FilterOperator.Contains,
            description: 'contient'
        },
        {
            id: FilterOperator.NotEqual,
            description: 'différent'
        }
    ],
    numberSearchOperatorsCollection: [
        {
            id: FilterOperator.Equal,
            description: 'égal'
        },
        {
            id: FilterOperator.GreaterOrEqualThan,
            description: 'supérieur ou égal'
        },
        {
            id: FilterOperator.LessOrEqualThan,
            description: 'inférieur ou égal'
        }
    ],
    booleanSearchOperatorsCollection: [
        {
            id: FilterOperator.Equal,
            description: 'égal'
        },
    ],
    activeDeactiveBoolean: [
        {
            id: ActiveInactiveEnum.ACTIVE,
            name: 'Active'
        },
        {
            id: ActiveInactiveEnum.INACTIVE,
            name: 'Inactive'
        }
    ],
    yesNoBoolean: [
        {
            name: true,
            description: 'Oui'
        },
        {
            name: false,
            description: 'Non'
        }
    ],
    billingMethods: [
        {
            id: 1,
            type: BillingTaskType.UNIT,
            description: 'A l’unité'
        },
        {
            id: 2,
            type: BillingTaskType.TIME_BASED,
            description: 'Au temps passé'
        },
        {
            id: 3,
            type: BillingTaskType.FIXED,
            description: 'Au forfait'
        },
        {
            id: 4,
            type: BillingTaskType.FEES_BASED,
            description: 'Frais de dossier'
        },
        {
            id: 5,
            type: BillingTaskType.NOT_INVOICED,
            description: 'Ne donne pas lieu à facturation'
        }
    ],
    testNotes: [
        {
            id: 1,
            title: 'Titre 1',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. unknown printer took a galley of type and scrambled it type and scrambled it to make a type specimen book.'
        },
        {
            id: 2,
            title: 'Titre 2',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. unknown printer took a galley of type and scrambled it type and scrambled it to make a type specimen book.'
        },
        {
            id: 3,
            title: 'Titre 3',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. unknown printer took a galley of type and scrambled it type and scrambled it to make a type specimen book.'
        },
        {
            id: 4,
            title: 'Titre 4',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. unknown printer took a galley of type and scrambled it type and scrambled it to make a type specimen book.'
        }
    ],
    devisTemplatesSearhCriterions: [
        {
            id: 1,
            title: 'Nom',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    headerSearchCriterions: [
        {
            id: 1,
            title: 'Nom',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    footerSearchCriterions: [
        {
            id: 1,
            title: 'Nom',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    lettreMissionTemplatesSearchCriteria: [
        {
            id: 1,
            title: 'Nom',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    lettreMissionHeaderFooterSearchCriteria: [
        {
            id: 1,
            title: 'Nom',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    lettreMissionAnnexesSearchCriteria: [
        {
            id: 1,
            title: 'Nom',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    profilesSearhCriterions: [
        {
            id: 1,
            title: 'Profil',
            data: ['employeeResourceType.name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Expérience',
            data: ['employeeResourceExperience.name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 3,
            title: 'Département',
            data: ['department.name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 4,
            title: 'Sites applicables',
            data: ['officesNames'],
            type: SearchCriterionsDataType.String,
        }
    ],
    prestationsSearhCriterions: [
        {
            id: 1,
            title: 'Mission',
            data: ['mission.name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 2,
            title: 'Activité',
            data: ['activity.name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 3,
            title: 'Prestation',
            data: ['name'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 4,
            title: 'Profil et séniorité',
            data: ['profileAndSeniority'],
            type: SearchCriterionsDataType.String,
        },
        {
            id: 5,
            title: 'Statut',
            data: ['deactivated'],
            type: SearchCriterionsDataType.Boolean,
        }
    ],
    devisConslultationSearhCriterions: [
        {
            id: 1,
            description: 'Entité'
        },
        {
            id: 2,
            description: 'Bureau'
        },
        {
            id: 3,
            description: 'Modèle'
        },
        {
            id: 4,
            description: 'Statut'
        },
        {
            id: 5,
            description: 'Responsable'
        }
    ],
    headerFooterTypes: [
        {
            id: TemplateType.Header,
            name: 'Entete'
        },
        {
            id: TemplateType.Footer,
            name: 'Pied de page'
        }
    ],
    frequency: [
        {
            id: 0,
            name: 'Mensuel',
        },
        {
            id: 1,
            name: 'Trimestrielle',
        },
        {
            id: 2,
            name: 'Quadrimestrielle',
        },
        {
            id: 3,
            name: 'Semestriel',
        },
        {
            id: 3,
            name: 'Annuel',
        },
    ],
    billings: [
        {
            id: 1,
            description: 'Pièce 1'
        },
        {
            id: 2,
            description: 'Pièce 2'
        }
    ],
    prospectsSearhOperators: [
        {
            id: 1,
            description: 'prospectsSearhOperators 1'
        },
        {
            id: 2,
            description: 'prospectsSearhOperators 2'
        }
    ],
    models: [
        {
            id: 1,
            description: 'Classique'
        },
        {
            id: 2,
            description: 'Model 2'
        }
    ],
    statuses: [
        {
            id: 0,
            description: 'En cours' // InProgress
        },
        {
            id: 2,
            description: 'Terminé' // Completed
        },
        {
            id: 3,
            description: 'Validé' // Validated
        },
        {
            id: 4,
            description: 'Annulé' // Cancelled
        },
        {
            id: 5,
            description: 'Envoyé' // Sent
        },
        {
            id: 6,
            description: 'Signé' // Signed
        },
        {
            id: 7,
            description: 'Archivé' // Archived
        }
    ],
    quoteLinks: [
        {
            id: 1,
            description: '144101'
        },
        {
            id: 2,
            description: '144102'
        },
        {
            id: 2,
            description: '143'
        }
    ],
    clientSideData: [
        { id: 860, firstName: 'John', lastName: 'Rossi' },
        { id: 870, firstName: 'Jane', lastName: 'Lisari' },
        { id: 590, firstName: 'Kimberly', lastName: 'Chen' },
        { id: 803, firstName: 'Antonio', lastName: 'Genosa' },
        { id: 474, firstName: 'Christopher', lastName: 'Eremi' },
        { id: 476, firstName: 'Kathryn', lastName: 'Johnson' },
        { id: 464, firstName: 'Steven', lastName: 'Lamy' },
        { id: 505, firstName: 'Joshua', lastName: 'Peterson' },
        { id: 308, firstName: 'Pierre', lastName: 'Robinson' },
        { id: 184, firstName: 'Luc', lastName: 'Gills' },
        { id: 411, firstName: 'Alexandre', lastName: 'Ritter' },
        { id: 154, firstName: 'Benoit', lastName: 'Vang' },
        { id: 623, firstName: 'Gaetan', lastName: 'Anderson' },
        { id: 499, firstName: 'Romain', lastName: 'Brown' },
        { id: 482, firstName: 'Bastien', lastName: 'Moore' },
        { id: 255, firstName: 'François', lastName: 'Dujardin' }
    ],
    clientSideDataShort: [
        { id: 860, firstName: 'John', lastName: 'Rossi' },
        { id: 499, firstName: 'Jane', lastName: 'Lisari' },
        { id: 482, firstName: 'Kimberly', lastName: 'Chen' },
        { id: 255, firstName: 'Antonio', lastName: 'Genosa' }
    ],
    months: [
        { key: 'Janvier', value: '01' },
        { key: 'Février', value: '02' },
        { key: 'Mars', value: '03' },
        { key: 'Avril', value: '04' },
        { key: 'Mai', value: '05' },
        { key: 'Juin', value: '06' },
        { key: 'Juillet', value: '07' },
        { key: 'Août', value: '08' },
        { key: 'Septembre', value: '09' },
        { key: 'Octobre', value: '10' },
        { key: 'Novembre', value: '11' },
        { key: 'Décembre', value: '12' }
    ],
    messages: {
        required: 'Merci de renseigner ce champ',
        benificiatySumError: 'La somme des pourcentages des parts des bénéficiaires effectifs ne peut pas être supérieure à 100%',
        besoinSumError: 'La somme des pourcentages doit être strictement égale à 100%'
    }
}
