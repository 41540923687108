import { ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { CollectionDescription } from '../dropdown-form-control/dropdown-form-control';

export class RadioGroupFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private collectionDescription: CollectionDescription = {}, private options?: RadioGroupOptions) {
        super(required, disabled);

        if (collectionDescription && collectionDescription.enableFirstChooseOption && collectionDescription.collection.length) {
            this.setValue(collectionDescription.collection[0][collectionDescription.keyPropertyName]);
        } else {
            this.setValue(null);
        }

        if (options && options.defaultValue) {
            this.setValue(options.defaultValue);
        }
    }

    public get collection(): any[] {
        return this.collectionDescription.collection;
    }

    public get multiple(): boolean {
        return this.options?.multiple ?? false;
    }

    public get keyPropertyName(): string {
        return this.collectionDescription.keyPropertyName;
    }

    public get valuePropertyName(): string {
        return this.collectionDescription.valuePropertyName;
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }
}

export interface RadioGroupOptions {
    defaultValue?: any;
    multiple?: boolean;
}
