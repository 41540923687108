import { Injectable } from '@angular/core';
import { FeaturesTaxation, FeaturesTaxationClient } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractFormService } from '../../abstract-form-service';

@Injectable({
  providedIn: 'root'
})

export class FeaturesTaxationService extends AbstractFormService<FeaturesTaxation> {
  constructor(public webApi: FeaturesTaxationClient) {
    super(webApi);
  }
}
