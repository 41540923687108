import { NameWithDigitsFormControl } from './../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { LookupDataService, LookupTypeId } from '../../../../../services/lookup-data.service';
import { NameFormControl } from '../../../controls/name-form-control/name-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { DataFieldConfig, ResponsibleManagement } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { InfoFormControl } from '../../../controls/info-form-control/info-form-control';
import { FormValuesChangedModel } from '../../abstract-form';

function getResponsibleManagementFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
    const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

    switch (config.name) {
        case formFields.responsibleManagement.responsibleManagementId: {
            return new DropDownFormControl(config.isRequired, isReadOnly, {
                valuePropertyName: constants.namePropertyName,
                keyPropertyName: constants.keyPropertyName,
                collection: lookupDataService.lookupDataObj[LookupTypeId.Persons]
            })
        }
        case formFields.responsibleManagement.serviceProviderName: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly)
        }
        case formFields.responsibleManagement.socialFeatures: {
            return new InfoFormControl(config.isRequired, isReadOnly, true)
        }
        default: {
            return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
        }
    }
}

function responsibleManagementFormToViewModel(config: DataFieldConfig[], data: any): any[] {
    const viewModel: any = {};

    if (!data) {
        return viewModel;
    }

    config?.forEach((configItem: any) => {
        const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
        viewModel[configItem.name] = data[propertyName]
    })

    return viewModel;
}

function responsibleManagementModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): ResponsibleManagement {
    const objModel: any = {};
    const model = data?.model;

    if (data) {
        config?.forEach((configItem: any) => {
            const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
            objModel[propertyNameForSaveObj] = model[configItem.name];
        })

        objModel.socialResponsibleManagementId = data.formId;
        objModel.profileGuid = data.profileGuid;
    }

    return new ResponsibleManagement(objModel);
}

export {
    getResponsibleManagementFormComponentControl,
    responsibleManagementFormToViewModel,
    responsibleManagementModelToDataModel
}
